import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ConnectableObservable, interval as observableInterval, Observable, SubscriptionLike } from 'rxjs';
import { distinctUntilChanged, filter, map, publish, share, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';

import { AnalyticsService } from '../analytics/analytics.service';
import { LoggerService } from '../core/logger.service';

import { JPlayerComponent } from './jplayer/jplayer.component';
import { MobilePlayService } from './mobile-play.service';
import { PlayerService } from './player.service';
import initialState from './player.state';

@Component({
    selector: 'player',
    template: `
    <jplayer
        [isBrowserTabActive]="isBrowserTabActive"
        (onAudioPlaying)="onAudioPlaying($event)"
        (onAudioComplete)="onAudioComplete()"
        (onAudioBuffering)="onAudioBuffering()"
        (onAudioLoaded)="onAudioLoaded()"
        (onTimeUpdate)="onTimeUpdate($event)"
        (onAudioDurationChange)="onAudioDurationChange($event)">
    </jplayer>
    <rewind class="grid__column grid__column--fixed-width"
            [hasAudio]="hasAudio$ | async"
            [hasDisabledControls]="hasDisabledControls$ | async"
            [isRewindable]="isRewindable$ | async"
            [isPlayerHidden]="isPlayerHidden"
            (onRewind)="onRewind()">
    </rewind>
    <toggle-play class="grid__column grid__column--fixed-width"
                 [hasAudio]="hasAudio$ | async"
                 [isLoading]="isLoading$ | async"
                 [isPlaying]="isPlaying$ | async"
                 [isAudioPlaying]="isAudioPlaying$ | async"
                 [isPlayerHidden]="isPlayerHidden"
                 (onTogglePlay)="onTogglePlay()">
    </toggle-play>
    <progress-bar class="grid__column"
                  [hasAudio]="hasAudio$ | async"
                  [isAudioPlaying]="isAudioPlaying$ | async"
                  [hasDisabledControls]="hasDisabledControls$ | async"
                  [audioPosition]="audioPosition$ | async"
                  [duration]="duration$ | async"
                  [isScrubbing]="isScrubbing$ | async"
                  [isBrowserTabActive]="isBrowserTabActive"
                  (onSeek)="onSeek($event)"
                  (onScrubBegin)="onScrubBegin()"
                  (onScrubEnd)="onScrubEnd()">
    </progress-bar>
    <volume-controls class="grid__column grid__column--fixed-width"
                     [volume]="volume$ | async"
                     [isMuted]="isMuted$ | async"
                     [isVolumeControlExpanded]="isVolumeControlExpanded$ | async"
                     [isChangingVolume]="isChangingVolume$ | async"
                     [isPlayerHidden]="isPlayerHidden"
                     (onToggleVolumeControls)="onToggleVolumeControls()"
                     (onToggleMute)="onToggleMute()"
                     (onAudioVolumeChange)="onAudioVolumeChange($event)"
                     (onVolumeSliderBegin)="onVolumeSliderBegin()"
                     (onVolumeSliderEnd)="onVolumeSliderEnd()">
    </volume-controls>
    <skip class="grid__column grid__column--fixed-width"
          [hasAudio]="hasAudio$ | async"
          [hasDisabledControls]="hasDisabledControls$ | async"
          [isPlaying]="isPlaying$ | async"
          [isPlayerHidden]="isPlayerHidden"
          (onSkip)="onSkip()">
    </skip>
    `,
    styleUrls: ['./player.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * The main component representing the audio player. It outsources most of the functionality to its sub-components,
 * which handle the view logic and rendering of their individual responsibilities. However, the PlayerComponent is the
 * only one that is allowed to interact directly with the PlayerService which in turn interacts with the Store.
 *
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 */
export class PlayerComponent implements OnInit, AfterViewInit, OnDestroy {
    /** @type {boolean} */
    @Input() isBrowserTabActive;
    /** @type {boolean} */
    @Input() isPlayerHidden;

    public isLoading$: Observable<boolean>;
    public isPlaying$: Observable<boolean>;
    public isAudioPlaying$: Observable<boolean>;
    public hasAudio$: Observable<boolean>;
    public hasDisabledControls$: Observable<boolean>;
    public duration$: Observable<number>;
    public audioPosition$: Observable<number>;
    public isScrubbing$: Observable<boolean>;
    public volume$: Observable<number>;
    public isMuted$: Observable<boolean>;
    public isVolumeControlExpanded$: Observable<boolean>;
    public isChangingVolume$: Observable<boolean>;
    public isRewindable$: Observable<boolean>;

    @ViewChild(JPlayerComponent, { static: false }) private jPlayerComponent: JPlayerComponent;

    private _subscriptions: Array<SubscriptionLike>;
    private _googleHeartbeatInterval: number;
    private _volumeControlsTimeout: number;

    constructor(private _playerService: PlayerService, private _mobilePlayService: MobilePlayService, private _analytics: AnalyticsService, private _logger: LoggerService) {
        /** @private */
        this._subscriptions = [];

        /**
         * The time between heartbeat events sent to Google. Saved as a variable so we can override it in our unit
         * tests and not have to wait 5 minutes to be able to test `_sendHeartbeatToGoogleWhileAudioIsPlaying()`.
         *
         * @type {number}
         * @private
         */
        this._googleHeartbeatInterval = 300000; // 5 minutes in milliseconds
        /**
         * The time to wait before closing the volume controls. Saved as a variable so we can override it in our unit
         * tests and not have to wait 15 seconds to be able to test `_checkAndCloseVolumeControlsIfUnusedAfterFixedTime()`.
         *
         * @returns {number}
         * @private
         */
        this._volumeControlsTimeout = 15000; // 15 seconds in milliseconds

        /** @type {Observable<boolean>} */
        this.isLoading$ = this._playerService.state$.pipe(map(s => s.isLoading), startWith(initialState.isLoading), distinctUntilChanged());
        /** @type {Observable<boolean>} */
        this.isPlaying$ = this._playerService.state$.pipe(map(s => s.isPlaying), startWith(initialState.isPlaying), distinctUntilChanged());
        /** @type {Observable<boolean>} */
        this.isAudioPlaying$ = this._playerService.state$.pipe(
            map(s => s.isAudioPlaying),
            startWith(initialState.isAudioPlaying),
            distinctUntilChanged(),
        );
        /** @type {Observable<boolean>} */
        this.hasAudio$ = this._playerService.state$.pipe(
            map(s => s.audioUrls && s.audioUrls.length > 0),
            startWith(false),
            distinctUntilChanged(),
        );
        /** @type {Observable<boolean>} */
        this.hasDisabledControls$ = this._playerService.state$.pipe(
            map(s => s.hasDisabledControls),
            startWith(initialState.hasDisabledControls),
            distinctUntilChanged(),
        );
        /** @type {Observable<number>} */
        this.duration$ = this._playerService.state$.pipe(map(s => s.duration), startWith(initialState.duration), distinctUntilChanged());
        /** @type {Observable<number>} */
        this.audioPosition$ = this._playerService.state$.pipe(
            map(s => s.position),
            startWith(initialState.position),
            distinctUntilChanged(),
        );
        /** @type {Observable<boolean>} */
        this.isScrubbing$ = this._playerService.state$.pipe(
            map(s => s.isScrubbing),
            startWith(initialState.isScrubbing),
            distinctUntilChanged(),
        );
        /** @type {Observable<number>} */
        this.volume$ = this._playerService.state$.pipe(map(s => s.volume), startWith(initialState.volume), distinctUntilChanged());
        /** @type {Observable<boolean>} */
        this.isMuted$ = this._playerService.state$.pipe(map(s => s.isMuted), startWith(initialState.isMuted), distinctUntilChanged());
        /** @type {Observable<boolean>} */
        this.isVolumeControlExpanded$ = this._playerService.state$.pipe(
            map(s => s.isVolumeControlExpanded),
            startWith(initialState.isVolumeControlExpanded),
            distinctUntilChanged(),
        );
        /** @type {Observable<boolean>} */
        this.isChangingVolume$ = this._playerService.state$.pipe(
            map(s => s.isChangingVolume),
            startWith(initialState.isChangingVolume),
            distinctUntilChanged(),
        );
        /** @type {Observable<boolean>} */
        this.isRewindable$ = this.audioPosition$.pipe(map(position => position > 0), startWith(false), distinctUntilChanged());
    }

    /**
     * The actions that should be taken when a component has been initialized.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnInit-interface.html
     */
    ngOnInit() {
        this._subscriptions.push(this.isAudioPlaying$.pipe(filter(isAudioPlaying => isAudioPlaying === true))
            .subscribe(this._sendHeartbeatToGoogleWhileAudioIsPlaying.bind(this)));

        this._subscriptions.push(this.isVolumeControlExpanded$.pipe(filter(isExpanded => isExpanded === true))
            .subscribe(this._checkAndCloseVolumeControlsIfUnusedAfterFixedTime.bind(this)));
    }

    /**
     * The actions that should be taken when a component's view has been fully initialized.
     *
     * @see https://angular.io/docs/ts/latest/api/core/AfterViewInit-interface.html
     */
    ngAfterViewInit() {
        this._mobilePlayService.initialize(this.jPlayerComponent);
    }

    /**
     * The actions that should be taken right before a component is destroyed.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnDestroy-interface.html
     */
    ngOnDestroy() {
        // don't forget to clean up the subscriptions
        this._subscriptions.forEach((s) => {
            s.unsubscribe();
        });
    }

    // @TODO find a better way to test this and remove these getters & setters
    /**
     * getter for subscriptions - used for testing only
     * @return Array<ISubscription>
     */
    get subscriptions(): Array<SubscriptionLike> {
        return this._subscriptions;
    }

    /**
     * setter for heartbeat interval - used for testing
     * @param {number} interval
     */
    set googleHeartbeatInterval(interval: number) {
        this._googleHeartbeatInterval = interval;
    }

        /**
     * setter for volume controls timeout - used for testing
     * @param {number} timeout
     */
    set volumeControlsTiemout(timeout: number) {
        this._volumeControlsTimeout = timeout;
    }

    /**
     * The event listener that gets called when the third-party audio player reports that audio is playing
     *
     * @param {boolean} isAudioPlaying
     */
    onAudioPlaying(isAudioPlaying: boolean): void {
        this._playerService.onAudioPlaying(isAudioPlaying);
    }

    /**
     * The event listener that gets called when the third-party audio player reports that the audio track has completed
     */
    onAudioComplete(): void {
        this._playerService.onAudioComplete();
    }

    /**
     * The event listener that gets called when the third-party audio player reports that the audio is currently buffering
     */
    onAudioBuffering(): void {
        this._playerService.onAudioBuffering();
    }

    /**
     * The event listener that gets called when the third-party audio player has loaded the audio and is ready to play
     */
    onAudioLoaded(): void {
        this._playerService.onAudioLoaded();
    }

    /**
     * The event listener that gets called when the third-party audio player reports that the position within the track has changed
     *
     * @param {number} position
     */
    onTimeUpdate(position: number): void {
        this._playerService.onTimeUpdate(position);
    }

    /**
     * The event listener that gets called when the third-party audio player reports the duration of the audio track
     *
     * @param {number} duration
     */
    onAudioDurationChange(duration: number): void {
        this._playerService.onAudioDurationChange(duration);
    }

    /**
     * The event listener that gets called when the user clicks on either the play or pause buttons.
     */
    onTogglePlay(): void {
        this._playerService.onTogglePlay();
    }

    /**
     * The event listener that gets called when the user clicks the skip button.
     */
    onSkip(): void {
        this._playerService.onSkip();
    }

    /**
     * The event listener that gets triggered when the user clicks the rewind button.
     */
    onRewind(): void {
        this._playerService.onRewind();
    }

    /**
     * The event listener that gets called when the user clicks anywhere on the player progress bar.
     *
     * @param {number} position The position to seek to
     */
    onSeek(position: number): void {
        this._playerService.onSeek(position);
    }

    /**
     * The event listener that gets called when the user begins scrubbing the player progress bar.
     */
    onScrubBegin(): void {
        this._playerService.onScrubBegin();
    }

    /**
     * The event listener that gets called when the user lets go of the scrubber.
     */
    onScrubEnd(): void {
        this._playerService.onScrubEnd();
    }

    /**
     * The event listener that gets called when the user has toggled whether or not the volume controls should be shown
     */
    onToggleVolumeControls(): void {
        this._playerService.onToggleVolumeControls();
    }

    /**
     * The event listener that gets called when the user clicks on the volume mute button.
     */
    onToggleMute(): void {
        this._playerService.onToggleMute();
    }

    /**
     * The event listener that gets called when the user has selected a different volume for the audio player
     *
     * @param {number} volume
     */
    onAudioVolumeChange(volume: number): void {
        this._playerService.onAudioVolumeChange(volume);
    }

    /**
     * The event listener that gets called when the user has begun changing the volume by sliding the volume slider
     */
    onVolumeSliderBegin(): void {
        this._playerService.onVolumeSliderBegin();
    }

    /**
     * The event listener that gets called when the user has finished changing the volume by sliding the volume slider
     */
    onVolumeSliderEnd(): void {
        this._playerService.onVolumeSliderEnd();
    }

    /**
     * Send a heartbeat event to Google Analytics every 5 minutes while audio is playing
     *
     * @private
     */
    _sendHeartbeatToGoogleWhileAudioIsPlaying(): void {
        const audioIsNoLongerPlaying$ = this.isAudioPlaying$.pipe(
            filter(isAudioPlaying => isAudioPlaying === false),
            publish(),
        ) as ConnectableObservable<boolean>;
        const timer$ = observableInterval(this._googleHeartbeatInterval).pipe(share(), takeUntil(audioIsNoLongerPlaying$));
        const connection = audioIsNoLongerPlaying$.connect();

        timer$.subscribe({
            next: () => { this._analytics.sendEvent('heartbeat', 'interval'); },
            error: () => { this._logger.error.bind(this, 'PlayerComponent'); },
            complete: () => { connection.unsubscribe(); },
        });
    }

    /**
     * Close the volume controls after 15 seconds of inactivity
     *
     * @todo I think there's an even better way to do this with RxJS, I just don't see it yet
     * @private
     */
    _checkAndCloseVolumeControlsIfUnusedAfterFixedTime(): void {
        const volumeControlIsClosed$ = this.isVolumeControlExpanded$.pipe(
            filter(isExpanded => isExpanded === false),
            publish(),
        ) as ConnectableObservable<boolean>;
        const timer$ = observableInterval(this._volumeControlsTimeout).pipe(
            withLatestFrom(this.isChangingVolume$, (i, isChangingVolume) => isChangingVolume),
            share(),
            takeUntil(volumeControlIsClosed$),
        );
        const connection = volumeControlIsClosed$.connect();

        const subscription = timer$.subscribe({
            next: (isChangingVolume) => {
                if (!isChangingVolume) {
                    this.onToggleVolumeControls(); // collapse the volume controls
                    subscription.unsubscribe();
                }
            },
            error: () => {
                this._logger.error.bind(this, 'PlayerComponent');
            },
            complete: () => {
                subscription.unsubscribe();
                connection.unsubscribe();
            },
        });
    }
}
export default PlayerComponent;
