import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { AnalyticsService } from '../../analytics/analytics.service';
import { User } from '../../api/user/user.state';
import BrowserService from '../../core/browser.service';

@Component({
    selector: 'user-menu',
    template: `
    <button *ngIf="!isSignedIn" class="site__nav__button"
            (click)="signIn($event)" aria-label="Sign in to NPR One" id="user-signin">Sign In</button>
    <button *ngIf="isSignedIn" class="site__nav__button site__nav__button--user"
            (click)="toggleUserMenu($event)">
        <i class="site__nav__icon site__nav__icon--avatar" role="img" aria-label="Toggle the user menu"></i>
    </button>
    <ul class="site__nav__sub-menu" *ngIf="isSignedIn && isUserMenuExpanded" role="menu">
        <li class="site__nav__item site__nav__item--linkless" role="menuitem" aria-disabled="true" *ngIf="user.firstName || user.lastName">
            {{user.firstName + ' ' + user.lastName}}
        </li>
        <li class="site__nav__item site__nav__item--linkless" role="menuitem" aria-disabled="true">{{user.email}}</li>
        <li class="site__nav__item" role="menuitem">
            <button class="site__nav__button" (click)="signOut($event)">Sign Out</button>
        </li>
    </ul>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Top right portion of the Header (Sign In, user icon)
 */
export class UserMenuComponent implements OnInit, OnDestroy, OnChanges {
    /** @type {User} */
    @Input() user: User;
    /** @type {boolean} */
    @Input() isSignedIn: boolean;
    /** @type {boolean} */
    @Input() isUserMenuExpanded: boolean;
    /** @private */
    @Output('onSignIn') _onSignIn: EventEmitter<void> = new EventEmitter();
    /** @private */
    @Output('onSignOut') _onSignOut: EventEmitter<void> = new EventEmitter();
    /** @private */
    @Output('onToggleUserMenu') _onToggleUserMenu: EventEmitter<void> = new EventEmitter();

    public keyboardShortcuts: string;

    constructor(private _analytics: AnalyticsService, private _browser: BrowserService) {}
    ngOnInit() {
        this._browser.getWindow().document.addEventListener('keyup', this._processEscUp.bind(this), false);
    }

    ngOnDestroy() {
        this._browser.getWindow().document.removeEventListener('keyup', this._processEscUp.bind(this), false);
    }

    ngOnChanges() {
        if (this.isUserMenuExpanded === true) {
            this.keyboardShortcuts = 'Escape BlurMenu';
        } else {
            this.keyboardShortcuts = 'Escape DoNothing';
        }
    }
    /**
     * @listens {click}
     * @param {Event} event
     */
    signIn(event: Event): void {
        event.preventDefault();

        this._onSignIn.emit();

        this._analytics.sendEvent('settings', 'login', 'external link');
    }

    /**
     * @listens {click}
     * @param {Event} event
     */
    signOut(event: Event): void {
        event.preventDefault();

        this._onSignOut.emit();

        this._analytics.sendEvent('settings', 'logout');
    }

    /**
     * @listens {click}
     * @param {Event} event
     */
    toggleUserMenu(event: Event): void {
        event.preventDefault();

        if (!this.isUserMenuExpanded) {
            this._analytics.sendEvent('settings', 'profile view');
        }

        this._onToggleUserMenu.emit();
    }
    _processEscUp(event: KeyboardEvent): void {
        event.preventDefault();
         if (!this.isUserMenuExpanded) {
             return;
         }

         if (event.key === 'Escape') {
             this._onToggleUserMenu.emit();
             return;
         }
     }
}
export default UserMenuComponent;
