import { Injectable } from '@angular/core';

@Injectable()
export class BrowserService {
    getDocument(): Document {
        return document;
    }

    getWindow(): Window {
        return window;
    }

    getGA(): UniversalAnalytics.ga | null {
        if ((<any> window).ga) {
            return (<any> window).ga;
        }
        return null;
    }

    getModernizr(): ModernizrStatic | null {
        if ((<any> window).Modernizr) {
            return (<any> window).Modernizr;
        }
        return null;
    }

    getLocalStorage(): Storage | null {
        /* istanbul ignore next */ // impossible to mock, etc.
        if (Storage !== void(0)) { // tslint:disable-line
            return localStorage;
        }
        return null;
    }
}

export default BrowserService;
