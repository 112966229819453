import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Recommendation } from '@npr/npr-one-sdk';
import {combineLatest,  Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, withLatestFrom } from 'rxjs/operators';

import FeatureStores from '../../constants/feature-stores.enum';
import { LoggerService } from '../../core/logger.service';
import ApiActions from '../api.actions';
import { ApiService } from '../api.service';

import initialState, { PlaylistPlusRootState, PlaylistState } from './playlist.state';

@Injectable()
/**
 * A global service class which can theoretically be injected anywhere. However, our contract is that the only place
 * where these custom services should be allowed to be interacted with is inside other services.
 */
export class PlaylistService {
    public state$: Observable<PlaylistState>;

    public _recommendations: Array<Recommendation> = []; // @TODO make private after refactoring tests
    public _currentRecommendation: Recommendation = null; // @TODO make private after refactoring tests

    constructor(private _store: Store<PlaylistPlusRootState>, private _api: ApiService, private _logger: LoggerService) {
        /** @type {Observable} */
        this.state$ = this._store.select(FeatureStores.PLAYLIST);

        const Action: any = this._api.Action;

        const isUserLoaded$ = this.state$.pipe(map(s => s.isUserLoaded), startWith(initialState.isUserLoaded), distinctUntilChanged());
        const isReadyForStory$ = this.state$.pipe(
            map(s => s.isReadyForStory),
            startWith(initialState.isReadyForStory),
            distinctUntilChanged(),
        );
        const needsRecommendationListUpdate$ = this.state$.pipe(
            map(s => s.needsRecommendationListUpdate),
            startWith(initialState.needsRecommendationListUpdate),
            distinctUntilChanged(),
        );
        const sharedMediaId$ = this.state$.pipe(map(s => s.sharedMediaId), startWith(initialState.sharedMediaId), distinctUntilChanged());
        const channel$ = this.state$.pipe(map(s => s.channel), startWith(initialState.channel), distinctUntilChanged());
        const position$ = this.state$.pipe(map(s => s.position), startWith(initialState.position), distinctUntilChanged());
        const isAudioPlaying$ = this.state$.pipe(
            map(s => s.isAudioPlaying),
            startWith(initialState.isAudioPlaying),
            distinctUntilChanged(),
        );
        const isAudioComplete$ = this.state$.pipe(
            map(s => s.isAudioComplete),
            startWith(initialState.isAudioComplete),
            distinctUntilChanged(),
        );
        const hasClickedSkip$ = this.state$.pipe(
            map(s => s.hasClickedSkip),
            startWith(initialState.hasClickedSkip),
            distinctUntilChanged(),
        );
        const hasClickedShare$ = this.state$.pipe(
            map(s => s.hasClickedShare),
            startWith(initialState.hasClickedShare),
            distinctUntilChanged(),
        );
        const hasClickedMarkInteresting$ = this.state$.pipe(
            map(s => s.hasClickedMarkInteresting),
            startWith(initialState.hasClickedMarkInteresting),
            distinctUntilChanged(),
        );
        const hasClickedThrough$ = this.state$.pipe(
            map(s => s.hasClickedThrough),
            startWith(initialState.hasClickedThrough),
            distinctUntilChanged(),
        );
        const isClickThroughInternal$ = this.state$.pipe(
            map(s => s.isClickThroughInternal),
            startWith(initialState.isClickThroughInternal),
            distinctUntilChanged(),
        );
        const hasTimedOut$ = this.state$.pipe(
            map(s => s.hasTimedOut),
            startWith(initialState.hasTimedOut),
            distinctUntilChanged(),
        );
        const needsRecommendationCheckAfterStationChange$ = this.state$.pipe(
            map(s => s.needsRecommendationCheckAfterStationChange),
            startWith(initialState.needsRecommendationCheckAfterStationChange),
            distinctUntilChanged(),
        );

        combineLatest([isUserLoaded$, isReadyForStory$])
            .pipe(withLatestFrom(sharedMediaId$), withLatestFrom(channel$))
            .subscribe(([[[isUserLoaded, isReadyForStory], sharedMediaId], channel]) => {
                if (isUserLoaded && isReadyForStory) {
                    this._getRecommendation(sharedMediaId, channel);
                }
            });

        combineLatest([isUserLoaded$, needsRecommendationListUpdate$])
            .pipe(withLatestFrom(channel$))
            .subscribe(([[isUserLoaded, needsRecommendationListUpdate], channel]) => {
                if (isUserLoaded && needsRecommendationListUpdate) {
                    this._getRecommendationsFromChannel(channel);
                }
            });

        isAudioPlaying$.pipe(withLatestFrom(position$))
            .subscribe(([isAudioPlaying, position]) => {
                if (isAudioPlaying && Math.round(position) === 0) {
                    this._currentRecommendation.recordAction(Action.START, 0);
                }
            });

        isAudioComplete$.pipe(withLatestFrom(position$))
            .subscribe(([isAudioComplete, position]) => {
                if (isAudioComplete) {
                    this._currentRecommendation.recordAction(Action.COMPLETED, Math.round(position));
                    this._getRecommendation();
                }
            });

        hasClickedSkip$
            .pipe(withLatestFrom(position$), withLatestFrom(isAudioPlaying$))
            .subscribe(([[isSkipping, position], isPlaying]) => {
                if (isSkipping) {
                    if (!isPlaying) {
                        setTimeout(() => {
                            this._currentRecommendation.recordAction(Action.SKIP, Math.round(position));
                            this._getRecommendation();
                        }, 100); // wait while we send a START first
                    } else {
                        this._currentRecommendation.recordAction(Action.SKIP, Math.round(position));
                        this._getRecommendation();
                    }
                }
            });

        hasClickedShare$
            .pipe(withLatestFrom(position$))
            .subscribe(([isSharing, position]) => {
                if (isSharing) {
                    this._currentRecommendation.recordAction(Action.SHARE, Math.round(position));
                }
            });

        hasClickedMarkInteresting$
            .pipe(withLatestFrom(position$))
            .subscribe(([hasClickedMarkInteresting, position]) => {
                if (hasClickedMarkInteresting) {
                    this._currentRecommendation.recordAction(Action.THUMBUP, Math.round(position));
                }
            });

        hasClickedThrough$
            .pipe(withLatestFrom(position$), withLatestFrom(isClickThroughInternal$))
            .subscribe(([[isClickingThrough, position], isClickThroughInternal]) => {
                if (isClickingThrough) {
                    this._currentRecommendation.recordAction(Action.TAPTHRU, Math.round(position));
                    if (isClickThroughInternal) {
                        this._getRecommendation();
                    }
                }
            });

        hasTimedOut$
            .pipe(withLatestFrom(position$))
            .subscribe(([hasTimedOut, position]) => {
                if (hasTimedOut) {
                    this._currentRecommendation.recordAction(Action.TIMEOUT, Math.round(position));
                    this._getRecommendation();
                }
            });

        needsRecommendationCheckAfterStationChange$.subscribe((needsRecommendationCheckAfterStationChange) => {
            if (needsRecommendationCheckAfterStationChange) {
                if (this._currentRecommendation && this._currentRecommendation.attributes && this._currentRecommendation.attributes.type === 'stationId') {
                    this._api.resetFlow().then(this._getRecommendation.bind(this, undefined, undefined));
                } else {
                    this._store.dispatch(ApiActions.setUserChangedStation(false));
                }
            }
        });
    }

    /**
     * Given a universal identifier for a story, this goes back through the history of the stories that have been played
     * and, if a story with that UID is found, it will move the pointer (index) back to that story.
     *
     * @param {string} uid
     */
    previous(uid: string): void {
        this._recommendations.forEach((recommendation) => {
            if (recommendation.attributes.uid === uid) {
                this._setCurrentRecommendation(recommendation);
            }
        });
    }

    /**
     * This function is intended to be used only by the Explore view; if the user selects a story to play, make sure
     * we mark it as the "active" recommendation in the SDK so that the correct ratings are sent on the next call to
     * `getRecommendation()`; additionally, we pre-load this story in the Metadata store to prevent the MetadataService
     * from making another API call to retrieve the story.
     *
     * @param {string} uid
     * @param {string} [channel='recommended']
     */
    queueRecommendation(uid: string, channel = 'recommended'): void {
        if (!uid || typeof uid !== 'string') {
            throw new TypeError('Must specify a UID, and it must be a valid string');
        }
        if (!channel || typeof channel !== 'string') {
            throw new TypeError('Custom channel name must be a valid string');
        }
        const recommendation = this._api.queueRecommendationFromChannel(channel, uid);
        this._setCurrentRecommendation(recommendation);
    }

    /**
     * Gets the next recommendation from the ApiService. If optionally given a sharedMediaId or channel, it will make
     * a call to the recommendations endpoint with that sharedMediaId or channel param.
     *
     * @param {string} [sharedMediaId='']
     * @param {string} [channel='']
     * @returns {Promise<Recommendation>}
     * @private
     */
    _getRecommendation(sharedMediaId = '', channel = ''): Promise<Recommendation | void> {
        return this._api.getRecommendation(sharedMediaId, channel)
            .then(recommendation => this._setCurrentRecommendation(recommendation))
            .catch(this._handleGetRecommendationFailure.bind(this, sharedMediaId, channel));
    }

    /**
     * Get a list of recommendations, rather than just a single one.
     *
     * @param {string} [channel='']
     * @returns {Promise<Array<Recommendation>>}
     * @private
     */
    _getRecommendationsFromChannel(channel = ''): Promise<Array<Recommendation>> {
        return this._api.getRecommendationsFromChannel(channel)
            .then((recommendations) => {
                this._store.dispatch(ApiActions.setRecommendationsUpdated(recommendations));
                return recommendations; // not for consumption, but useful for unit tests/debugging
            }).catch((error) => {
                this._logger.error('PlaylistService', error); // we do want to log that it happened
                throw error; // ...but we don't have enough information as to how best to handle the error here; rethrow
            });
    }

    /**
     * Takes care of graceful failover if the call to `getRecommendation` throws an error.
     *
     * @param {string} [sharedMediaId='']
     * @param {string} [channel='']
     * @param {?Error} error
     * @returns {Promise<Recommendation>}
     * @private
     */
    _handleGetRecommendationFailure(sharedMediaId = '', channel = '', error?: Error): Promise<Recommendation | void> {
        this._logger.warn('PlaylistService', 'Call to getRecommendation() failed with', error);

        if (channel) {
            this._logger.debug('PlaylistService', 'A channel was specified, so retrying the getRecommendation() call without the channel');
            return this._getRecommendation(sharedMediaId);
        } else if (sharedMediaId) {
            this._logger.debug('PlaylistService', 'A sharedMediaId was specified, so retrying the getRecommendation() call without the sharedMediaId');
            return this._getRecommendation();
        }

        // not much we can do here to resolve the situation
        if (error) {
            throw error;
        } else {
            throw new Error('[PlaylistService] Unable to obtain any recommendations from the API');
        }
    }

    /**
     * Set a pointer to the currently playing recommendation. This function aims to be the
     * only place where _currentRecommendation is mutated.
     *
     * @param {Recommendation} recommendation
     * @private
     */
    _setCurrentRecommendation(recommendation: Recommendation): void {
        if (this._recommendations.indexOf(recommendation) < 0) {
            this._recommendations.push(recommendation);
        }
        this._currentRecommendation = recommendation;
        this._store.dispatch(ApiActions.setStoryLoaded(this._currentRecommendation));
    }
}
export default PlaylistService;
