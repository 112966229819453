import { Injectable } from '@angular/core';

import JPlayerComponent from './jplayer/jplayer.component';

@Injectable()
/**
 * This service class exists because mobile browsers suck and Apple hates developers.
 */
export class MobilePlayService {
    private _initialPlayEventHasHappened: boolean;
    private _jPlayerComponent: JPlayerComponent | null;

    constructor() {
        /** @private */
        this._initialPlayEventHasHappened = false;
        /** @private */
        this._jPlayerComponent = null;
    }

    /**
     * Initialize the service with the one piece it needs: access to the jPlayer component.
     *
     * @param {JPlayerComponent} jPlayerComponent
     */
    initialize(jPlayerComponent: JPlayerComponent): void {
        this._jPlayerComponent = jPlayerComponent;
    }

    /**
     * Tell jPlayer to start playing the audio immediately.
     */
    play(): void {
        if (!this._jPlayerComponent) {
            throw new Error('JPlayerComponent has not been set so the MobilePlayService cannot act.');
        }
        if (!this._initialPlayEventHasHappened) {
            this._initialPlayEventHasHappened = true;
            this._jPlayerComponent.play();
        }
    }

    /**
     * Exposes whether the private JPlayer property has been set or not.
     */
    hasJPlayer(): boolean {
        return this._jPlayerComponent !== null;
    }

    get initialPlayEventHasHappened(): boolean {
        return this._initialPlayEventHasHappened;
    }

}
export default MobilePlayService;
