import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { AnalyticsService } from '../../../analytics/analytics.service';
import { BrowserService } from '../../../core/browser.service';

@Component({
    selector: 'share-button',
    template: `
    <button type="button" *ngIf="!isShareMenuExpanded"
            (click)="openShareMenu($event)">
        <div class="grid__row grid__row--flex grid__row--centered card__button__contents">
            <i class="grid__column grid__column--fixed-width" role="img" aria-label="Share icon"></i>
            <b class="grid__column">Share</b>
        </div>
    </button>
    <div class="grid__row grid__row--flex grid__row--centered grid__row--with-gutters card__button--expanded" *ngIf="isShareMenuExpanded">
        <button type="button" class="grid__column grid__column--fixed-width card__button__social"
                (click)="closeShareMenu($event)">
            <i class="card__button__social__icon card__button__social__icon--close" role="img" aria-label="Close share menu icon"></i>
        </button>
        <button role="link" type="button" class="grid__column grid__column--fixed-width card__button__social"
                (click)="shareOnTwitter($event)">
            <i class="card__button__social__icon card__button__social__icon--twitter" role="img" aria-label="Twitter icon"></i>
        </button>
        <button role="link" type="button" class="grid__column grid__column--fixed-width card__button__social"
                (click)="shareOnFacebook($event)">
            <i class="card__button__social__icon card__button__social__icon--facebook" role="img" aria-label="Facebook icon"></i>
        </button>
    </div>
    `,
    styleUrls: ['./share.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * A very thin component that represents the "Share" button
 */
export class ShareButtonComponent {
    /** @type {boolean} */
    @Input() isShareMenuExpanded: boolean;
    /** @type {string} */
    @Input() storyTitle: string;
    /** @type {string} */
    @Input() shareableLink: string;
    /** @private */
    @Output('onShare') _onShare: EventEmitter<void> = new EventEmitter();
    /** @type {EventEmitter} */
    @Output('onCloseShareMenu') _onCloseShareMenu: EventEmitter<void> = new EventEmitter();

    constructor(private _analytics: AnalyticsService, private _browser: BrowserService) {}

    /**
     * The callback that gets triggered when the user has decided to share the story
     *
     * @listens {click}
     * @param {Event} event
     */
    openShareMenu(event: Event): void {
        event.preventDefault();

        this._onShare.emit();
    }

    /**
     * The callback that gets triggered when the user has decided to close the share menu
     *
     * @listens {click}
     * @param {Event} event
     */
    closeShareMenu(event: Event): void {
        event.preventDefault();

        this._onCloseShareMenu.emit();
    }

    /**
     * The callback that gets triggered when the user has decided to share the story on Twitter
     *
     * @listens {click}
     * @param {Event} event
     */
    shareOnTwitter(event: Event): void {
        event.preventDefault();

        const twitterUrl = `https://twitter.com/intent/tweet?via=nprone&url=${encodeURIComponent(this.shareableLink)}&text=${encodeURIComponent(this.storyTitle).replace(/%20/g, '+')}`;
        const newWindow: Window = this._browser.getWindow().open(twitterUrl, '_blank');
        newWindow.opener = null;

        this._analytics.sendEvent('audio user interactions', 'share', 'twitter');
    }

    /**
     * The callback that gets triggered when the user has decided to share the story on Facebook
     *
     * @listens {click}
     * @param {Event} event
     */
    shareOnFacebook(event: Event): void {
        event.preventDefault();

        const facebookUrl = `http://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.shareableLink)}`;
        const newWindow: Window = this._browser.getWindow().open(facebookUrl, '_blank');
        newWindow.opener = null;

        this._analytics.sendEvent('audio user interactions', 'share', 'facebook');
    }
}
export default ShareButtonComponent;
