import { Injectable } from '@angular/core';
import { Action, NprOneSDK, Recommendation, Station, User } from '@npr/npr-one-sdk';

import { environment } from '../../environments/environment';
import { LoggerService } from '../core/logger.service';

export interface ApiForPlaylistService {
    readonly Action: Action;

    getRecommendation(sharedMediaId: string, channel: string): Promise<Recommendation>;
    getRecommendationsFromChannel(channel: string): Promise<Array<Recommendation>>;
    queueRecommendationFromChannel(channel: string, uid: string): Recommendation;
    resetFlow(): Promise<void>;
}

export interface ApiForStationFinderService {
    searchStations(query?: string): Promise<Array<Station>>;
    getStationDetails(stationId: string | number): Promise<Station>;
}

export interface ApiForUserService {
    readonly authHostname: string;
    accessToken: string;
    onAccessTokenChanged: Function;

    getUser(): Promise<User>;
    createTemporaryUser(): Promise<User>;
    logout(): Promise<void>;
    setUserStation(stationId: string | number): Promise<User>;
}

@Injectable()
/**
 * A global service class which can theoretically be injected anywhere. However, our contract is that the only place
 * where these custom services should be allowed to be interacted with is inside other services.
 */
export class ApiService implements ApiForPlaylistService, ApiForStationFinderService, ApiForUserService {
    public readonly authHostname: string = environment.authHostname.replace(/\/$/, '');

    constructor(private _nprOne: NprOneSDK, private _logger: LoggerService) {
        (<any> NprOneSDK.Logger).setLevel(NprOneSDK.Logger[this._logger.logLevel]);
        NprOneSDK.config = {
            subdomain: environment.apiSubdomain ? `${environment.apiSubdomain}-` : '',
            authProxyBaseUrl: `${this.authHostname}/oauth2`,
            newDeviceCodePath: '/device',
            pollDeviceCodePath: '/device_poll',
            refreshTokenPath: '/refresh',
            tempUserPath: '/temporary',
            logoutPath: '/logout',
            clientId: 'nprone_web',
        };
    }

    /**
     * Gets the access token from the SDK. The SDK should be considered the most up-to-date source.
     *
     * @type {string}
     */
    get accessToken(): string {
        return NprOneSDK.accessToken;
    }

    /**
     * Sets the access token within the SDK
     *
     * @type {string}
     */
    set accessToken(accessToken: string) {
        NprOneSDK.accessToken = accessToken;
    }

    /**
     * Sets the callback that will be triggered whenever the SDK indicates that the access token has been changed
     * behind-the-scenes.
     *
     * @type {Function}
     */
    set onAccessTokenChanged(callback: Function) {
        if (typeof callback !== 'function') {
            throw new TypeError('First parameter for onAccessTokenChanged must be a function');
        }
        NprOneSDK.onAccessTokenChanged = callback;
    }

    /**
     * A shorthand way of accessing the static `Action` class in the SDK without importing the SDK
     *
     * @type {Action}
     */
    get Action(): Action {
        return NprOneSDK.Action;
    }

    /**
     * Returns the results of a call to https://api.npr.org/listening/v2/recommendations via the SDK
     *
     * @param {string} [sharedMediaId=''] An optional sharedMediaId
     * @param {string} [channel=''] An optional channel
     * @returns {Promise<Recommendation>}
     */
    getRecommendation(sharedMediaId = '', channel = ''): Promise<Recommendation> {
        this._logger.debug('ApiService', 'Calling getRecommendation() in the SDK with sharedMediaId',
            sharedMediaId || '<undefined>', 'and channel', channel || '<undefined>');
        return this._nprOne.getRecommendation(sharedMediaId, channel);
    }

    /**
     * Get a list of recommendations from a given channel (if channel is left unspecified, the SDK will default to the
     * channel "recommended"). Not suitable for regular flow behavior; intended for e.g. the Explore view.
     *
     * @param {string} [channel=''] An optional channel
     * @returns {Promise<Array<Recommendation>>}
     */
    getRecommendationsFromChannel(channel = ''): Promise<Array<Recommendation>> {
        this._logger.debug('ApiService', 'Calling getRecommendationsFromChannel() in the SDK with channel',
            channel || '<undefined>');
        return this._nprOne.getRecommendationsFromChannel(channel);
    }

    /**
     * Intended to be paired with {@link getRecommendationsFromChannel}; call this function when the user has selected a
     * story to play from a previously-retrieved list of recommendations from a specific channel (*not* "npr").
     *
     * @param {string} channel
     * @param {string} uid
     * @returns {Recommendation}
     * @throws {TypeError} If no valid channel or uid is passed in
     * @throws {Error} If no recommendations for this channel were previously cached, or if the uid was not found in that cached list
     */
    queueRecommendationFromChannel(channel, uid): Recommendation {
        this._logger.debug('ApiService', 'Calling queueRecommendationFromChannel() in the SDK with uid',
            uid || '<undefined>', 'and channel', channel || '<undefined>');
        return this._nprOne.queueRecommendationFromChannel(channel, uid);
    }

    /**
     * @returns {Promise<User>}
     */
    getUser(): Promise<User> {
        this._logger.debug('ApiService', 'Calling getUser() in the SDK');
        return this._nprOne.getUser();
    }

    /**
     * @returns {Promise<User>}
     */
    createTemporaryUser(): Promise<User> {
        this._logger.debug('ApiService', 'Calling createTemporaryUser() in the SDK');
        return this._nprOne.createTemporaryUser();
    }

    /**
     * @returns {Promise}
     */
    logout(): Promise<void> {
        this._logger.debug('ApiService', 'Calling logout() in the SDK');
        return this._nprOne.logout();
    }

    /**
     * @param {null|string} [query]
     * @returns {Promise<Array<Station>>}
     */
    searchStations(query?: string): Promise<Array<Station>> {
        this._logger.debug('ApiService', 'Calling searchStations() in the SDK with query', query);
        return this._nprOne.searchStations(query);
    }

    /**
     * @param {string} stationId
     * @returns {Promise<Station>}
     */
    getStationDetails(stationId: string | number): Promise<Station> {
        this._logger.debug('ApiService', 'Calling getStationDetails() in the SDK with ID', stationId);
        return this._nprOne.getStationDetails(stationId);
    }

    /**
     * @param {string|number} stationId
     * @returns {Promise<User>}
     */
    setUserStation(stationId: string | number): Promise<User> {
        this._logger.debug('ApiService', `Setting the current user's station to ${stationId}`);
        return this._nprOne.setUserStation(stationId);
    }

    /**
     * @returns {Promise}
     */
    resetFlow(): Promise<void> {
        this._logger.debug('ApiService', 'Calling resetFlow() in the SDK');
        return this._nprOne.resetFlow();
    }
}
export default ApiService;
