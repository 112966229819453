import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from '../../environments/environment';
import { MetadataComponent } from '../metadata/metadata.component';
import { MetadataModule } from '../metadata/metadata.module';

/**
 * The below Routes array is how you set up routes in Angular 2+. It's pretty straightforward. Right now we
 * only have a few components that have routes, but if we wanted to have e.g. an 'About' page accessible at
 * https://one.npr.org/about, this is where we'd add it.
 *
 * By mapping our / route to MetadataComponent, we're putting that component in charge of routing the main app.
 * The reason for this is because the old webapp (whose behavior we are trying to maintain) can handle and generate
 * links of the form https://one.npr.org/?sharedMediaId=466040050:466040052 which should play the story represented
 * by that UID.
 *
 * In the future, we should strongly consider migrating the webapp's links to the
 * https://one.npr.org/i/466040050:466040052 format, which currently just redirects to
 * https://one.npr.org/?sharedMediaId=466040050:466040052
 * Angular has much better built-in support for the former (which would allow us to set up better custom route
 * handling using path params) than the latter query param-based routing, which Angular provides no help with.
 */
const routes: Routes = [
    { path: 'explore', loadChildren: () => import('../explore/explore.module').then(m => m.ExploreModule) },
    { path: 'stations', loadChildren: () => import('../station/station.module').then(m => m.StationModule) },
    { path: 'localize', loadChildren: () => import('../pre-localize/pre-localize.module').then(m => m.PreLocalizeModule) },
    { path: '', component: MetadataComponent, pathMatch: 'full' },
    { path: '**', redirectTo: '/' },
];

@NgModule({
    imports: [
        MetadataModule,
        RouterModule.forRoot(routes, { enableTracing: !environment.production && environment.debugRouter }),
    ],
    exports: [
        RouterModule,
    ],
})
export class AppRoutingModule {
}
