export enum ActionTypes {
    INIT = 'INIT',

    ROUTE_CHANGED = 'ROUTE_CHANGED',

    USER_LOADED = 'USER_LOADED',
    USER_CHANGED_STATION = 'USER_CHANGED_STATION',

    METADATA_LOADING = 'METADATA_LOADING',
    METADATA_LOADED = 'METADATA_LOADED',

    RECOMMENDATIONS_UPDATING = 'RECOMMENDATIONS_UPDATING',
    RECOMMENDATIONS_UPDATED = 'RECOMMENDATIONS_UPDATED',

    AUDIO_PLAYING = 'AUDIO_PLAYING',
    AUDIO_COMPLETE = 'AUDIO_COMPLETE',
    AUDIO_LOADING = 'AUDIO_LOADING',
    AUDIO_LOADED = 'AUDIO_LOADED',
    AUDIO_POSITION_CHANGE = 'AUDIO_POSITION_CHANGE',
    AUDIO_DURATION_CHANGE = 'AUDIO_DURATION_CHANGE',

    FORCE_PLAY = 'FORCE_PLAY',
    TOGGLE_PLAY = 'TOGGLE_PLAY',
    REWIND = 'REWIND',
    SEEK = 'SEEK',
    SCRUB_BEGIN = 'SCRUB_BEGIN',
    SCRUB_END = 'SCRUB_END',

    TOGGLE_VOLUME_CONTROLS = 'TOGGLE_VOLUME_CONTROLS',
    TOGGLE_MUTE = 'TOGGLE_MUTE',
    AUDIO_VOLUME_CHANGE = 'AUDIO_VOLUME_CHANGE',
    VOLUME_SLIDER_BEGIN = 'VOLUME_SLIDER_BEGIN',
    VOLUME_SLIDER_END = 'VOLUME_SLIDER_END',

    SKIP = 'SKIP',
    TIMEOUT = 'TIMEOUT',

    SHARE = 'SHARE',
    CLOSE_SHARE = 'CLOSE_SHARE',
    MARK_INTERESTING = 'MARK_INTERESTING',
    CLICK_THROUGH = 'CLICK_THROUGH',

    SIGN_IN = 'SIGN_IN',
    SIGN_OUT = 'SIGN_OUT',

    TOGGLE_JUNK_DRAWER = 'TOGGLE_JUNK_DRAWER',
    TOGGLE_USER_MENU = 'TOGGLE_USER_MENU',
    TOGGLE_ORGANIZATION_MENU = 'TOGGLE_ORGANIZATION_MENU',

    STATION_SEARCH_RESULTS_UPDATING = 'STATION_SEARCH_RESULTS_UPDATING',
    STATION_SEARCH_RESULTS_UPDATED = 'STATION_SEARCH_RESULTS_UPDATED',
    STATION_DETAILS_UPDATED = 'STATION_DETAILS_UPDATED',
}
export default ActionTypes;
