import * as fromRouter from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import RootState from './root.state';

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
const rootReducer: ActionReducerMap<RootState> = {
    router: fromRouter.routerReducer,
};
export default rootReducer;
