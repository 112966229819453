import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import FeatureStores from '../constants/feature-stores.enum';

import PlayerActions from './player.actions';
import { PlayerPlusRootState, PlayerState } from './player.state';

@Injectable()
/**
 * The service class to be paired with the PlayerComponent. All business logic that does not directly result in
 * a view being updated should be placed here. This includes all interactions with the {@link Store}.
 */
export class PlayerService {
    public state$: Observable<PlayerState>;

    constructor(private _store: Store<PlayerPlusRootState>) {
        /** @type {Observable} */
        this.state$ = this._store.select(FeatureStores.PLAYER);
    }

    /**
     * The callback to trigger when the third-party audio player reports that audio is playing
     *
     * @param {boolean} isAudioPlaying
     */
    onAudioPlaying(isAudioPlaying: boolean): void {
        this._store.dispatch(PlayerActions.setAudioPlaying(isAudioPlaying));
    }

    /**
     * The callback to trigger when the third-party audio player reports that the audio track has completed
     */
    onAudioComplete(): void {
        this._store.dispatch(PlayerActions.audioComplete());
    }

    /**
     * The callback to trigger when the third-party audio player reports that the audio is currently buffering
     */
    onAudioBuffering(): void {
        this._store.dispatch(PlayerActions.audioBuffering());
    }

    /**
     * The callback to trigger when the third-party audio player has loaded the audio and is ready to play
     */
    onAudioLoaded(): void {
        this._store.dispatch(PlayerActions.audioLoaded());
    }

    /**
     * The callback to trigger when the third-party audio player reports that the position within the track has changed
     *
     * @param {number} position
     */
    onTimeUpdate(position: number): void {
        this._store.dispatch(PlayerActions.updatePosition(position));
    }

    /**
     * The callback to trigger when the third-party audio player reports the duration of the audio track
     *
     * @param {number} duration
     */
    onAudioDurationChange(duration: number): void {
        this._store.dispatch(PlayerActions.changeDuration(duration));
    }

    /**
     * The callback to trigger when the user has pushed the play or pause buttons
     */
    onTogglePlay(): void {
        this._store.dispatch(PlayerActions.togglePlay());
    }

    /**
     * The callback to trigger when the user has pushed the skip button
     */
    onSkip(): void {
        this._store.dispatch(PlayerActions.skip());
    }

    /**
     * The callback to trigger when the user has pushed the rewind button
     */
    onRewind(): void {
        this._store.dispatch(PlayerActions.rewind());
    }

    /**
     * The callback to trigger when the user has selected a new position within the audio track
     *
     * @param {number} position
     */
    onSeek(position: number): void {
        this._store.dispatch(PlayerActions.seek(position));
    }

    /**
     * The callback to trigger when the user has begun scrubbing
     */
    onScrubBegin(): void {
        this._store.dispatch(PlayerActions.scrubBegin());
    }

    /**
     * The callback to trigger when the user has finished scrubbing
     */
    onScrubEnd(): void {
        this._store.dispatch(PlayerActions.scrubEnd());
    }

    /**
     * The callback to trigger when the user has toggled whether or not the volume controls should be shown
     */
    onToggleVolumeControls(): void {
        this._store.dispatch(PlayerActions.toggleVolumeControls());
    }

    /**
     * The callback to trigger when the user has pushed the mute or unmute buttons
     */
    onToggleMute(): void {
        this._store.dispatch(PlayerActions.toggleMute());
    }

    /**
     * The callback to trigger when the user has selected a different volume for the audio player
     *
     * @param {number} volume
     */
    onAudioVolumeChange(volume: number): void {
        this._store.dispatch(PlayerActions.changeVolume(volume));
    }

    /**
     * The callback to trigger when the user has begun changing the volume by sliding the volume slider
     */
    onVolumeSliderBegin(): void {
        this._store.dispatch(PlayerActions.volumeSliderBegin());
    }

    /**
     * The callback to trigger when the user has finished changing the volume by sliding the volume slider
     */
    onVolumeSliderEnd(): void {
        this._store.dispatch(PlayerActions.volumeSliderEnd());
    }
}
export default PlayerService;
