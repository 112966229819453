import { AudioLink } from '@npr/npr-one-sdk';

import RootState from '../app/root.state';
import FeatureStores from '../constants/feature-stores.enum';

/**
 * @typedef {Object} PlayerState
 * @property {boolean} isLoading Whether or not the player is in the process of loading a new recommendation or the audio is still loading or buffering; essentially indicates the audio is not ready to play
 * @property {boolean} isPlaying Whether or not the user engaged the player to play
 * @property {boolean} isAudioPlaying Whether the audio player library reports that audio is actually playing
 * @property {boolean} isAudioComplete Whether or not the audio has completed playing
 * @property {boolean} isScrubbing Whether or not the user is actively scrubbing the player
 * @property {boolean} hasDisabledControls Whether or not play controls should be disabled (for e.g. sponsorship)
 * @property {number} duration The duration of the audio piece (in seconds), initially from the API, but confirmed by jPlayer
 * @property {number} position The position (in seconds) corresponding to where the user is in the current piece
 * @property {number} seekPosition The position (in seconds) that the user is trying to seek/scrub to
 * @property {number} volume A numeric representation of the desired volume level, between 0 and 1, defaulting to 0.8
 * @property {boolean} isVolumeControlExpanded Whether or not the user has engaged the volume control sub-menu
 * @property {boolean} isChangingVolume Whether or not the user is in the process of changing the audio volume
 * @property {boolean} isMuted Whether or not the player should be muted
 * @property {Array<Link>} audioUrls A list of audio URLs (along with their corresponding mime-types) from the API
 * @property {string} uid The UID of the recommendation being played, ONLY for debugging/internal use!!!
 */
export interface PlayerState {
    isLoading: boolean;
    isPlaying: boolean;
    isAudioPlaying: boolean;
    isAudioComplete: boolean;
    isScrubbing: boolean;
    hasDisabledControls: boolean;
    duration: number;
    position: number;
    seekPosition: number;
    volume: number;
    isVolumeControlExpanded: boolean;
    isChangingVolume: boolean;
    isMuted: boolean;
    audioUrls: Array<AudioLink>;
    uid: string;
}

export interface PlayerPlusRootState extends RootState {
    [FeatureStores.PLAYER]: PlayerState;
}

/**
 * Initial state (and general structure) of the player store.
 *
 * @type {PlayerState}
 */
const initialState: PlayerState = {
    isLoading: true,
    isPlaying: false,
    isAudioPlaying: false,
    isAudioComplete: false,
    isScrubbing: false,
    hasDisabledControls: false,
    duration: 0,
    position: 0,
    seekPosition: 0,
    volume: 0.8,
    isVolumeControlExpanded: false,
    isChangingVolume: false,
    isMuted: false,
    audioUrls: [],
    uid: '',
};

export function getInitialPlayerState() {
    return initialState;
}

export default initialState;
