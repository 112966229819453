/*
These strings, e.g. 'C0001', come from OneTrust, and can be edited in the "categorizations" section of OneTrust's app.
Be sure to update this file if you make changes in OneTrust's app.
This file also exists in the ID/Auth codebase
 */
export const STRICTLY_NECESSARY = 'C0001';
export const PERFORMANCE_AND_ANALYTICS = 'C0002';
export const FUNCTIONAL = 'C0003';
export const TARGETING_AND_SPONSOR = 'C0004';
export const SOCIAL_MEDIA = 'C0005';

export function hasConsentedTo(category) {
    // checking for "window" because this could run in a windowless unit-test environment
    if (window && typeof window.OnetrustActiveGroups !== 'undefined') {
        return window.OnetrustActiveGroups.split(',').includes(category);
    }

    return true;
}
