import { Action } from '@ngrx/store';

import ActionTypes from '../constants/action-types.enum';
import { SignInAction } from '../util/shared.actions';


export class ToggleJunkDrawerAction implements Action {
    readonly type = ActionTypes.TOGGLE_JUNK_DRAWER;
}

export class ToggleUserMenuAction implements Action {
    readonly type = ActionTypes.TOGGLE_USER_MENU;
}

export class ToggleOrganizationMenuAction implements Action {
    readonly type = ActionTypes.TOGGLE_ORGANIZATION_MENU;
}

export class SignOutAction implements Action {
    readonly type = ActionTypes.SIGN_OUT;
}

export type AllNavActions
    = ToggleJunkDrawerAction
    | ToggleUserMenuAction
    | ToggleOrganizationMenuAction
    | SignOutAction;

/**
 * @typedef {Object} NavActions
 * @property {function(): Action} toggleJunkDrawer
 * @property {function(): Action} toggleUserMenu
 * @property {function(): Action} toggleOrganizationMenu
 * @property {function(): Action} signIn
 * @property {function(): Action} signOut
 */
const NavActions = {
    toggleJunkDrawer(): ToggleJunkDrawerAction {
        return new ToggleJunkDrawerAction();
    },

    toggleUserMenu(): ToggleUserMenuAction {
        return new ToggleUserMenuAction();
    },

    toggleOrganizationMenu(): ToggleOrganizationMenuAction {
        return new ToggleOrganizationMenuAction();
    },

    signIn(): SignInAction {
        return new SignInAction();
    },

    signOut(): SignOutAction {
        return new SignOutAction();
    },
};
export default NavActions;
