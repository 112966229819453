import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { AnalyticsService } from './analytics.service';
import { BranchApiKeyProvider, BranchProvider } from './branch.provider';
import { CampaignService } from './campaign.service';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [],
    providers: [
        AnalyticsService,
        BranchProvider,
        BranchApiKeyProvider,
        CampaignService,
    ],
})
export class AnalyticsModule {
    constructor(@Optional() @SkipSelf() parentModule: AnalyticsModule) {
        if (parentModule) {
            throw new Error(
                'AnalyticsModule is already loaded. Import it in the AppModule only');
        }
    }
}
