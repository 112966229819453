export const sharedEnvironment = {
  'baseHref': '/',
  'apiSubdomain': '',
  'authHostname': 'https://one.npr.org',
  'appHostname': 'https://one.npr.org',
  'wwwHostname': 'https://www.npr.org',
  'cdnStaticPath': '',
  'cdnImagePath': 'https://media.npr.org/one/',
  'branchApiKey': 'key_live_bhk8xNbf03HoKQnyLTb6KfigDDfGWNuf'
}

export default sharedEnvironment;