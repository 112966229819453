import { FormFactorLink, ImageLink, Link, Recommendation } from '@npr/npr-one-sdk';

import { environment } from '../../environments/environment';
import CardTypeImages, { FeatureCardTypeImages } from '../constants/card-types-images.enum';
import CardTypes, { FeatureCardTypes } from '../constants/card-types.enum';
import FallbackImages from '../constants/fallback-images.enum';

/**
 * @typedef {Object} Story
 * @property {string} templateType The type of story for the purposes of determining which template to use; collapses all feature card types into 'featureCard' except upsells which are given their own card type of 'upsell'
 * @property {string} type The type of recommendation, usually 'audio'. Can also be 'stationId', 'sponsorship', etc.
 * @property {string} uid The universal identifier of the story
 * @property {string} title The title of the story
 * @property {string} slug A slug or category for the story
 * @property {string} provider The provider of the story, usually 'NPR'. Can also be a member station or third-party podcast provider.
 * @property {Date|null} date The date at which the story was first published
 * @property {string} rationale The reason for recommending this story to the listener, from the API
 * @property {number} duration The duration of the piece, in seconds
 * @property {string} description A short description of the story, usually not shown in the UI but instead used for social media
 * @property {Array<ImageLink>} images An array of image(s) associated with the story
 * @property {ImageLink|null} primaryImage The primary image to accompany the story content, if applicable
 * @property {ImageLink|null} logo The podcast cover art (logo) to accompany a regular story, if it exists
 * @property {string|null} caption The primary image caption that can be used in alt tags, if applicable
 * @property {string} webLink The URL to link to on stories that have a "Read Story" button
 * @property {string} shareableLink The URL to link to when sharing stories via social media
 * @property {boolean} isShareable Whether or not the story should be considered shareable
 * @property {string} callToActionText The text to display on the call-to-action button(s), usually only used for feature cards
 * @property {Array} callsToAction One or more actions to take, usually only used for feature cards
 * @property {Array} sponsorshipClickthroughs One or more click-through URLs for sponsorship items
 */
export interface Story {
    templateType: string;
    type: string;
    uid: string;
    title: string;
    slug: string;
    provider: string;
    date: Date | null;
    rationale: string;
    duration: number;
    description: string;
    images: Array<ImageLink>;
    primaryImage: ImageLink | null;
    logo: ImageLink | null;
    caption: string | null;
    webLink: string;
    shareableLink: string;
    isShareable: boolean;
    callToActionText: string;
    callsToAction: Array<Link>;
    sponsorshipClickthroughs: Array<FormFactorLink>;
}

/**
 * Checks whether the feature card is an upsell
 *
 * @param {Recommendation} story
 * @returns {boolean}
 * @private
 */
function checkIfFeatureCardIsUpsell(story: Recommendation): boolean {
    if (story && story.attributes.type === FeatureCardTypes.EXTLINK) {
        return story.callsToAction.some(link => /store-redirect\.php$/.test(link.href));
    }
    return false;
}

/**
 * Returns an "adjusted" story type, which collapses all feature cards down into one type,
 * in order to allow them to share one template.
 *
 * @param {Recommendation} story
 * @returns {string}
 * @private
 */
function getAdjustedStoryType(story: Recommendation): string {
    if (story && story.attributes.type) {
        if ((new RegExp(`^${CardTypes.FEATURE}`)).test(story.attributes.type)) {
            if (checkIfFeatureCardIsUpsell(story) === true) {
                return CardTypes.UPSELL;
            }
            return CardTypes.FEATURE;
        }
        return story.attributes.type;
    }
    return '';
}

/**
 * Helper function that gets the image of the desired crop (rel) type.
 *
 * @param {Recommendation} story
 * @param {string} relType
 * @returns {ImageLink|null}
 * @private
 */
function getImageOfRelType(story: Recommendation, relType: string): ImageLink {
    if (story && story.images) {
        return story.images.find(image => image.rel === relType);
    }
    return null;
}

/**
 * Gets the image of the desired crop type to be rendered by the story as the primary content image,
 * based on the story's (adjusted) type.
 *
 * @param {Recommendation} story
 * @param {string} adjustedStoryType
 * @param {number} fallbackIndex
 * @returns {ImageLink|null}
 * @private
 */
function getContentImage(story: Recommendation, adjustedStoryType: string, fallbackIndex: number): ImageLink {
    if (story && adjustedStoryType) {
        switch (adjustedStoryType) {
            case CardTypes.STATION:
                const stationLogo = getImageOfRelType(story, CardTypeImages.STATION);
                if (!stationLogo && story.attributes.title === 'NPR') {
                    return {
                        'content-type': 'image/png',
                        href: `${environment.cdnImagePath.replace(/\/$/, '')}/img/logos/npr.png`,
                        rel: 'logo',
                        width: 350,
                        height: 120,
                    };
                }
                return stationLogo;
            case CardTypes.FEATURE:
                const icon = getImageOfRelType(story, FeatureCardTypeImages.INFO);
                const logo = getImageOfRelType(story, CardTypeImages.FEATURE);
                if (icon) {
                    return icon;
                }
                return logo;
            case CardTypes.UPSELL:
                // the upsell card type handles images itself because we want mobile and desktop to render
                // different image resolutions based on size/form-factor
                break;
            case CardTypes.SPONSORSHIP:
                // sponsorship handles images itself because we will eventually want mobile and desktop to render
                // different image resolutions based on size/form-factor
                break;
            default:
                const image = getImageOfRelType(story, CardTypeImages.DEFAULT);
                if (image) {
                    return {
                        ...image,
                        href: /\?s=600$/.test(image.href) ? image.href.replace(/\?s=600$/, '?s=6') : image.href,
                    };
                }
                return {
                    'content-type': 'image/png',
                    href: `${environment.cdnImagePath.replace(/\/$/, '')}/img/fallbacks/bkgr-fall-${fallbackIndex}.png`,
                    rel: 'fallback',
                };
        }
    }
    return null;
}

/**
 * Returns the logo (i.e. podcast cover art) for a story that's not one of the special card types.
 *
 * @param {Recommendation} story
 * @param {string} adjustedStoryType
 * @returns {ImageLink|null}
 * @private
 */
function _getLogo(story: Recommendation, adjustedStoryType: string): ImageLink {
    if (story && adjustedStoryType) {
        switch (adjustedStoryType) {
            case CardTypes.STATION:
            case CardTypes.FEATURE:
            case CardTypes.SPONSORSHIP:
            case CardTypes.UPSELL:
                break;
            default:
                return getImageOfRelType(story, 'logo_square');
        }
    }
    return null;
}

/**
 * Get the "read story" link for a story
 *
 * @private
 * @param {Recommendation} story
 * @returns {string|null}
 */
function getReadStoryLink(story: Recommendation): string {
    if (story.web) {
        const foundLink = story.web.find(link => link['content-type'] === 'text/html');
        if (foundLink) {
            return foundLink.href;
        }
    }
    return null;
}

/**
 * Get the social media-shareable link for a story
 *
 * @param {Recommendation} story
 * @returns {string|null}
 * @private
 */
function getShareableLink(story: Recommendation): string {
    if (story && story.isShareable()) {
        const foundLink = story.onramps.find(link => link['content-type'] === 'text/html');
        if (foundLink) {
            return foundLink.href;
        }
    }
    return null;
}


/**
 * A utility class that parses recommendations from the API (by way of the SDK) into the format we need to actually
 * render them into various templates.
 */
export default class RecommendationParser {
    /**
     * Parses a raw API recommendation into the format we want for our reducers
     *
     * @param {Recommendation} story
     * @param {number} fallbackIndex
     * @returns {Story}
     */
    static parse(story: Recommendation, fallbackIndex: number): Story {
        const adjustedStoryType = getAdjustedStoryType(story);
        const contentImage = getContentImage(story, adjustedStoryType, fallbackIndex);

        return {
            templateType: adjustedStoryType,
            type: story.attributes.type || '',
            uid: story.attributes.uid || '',
            title: story.attributes.title || '',
            slug: story.attributes.slug || story.attributes.program || '',
            provider: story.attributes.provider || '',
            date: story.attributes.date ? new Date(story.attributes.date) : null,
            rationale: story.attributes.rationale || '',
            duration: story.attributes.duration || 0,
            description: story.attributes.description || '',
            images: story.images || [],
            primaryImage: contentImage,
            logo: _getLogo(story, adjustedStoryType),
            caption: contentImage ? (contentImage as any).caption || '' : '',
            webLink: getReadStoryLink(story) || '',
            shareableLink: getShareableLink(story) || '',
            isShareable: story.isShareable(),
            callToActionText: story.attributes.button || '',
            callsToAction: story.callsToAction || [],
            sponsorshipClickthroughs: story.relateds || [],
        };
    }

    /**
     * Parses a list of recommendations and returns them in a new array.
     *
     * @param {Array<Recommendation>} stories
     * @returns {Array<Story>}
     */
    static parseList(stories: Array<Recommendation>): Array<Story> {
        let fallbackIndex = FallbackImages.MIN_FALLBACK_INDEX;

        return stories.map((story) => {
            const parsedStory = RecommendationParser.parse(story, fallbackIndex);
            fallbackIndex = fallbackIndex === FallbackImages.MAX_FALLBACK_INDEX ?
                FallbackImages.MIN_FALLBACK_INDEX : fallbackIndex + 1;
            return parsedStory;
        });
    }
}
