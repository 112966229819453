export enum FeatureStores {
    PLAYLIST = 'playlist',
    STATION_FINDER = 'stationFinder',
    USER = 'user',
    METADATA = 'metadata',
    PLAYER = 'player',
    NAV = 'nav',
    EXPLORE = 'explore',
    STATION = 'station',
}
export default FeatureStores;
