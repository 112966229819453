import { Station } from '@npr/npr-one-sdk';

import RootState from '../app/root.state';
import FeatureStores from '../constants/feature-stores.enum';

/**
 * @typedef {Object} NavState
 * @property {boolean} isJunkDrawerExpanded
 * @property {boolean} isUserMenuExpanded
 * @property {boolean} isOrganizationMenuExpanded
 * @property {boolean} isSignedIn
 * @property {Station|null} stationDetails
 */
export interface NavState {
    isJunkDrawerExpanded: boolean;
    isUserMenuExpanded: boolean;
    isOrganizationMenuExpanded: boolean;
    isSignedIn: boolean;
    stationDetails: Station | null;
}

export interface NavPlusRootState extends RootState {
    [FeatureStores.NAV]: NavState;
}

/**
 * The initial state (and general structure) of the nav store.
 *
 * @type {NavState}
 */
const initialState: NavState = {
    isJunkDrawerExpanded: false,
    isUserMenuExpanded: false,
    isOrganizationMenuExpanded: false,
    isSignedIn: false,
    stationDetails: null,
};

export function getInitialNavState() {
    return initialState;
}

export default initialState;
