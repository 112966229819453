import RootState from '../../app/root.state';
import FeatureStores from '../../constants/feature-stores.enum';

/**
 * @typedef {Object} StationFinderState
 * @property {boolean} isUserLoaded
 * @property {boolean} needsStationSearch
 * @property {string} searchQuery
 * @property {string} stationId
 */
export interface StationFinderState {
    isUserLoaded: boolean;
    needsStationSearch: boolean;
    searchQuery: string;
    stationId: string;
}

export interface StationFinderPlusRootState extends RootState {
    [FeatureStores.STATION_FINDER]: StationFinderState;
}

/**
 * Initial state (and general structure) of the stationFinder store.
 *
 * @type {StationFinderState}
 */
const initialState: StationFinderState = {
    isUserLoaded: false,
    needsStationSearch: false,
    searchQuery: '',
    stationId: '',
};

export function getInitialStationFinderState() {
    return initialState;
}

export default initialState;
