import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { StationFinderService } from '../api/station/station-finder.service';
import { UserService } from '../api/user/user.service';
import { User } from '../api/user/user.state';
import FeatureStores from '../constants/feature-stores.enum';

import NavActions from './nav.actions';
import { NavPlusRootState, NavState } from './nav.state';

@Injectable()
/**
 * The service class to be paired with the NavComponent. All business logic that does not directly result in
 * a view being updated should be placed here. This includes all interactions with the {@link Store}.
 */
export class NavService {
    public state$: Observable<NavState>;
    public user$: Observable<User>;

    constructor(private _store: Store<NavPlusRootState>, private _userService: UserService, private _stationFinderService: StationFinderService) {
        // note: we do not actually need StationFinderService in this class, but if it isn't injected, it won't start

        /** @type {Observable} */
        this.state$ = this._store.select(FeatureStores.NAV);
        /** @type {Observable<User>} */
        this.user$ = this._userService.user$;
    }

    /**
     * The callback to trigger when the component is ready for a user to be loaded.
     */
    loadUser(): void {
        this._userService.loadUser();
    }

    /**
     * The callback to trigger when a user has clicked on the "junk drawer" nav icon
     */
    onToggleJunkDrawer(): void {
        this._store.dispatch(NavActions.toggleJunkDrawer());
    }

    /**
     * The callback to trigger when a user has toggled the user menu
     */
    onToggleUserMenu(): void {
        this._store.dispatch(NavActions.toggleUserMenu());
    }

    /**
     * The callback to trigger when a user has toggled the organization menu
     */
    onToggleOrganizationMenu(): void {
        this._store.dispatch(NavActions.toggleOrganizationMenu());
    }

    /**
     * The callback to trigger when a user has clicked on the "Sign In" button
     */
    onSignIn(): void {
        this._store.dispatch(NavActions.signIn());
        this._userService.startOAuthSignIn();
    }

    /**
     * The callback to trigger when a user has clicked on the "Sign Out" button
     */
    onSignOut(): void {
        this._store.dispatch(NavActions.signOut());
        this._userService.signOutUser();
    }
}
export default NavService;
