import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { BrowserService } from '../../core/browser.service';

@Component({
    selector: 'volume-controls',
    template: `
    <button type="button" class="player__play-control" aria-kbdshortcuts="AudioVolumeMute \"
            (click)="toggleVolumeControls($event)"  tabindex="0">
        <i class="player__play-control__icon player__play-control__icon--volume" role="img" aria-label="Volume control icon"
           [class.player__play-control__icon--volume--muted]="isMuted"></i>
    </button>
    <div class="player__volume-menu" *ngIf="isVolumeControlExpanded" [class.player__volume-menu--in-use]="isChangingVolume">
        <div class="player__volume-menu__wrapper" aria-kbdshortcuts="AudioVolumeUp AudioVolumeDown [ ]"
             (mousemove)="moveVolumeSlider($event)" (mouseup)="stopChangingVolume($event)" (mouseleave)="stopChangingVolume($event)">
            <div class="player__volume-menu__volume-bar" role="slider" aria-orientation="vertical" aria-valuemin="0" aria-valuemax="1"
                 [attr.aria-valuenow]="volume"
                 (mousedown)="startChangingVolume($event)" (drag)="moveVolumeSlider($event)" (dragend)="stopChangingVolume($event)">
                <div class="player__volume-menu__volume-bar__value" [style.height.%]="volume * 100"></div>
            </div>
        </div>
    </div>
    `,
    styleUrls: ['./volume-controls.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * A sub-component of the player representing the volume controls
 *
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class VolumeControlsComponent implements OnInit, OnDestroy {
    /** @type {number} */
    @Input() volume: number;
    /** @type {boolean} */
    @Input() isMuted: boolean;
    /** @type {boolean} */
    @Input() isVolumeControlExpanded: boolean;
    /** @type {boolean} */
    @Input() isChangingVolume: boolean;
    /** @type {boolean} */
    @Input() isPlayerHidden: boolean;
    /** @private */
    @Output('onToggleVolumeControls') _onToggleVolumeControls: EventEmitter<void> = new EventEmitter();
    /** @private */
    @Output('onToggleMute') _onToggleMute: EventEmitter<void> = new EventEmitter();
    /** @private */
    @Output('onAudioVolumeChange') _onAudioVolumeChange: EventEmitter<number> = new EventEmitter();
    /** @private */
    @Output('onVolumeSliderBegin') _onVolumeSliderBegin: EventEmitter<void> = new EventEmitter();
    /** @private */
    @Output('onVolumeSliderEnd') _onVolumeSliderEnd: EventEmitter<void> = new EventEmitter();

    constructor(private _elementRef: ElementRef, private _browser: BrowserService) {}

    /**
     * The actions that should be taken when a component has been initialized.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnInit-interface.html
     */
    ngOnInit() {
        this._browser.getWindow().document.addEventListener('keyup', this._processKeyUp.bind(this), false);
    }

    /**
     * The actions that should be taken right before a component is destroyed.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnDestroy-interface.html
     */
    ngOnDestroy() {
        this._browser.getWindow().document.removeEventListener('keyup', this._processKeyUp.bind(this), false);
    }

    /**
     * The callback that gets triggered when the user has toggled whether or not the volume controls should be shown
     *
     * @listens {click}
     * @param {Event} event
     */
    toggleVolumeControls(event: Event): void {
        event.preventDefault();

        this._onToggleVolumeControls.emit();
    }

    /**
     * The callback that gets triggered when the user clicks on the volume mute button
     *
     * @listens {click}
     * @param {Event} event
     */
    toggleMute(event: Event): void {
        event.preventDefault();

        this._onToggleMute.emit();
    }

    /**
     * The callback that gets triggered when the user changes the volume
     *
     * @listens {click}
     * @param {Event} event
     */
    changeVolume(event: Event): void {
        let normalizedY;
        /* istanbul ignore next */ // no good way to test this because there's no great way to mock events
        if (event instanceof MouseEvent) {
            normalizedY = event.pageY;
        } else if (event instanceof TouchEvent) {
            if (typeof event.touches !== 'undefined' && event.touches.length) {
                normalizedY = event.touches[0].clientY;
            }
        } else {
            return;
        }

        this._onAudioVolumeChange.emit(this._getVisualProgress(normalizedY));
    }

    /**
     * The callback that gets triggered when the user mouses over the volume controls
     *
     * @listens {mouseover}
     * @param {Event} event
     */
    startChangingVolume(event: Event): void {
        event.preventDefault();

        if (!this.isChangingVolume) {
            this._onVolumeSliderBegin.emit();
        }
        this.changeVolume(event);
    }

    /**
     * The callback that gets triggered when the user moves over the volume control slider
     *
     * @listens {mousemove}
     * @param {Event} event
     */
    moveVolumeSlider(event: Event): void {
        event.preventDefault();

        if (this.isChangingVolume) {
            this.changeVolume(event);
        }
    }

    /**
     * The callback that gets triggered when the user's mouse leaves the volume controls
     *
     * @listens {mouseleave}
     * @param {Event} event
     */
    stopChangingVolume(event: Event): void {
        event.preventDefault();

        if (this.isChangingVolume) {
            this._onVolumeSliderEnd.emit();
        }
    }

    /**
     * This is a modification of the audio scrubber controls; see ProgressBarComponent for the original code.
     *
     * @param {number} position
     * @returns {number}
     * @private
     */
    _getVisualProgress(position: number): number {
        const childElements = this._elementRef.nativeElement.getElementsByClassName('player__volume-menu__volume-bar');
        if (!childElements.length) {
            return this.volume; // trick to get the volume to just not change
        }
        const trackElement = childElements[0];
        const { top, height } = trackElement.getBoundingClientRect();
        const progress = (position - top) / height;

        // Clamp progress to [0, 1]
        return 1 - Math.max(Math.min(progress, 1), 0);
    }

    /**
     * A function to manually change the volume by a certain amount.
     *
     * @param {number} amountToChange A float in the range of -1 to +1, to be added to the current volume
     * @private
     */
    _changeVolumeByAmount(amountToChange: number): void {
        const newVolume = Math.max(Math.min(this.volume + amountToChange, 1), 0);

        if (this.volume === newVolume) {
            return;
        }

        this._onAudioVolumeChange.emit(newVolume);
    }

    /**
     * Processes keyboard/remote controller input.
     *
     * @see https://developer.amazon.com/public/solutions/platforms/webapps/docs/supporting-controllers-in-web-apps
     * @see https://msdn.microsoft.com/en-us/library/windows/desktop/dd375731(v=vs.85).aspx
     * @see https://fmtvp.github.io/tal/jsdoc/symbols/src/antie_static_script_events_keyevent.js.html
     *
     * @listens {keyup}
     * @param {Event} event
     * @private
     */
    _processKeyUp(event: KeyboardEvent): void {
        if (this.isPlayerHidden) {
            return;
        }

        if (event.key === '\\') {
            this.toggleMute(event);
                return;
        }

        if (event.key === ']') {
            event.preventDefault();
            this._changeVolumeByAmount(-0.10);
            return;
        }

        if (event.key === '[') {
            event.preventDefault();
            this._changeVolumeByAmount(0.10);
            return;
        }

        /** @todo: remove deprecated keycode use for firetv and replace with event.key  */
        const key = event.keyCode !== 0 ? event.keyCode : parseInt(event.code, 10); // tslint:disable-line:deprecation

        switch (key) {
            case 173: // Microsoft keyboard "VK_VOLUME_MUTE" key code
            // falls through
            case 220: // backslash
            // falls through
            case 449: // "VK_MUTE" key code
                this.toggleMute(event);
                return;

            case 174: // Microsoft keyboard "VK_VOLUME_DOWN" key code
            // falls through
            case 219: // open bracket
            // falls through
            case 448: // Alternative "VK_VOLUME_DOWN" key code
                event.preventDefault();
                this._changeVolumeByAmount(-0.10);
                return;

            case 175: // Microsoft keyboard "VK_VOLUME_UP" key code
            // falls through
            case 221: // close bracket
            // falls through
            case 447: // Alternative "VK_VOLUME_UP" key code
                event.preventDefault();
                this._changeVolumeByAmount(0.10);
                break;

            default:
                break;
        }
    }
}
export default VolumeControlsComponent;
