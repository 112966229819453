/**
 * @typedef {Object} MetadataConstants
 * @property {number} MIN_FALLBACK_INDEX The minimum (file) index of the fallback images
 * @property {number} MAX_FALLBACK_INDEX The maximum (file) index of the fallback images
 */
/**
 * Miscellaneous constants for use in the Metadata package.
 *
 * @type {MetadataConstants}
 */
const FallbackImages = {
    MIN_FALLBACK_INDEX: 1,
    MAX_FALLBACK_INDEX: 6,
};
export default FallbackImages;
