import { ImageLink, Recommendation } from '@npr/npr-one-sdk';

import { AllActions } from '../all.actions';
import ActionTypes from '../constants/action-types.enum';
import CardTypes from '../constants/card-types.enum';
import FallbackImages from '../constants/fallback-images.enum';
import RecommendationParser from '../util/recommendation-parser';

import initialState, { MetadataState } from './metadata.state';

type Action = AllActions;

/**
 * Gets the image of the desired crop type to be rendered by the story as a background texture.
 * In general, this will be the same image that will be rendered regularly by the story card itself.
 *
 * @param {Recommendation} story
 * @param {string} adjustedStoryType
 * @param {ImageLink|null} contentImage
 * @returns {ImageLink|null}
 * @private
 */
function _getTextureImage(story: Recommendation, adjustedStoryType: string, contentImage: ImageLink): ImageLink {
    if (story && adjustedStoryType) {
        switch (adjustedStoryType) {
            case CardTypes.STATION:
            case CardTypes.FEATURE:
            case CardTypes.SPONSORSHIP:
            case CardTypes.UPSELL:
                break;
            default:
                if (contentImage && contentImage.rel !== 'fallback') {
                    return contentImage;
                }
                break;
        }
    }
    return null;
}

/**
 * The primary reducer for the metadata module.
 *
 * @param {MetadataState} state
 * @param {Action} action
 * @returns {MetadataState}
 */
const metadataReducer = (state = initialState, action: Action): MetadataState => {
    switch (action.type) {
        case ActionTypes.METADATA_LOADING:
            return {
                ...state,
                isLoading: true,
                story: initialState.story,
                background: initialState.background,
                hasMarkedInteresting: false,
                isShareMenuExpanded: false,
            };

        case ActionTypes.METADATA_LOADED:
            /** @type {Recommendation} */
            const story = action.payload.story;
            if (story.attributes.uid === state.story.uid) {
                return state;
            }
            let isFirstStory = false;
            if (state.story.uid === initialState.story.uid) {
                isFirstStory = true;
            }
            let fallbackIndex = state.background.repeatingGradientVariation;
            if (!isFirstStory) {
                fallbackIndex = state.background.repeatingGradientVariation === FallbackImages.MAX_FALLBACK_INDEX ?
                    FallbackImages.MIN_FALLBACK_INDEX : state.background.repeatingGradientVariation + 1;
            }
            const parsedStory = RecommendationParser.parse(story, fallbackIndex);
            const contentImageIsFallback = !!(parsedStory.primaryImage && parsedStory.primaryImage.rel === 'fallback');
            const textureImage = _getTextureImage(story, parsedStory.templateType, parsedStory.primaryImage);
            const shouldUseGradientTexture = !textureImage && !contentImageIsFallback && parsedStory.templateType !== CardTypes.UPSELL;
            return {
                ...state,
                isLoading: false,
                story: parsedStory,
                background: {
                    textureImage,
                    shouldUseGradientTexture,
                    shouldUseGrayGradientTexture: shouldUseGradientTexture && story.attributes.type === CardTypes.SPONSORSHIP,
                    shouldUseRepeatingGradientTexture: contentImageIsFallback,
                    repeatingGradientVariation: contentImageIsFallback ? fallbackIndex : state.background.repeatingGradientVariation,
                    shouldUseFlatGrayBackground: parsedStory.templateType === CardTypes.UPSELL,
                },
                hasMarkedInteresting: false,
                isShareMenuExpanded: false,
            };

        case ActionTypes.MARK_INTERESTING:
            return {
                ...state,
                hasMarkedInteresting: true,
            };

        case ActionTypes.SHARE:
            return {
                ...state,
                isShareMenuExpanded: true,
            };

        case ActionTypes.CLOSE_SHARE:
            return {
                ...state,
                isShareMenuExpanded: false,
            };

        default:
            return state;
    }
};
export default metadataReducer;
