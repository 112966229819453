import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { LogLevels } from '../constants/log-levels.enum';

import { BrowserService } from './browser.service';
import { LogLevel } from './log-level.provider';

/* tslint:disable:no-console */
@Injectable()
/**
 * A service class which is a thin shim around the `console.log` functions, with a configurable log level.
 */
export class LoggerService {
    private _logLevel: string;

    /**
     * Sets the internal log level to a value passed in by the build.
     */
    constructor(@Inject(LogLevel) logLevel: string, private _browser: BrowserService) {
        this._logLevel = LogLevels[logLevel] ? logLevel : 'ERROR';
    }

    /**
     * @returns {string}
     */
    get logLevel() {
        return this._logLevel;
    }

    /**
     * Parses the current window's query parameters and sets the logLevel to 'DEBUG' if '?_DEBUG' is set
     */
    setLogLevelBasedOnUrl(): void {
        const queryParams = new HttpParams({ fromString: this.currentQueryString.replace(/^\?/, '') });
        const keys = queryParams.keys();
        keys.forEach(key => {
            if (key.toUpperCase() === '_DEBUG') {
                this._logLevel = 'DEBUG';
            }
        });
        this.info('LoggerService', 'Using log level:', this._logLevel);
    }

    /**
     * Prints a debug message to the console if the log level is 'DEBUG' or lower
     *
     * @param {string} callingClassName
     * @param {...*} messages
     */
    debug(callingClassName: string, ...messages): void {
        if (LogLevels[this._logLevel] <= LogLevels.DEBUG) {
            console.debug(`[${callingClassName}]`, LoggerService._getStringifiedMessage(messages));
        }
    }

    /**
     * Prints an info message to the console if the log level is 'INFO' or lower
     *
     * @param {string} callingClassName
     * @param {...*} messages
     */
    info(callingClassName: string, ...messages): void {
        if (LogLevels[this._logLevel] <= LogLevels.INFO) {
            console.info(`[${callingClassName}]`, LoggerService._getStringifiedMessage(messages));
        }
    }

    /**
     * Prints a log message to the console if the log level is 'INFO' or lower
     *
     * @param {string} callingClassName
     * @param {...*} messages
     */
    log(callingClassName: string, ...messages): void {
        if (LogLevels[this._logLevel] <= LogLevels.INFO) {
            console.log(`[${callingClassName}]`, LoggerService._getStringifiedMessage(messages));
        }
    }

    /**
     * Prints a warning message to the console if the log level is 'WARN' or lower
     *
     * @param {string} callingClassName
     * @param {...*} messages
     */
    warn(callingClassName: string, ...messages): void {
        if (LogLevels[this._logLevel] <= LogLevels.WARN) {
            console.warn(`[${callingClassName}]`, LoggerService._getStringifiedMessage(messages));
        }
    }

    /**
     * Prints an error message to the console if the log level is 'ERROR' or lower
     *
     * @param {string} callingClassName
     * @param {...*} messages
     */
    error(callingClassName: string, ...messages): void {
        if (LogLevels[this._logLevel] <= LogLevels.ERROR) {
            console.error(`[${callingClassName}]`, LoggerService._getStringifiedMessage(messages));
        }
    }

    /**
     * Takes an array of messages which can arbitrarily contain any primitive types,
     * and ensure that they are properly stringified, before joining all the array
     * elements together into one long string.
     *
     * @param {Array<*>} messages
     * @returns {string}
     * @private
     */
    private static _getStringifiedMessage(messages: Array<any>): string {
        return messages.map((message) => {
            /* istanbul ignore next: honesty no idea what Istanbul wants me to do here, this code is definitely being hit */
            if (message instanceof Error) {
                return message.message;
            } else if (typeof message === 'object') {
                return JSON.stringify(message);
            }
            return message;
        }).join(' ');
    }

    private get currentQueryString(): string {
        const window = this._browser.getWindow();
        return window.location.search;
    }
}
export default LoggerService;
/* tslint:enable:no-console */
