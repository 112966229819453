import AllActions from '../all.actions';
import ActionTypes from '../constants/action-types.enum';

import initialState, { PlayerState } from './player.state';

type Action = AllActions;

/**
 * The primary reducer for the player module.
 *
 * @param {PlayerState} state
 * @param {Action} action
 * @return {PlayerState}
 */
const playerReducer = (state = initialState, action: Action): PlayerState => {
    switch (action.type) {
        case ActionTypes.METADATA_LOADING:
            return {
                ...state,
                isLoading: true,
                isAudioPlaying: false,
                isAudioComplete: false,
                isScrubbing: false,
                duration: 0,
                position: 0,
                seekPosition: 0,
                isVolumeControlExpanded: false,
                isChangingVolume: false,
                audioUrls: [],
            };

        case ActionTypes.METADATA_LOADED:
            /** @type {Recommendation} */
            const story = action.payload.story;
            const isSameStory = story.attributes.uid === state.uid;
            return {
                ...state,
                isAudioComplete: isSameStory ? state.isAudioComplete : false,
                isScrubbing: false,
                hasDisabledControls: !story.attributes.skippable || false,
                duration: story.attributes.duration || 0,
                position: isSameStory ? state.position : 0,
                seekPosition: isSameStory ? state.position : 0,
                isVolumeControlExpanded: false,
                isChangingVolume: false,
                audioUrls: story.audio || [],
                uid: story.attributes.uid || initialState.uid,
            };

        case ActionTypes.FORCE_PLAY: // Not for general use; in most cases you should use `TOGGLE_PLAY` instead.
            return {
                ...state,
                isPlaying: true,
            };

        case ActionTypes.TOGGLE_PLAY:
            return {
                ...state,
                isPlaying: !state.isPlaying,
                isVolumeControlExpanded: false,
            };

        case ActionTypes.AUDIO_PLAYING:
            return {
                ...state,
                isAudioPlaying: action.payload.isAudioPlaying,
                isLoading: action.payload.isAudioPlaying === true ? false : state.isLoading,
            };

        case ActionTypes.AUDIO_COMPLETE:
            return {
                ...state,
                isAudioPlaying: false,
                isAudioComplete: true,
                isLoading: false,
            };

        case ActionTypes.AUDIO_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.AUDIO_LOADED:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.AUDIO_POSITION_CHANGE:
            return {
                ...state,
                position: action.payload && action.payload.position ? Math.max(Math.min(action.payload.position, state.duration), 0) : state.position,
            };

        case ActionTypes.AUDIO_DURATION_CHANGE:
            return {
                ...state,
                duration: action.payload && action.payload.duration ? action.payload.duration : state.duration,
                position: action.payload && action.payload.duration ? Math.min(state.position, action.payload.duration) : state.position,
            };

        case ActionTypes.REWIND:
            let seekPosition = Math.max(state.position - 15, 0);
            if (seekPosition === state.seekPosition) {
                seekPosition += 0.001; // to fool .distinctUntilChanged() into thinking the value has actually changed ...
            }
            return {
                ...state,
                seekPosition,
                isVolumeControlExpanded: false,
            };

        case ActionTypes.SEEK:
            return {
                ...state,
                seekPosition: action.payload && action.payload.position ? Math.max(Math.min(action.payload.position, state.duration), 0) : state.seekPosition,
                isVolumeControlExpanded: false,
            };

        case ActionTypes.SCRUB_BEGIN:
            return {
                ...state,
                isScrubbing: true,
                seekPosition: state.position,
                isVolumeControlExpanded: false,
            };

        case ActionTypes.SCRUB_END:
            return {
                ...state,
                isScrubbing: false,
            };

        case ActionTypes.TOGGLE_VOLUME_CONTROLS:
            return {
                ...state,
                isVolumeControlExpanded: !state.isVolumeControlExpanded,
                isChangingVolume: state.isVolumeControlExpanded === true ? false : state.isChangingVolume,
            };

        case ActionTypes.TOGGLE_MUTE:
            return {
                ...state,
                isMuted: !state.isMuted,
            };

        case ActionTypes.AUDIO_VOLUME_CHANGE:
            return {
                ...state,
                isVolumeControlExpanded: true,
                volume: action.payload.volume,
                isMuted: action.payload.volume < 0.05,
            };

        case ActionTypes.VOLUME_SLIDER_BEGIN:
            return {
                ...state,
                isChangingVolume: true,
            };

        case ActionTypes.VOLUME_SLIDER_END:
            return {
                ...state,
                isChangingVolume: false,
            };

        case ActionTypes.SKIP:
            return {
                ...state,
                isPlaying: true,
                isVolumeControlExpanded: false,
            };

        case ActionTypes.TOGGLE_JUNK_DRAWER:
            return {
                ...state,
                isVolumeControlExpanded: false,
            };

        case ActionTypes.TOGGLE_USER_MENU:
            return {
                ...state,
                isVolumeControlExpanded: false,
            };

        case ActionTypes.MARK_INTERESTING:
            return {
                ...state,
                isVolumeControlExpanded: false,
            };

        case ActionTypes.SHARE:
            return {
                ...state,
                isVolumeControlExpanded: false,
            };

        case ActionTypes.CLOSE_SHARE:
            return {
                ...state,
                isVolumeControlExpanded: false,
            };

        case ActionTypes.ROUTE_CHANGED:
            return {
                ...state,
                isVolumeControlExpanded: false,
            };

        case ActionTypes.SIGN_OUT:
            return {
                ...state,
                isPlaying: false,
            };

        default:
            return state;
    }
};
export default playerReducer;
