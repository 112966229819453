import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'progress-bar',
    template: `
    <div class="player__progress-bar" *ngIf="hasAudio" [class.player__play-control--inactive]="hasDisabledControls"
         [class.player__progress-bar--playing]="isAudioPlaying" [class.player__progress-bar--scrubbing]="isScrubbing"
         [class.player__progress-bar--hidden]="!isBrowserTabActive"
         (mousemove)="moveScrubber($event)" (mouseup)="endScrubbing($event)" (mouseleave)="endScrubbing($event)">
        <div class="player__progress-bar__seek-bar" role="progressbar" aria-valuemin="0"
             [attr.aria-disabled]="hasDisabledControls" [attr.aria-valuemax]="duration" [attr.value-now]="audioPosition"
             (mousedown)="beginScrubbing($event)" (drag)="moveScrubber($event)" (dragend)="endScrubbing($event)">
            <div class="player__progress-bar__play-bar" [style.width.%]="(audioPosition / duration) * 100"></div>
        </div>
        <div class="player__progress-bar__time" role="timer">
            {{ audioPosition | duration }} <span role="separator">/</span> <span class="player__progress-bar__time--bold">{{ duration | duration }}</span>
        </div>
    </div>
    `,
    styleUrls: ['./progress-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * A sub-component of the player representing the progress bar and seek/scrubbing controls
 */
export class ProgressBarComponent {
    /** @type {boolean} */
    @Input() hasAudio: boolean;
    /** @type {boolean} */
    @Input() isAudioPlaying: boolean;
    /** @type {boolean} */
    @Input() hasDisabledControls: boolean;
    /** @type {number} */
    @Input() audioPosition: number;
    /** @type {number} */
    @Input() duration: number;
    /** @type {boolean} */
    @Input() isScrubbing: boolean;
    /** @type {boolean} */
    @Input() isBrowserTabActive: boolean;
    /** @private */
    @Output('onSeek') _onSeek: EventEmitter<number> = new EventEmitter();
    /** @private */
    @Output('onScrubBegin') _onScrubBegin: EventEmitter<void> = new EventEmitter();
    /** @private */
    @Output('onScrubEnd') _onScrubEnd: EventEmitter<void> = new EventEmitter();

    constructor(private _elementRef: ElementRef) {}

    /**
     * The callback that gets triggered when the user clicks anywhere on the player progress bar.
     * Also called by the scrubbing callbacks to handle scrubbing.
     *
     * @listens {click}
     * @param {Event} event
     */
    seek(event: Event): void {
        event.preventDefault();

        if (this.hasDisabledControls) {
            return;
        }

        let normalizedX;
        /* istanbul ignore next */ // no good way to test this because there's no great way to mock events
        if (event instanceof MouseEvent) {
            normalizedX = event.pageX;
        } else if (event instanceof TouchEvent) {
            if (typeof event.touches !== 'undefined' && event.touches.length) {
                normalizedX = event.touches[0].clientX;
            }
        } else {
            return;
        }

        this._onSeek.emit(this._getVisualProgress(normalizedX) * this.duration);
    }

    /**
     * The callback that gets triggered when the user begins scrubbing the player progress bar.
     *
     * @listens {mousedown}
     * @param {Event} event
     */
    beginScrubbing(event: Event): void {
        event.preventDefault();

        if (this.hasDisabledControls) {
            return;
        }

        if (!this.isScrubbing) {
            this._onScrubBegin.emit();
        }
        this.seek(event);
    }

    /**
     * The callback that gets triggered continuously while the user is scrubbing the player progress bar.
     *
     * @listens {mousemove}
     * @listens {drag}
     * @param {Event} event
     */
    moveScrubber(event: Event): void {
        event.preventDefault();

        if (this.hasDisabledControls) {
            return;
        }

        if (this.isScrubbing) {
            this.seek(event);
        }
    }

    /**
     * The callback that gets triggered when the user lets go of the scrubber.
     *
     * @listens {mouseup}
     * @listens {dragend}
     * @listens {mouseleave}
     * @param {Event} event
     */
    endScrubbing(event: Event): void {
        event.preventDefault();

        if (this.hasDisabledControls) {
            return;
        }

        if (this.isScrubbing) {
            this._onScrubEnd.emit();
        }
    }

    /**
     * Copied shamelessly from the Player project so don't ask me for the precise logic behind this function.
     *
     * @param {number} position
     * @returns {number}
     * @private
     */
    _getVisualProgress(position: number): number {
        const childElements = this._elementRef.nativeElement.getElementsByClassName('player__progress-bar__seek-bar');
        if (!childElements.length) {
            return this.audioPosition / this.duration; // trick to get the position to just not change
        }
        const trackElement = childElements[0];
        const { left, width } = trackElement.getBoundingClientRect();
        const progress = (position - left) / width;

        // Clamp progress to [0, 1]
        return Math.max(Math.min(progress, 1), 0);
    }
}
export default ProgressBarComponent;
