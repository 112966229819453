import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { PlaylistService } from '../api/playlist/playlist.service';
import FeatureStores from '../constants/feature-stores.enum';
import { Story } from '../util/recommendation-parser';

import MetadataActions from './metadata.actions';
import initialState, { MetadataPlusRootState, MetadataState } from './metadata.state';

@Injectable()
/**
 * The service class to be paired with the MetadataComponent. All business logic that does not directly result in
 * a view being updated should be placed here. This includes all interactions with the {@link Store}.
 */
export class MetadataService {
    public state$: Observable<MetadataState>;

    private _sharedMediaId: string;
    private _channel: string;
    private _currentStory: Story;

    constructor(private _store: Store<MetadataPlusRootState>, private _playlist: PlaylistService) {
        /** @type {Observable} */
        this.state$ = this._store.select(FeatureStores.METADATA);
        /** @private */
        this._sharedMediaId = '';
        /** @private */
        this._channel = '';
        const story$ = this.state$.pipe(map(s => s.story), startWith(initialState.story), distinctUntilChanged());
        story$.subscribe((story) => {
            this._currentStory = story;
        });
    }

    /**
     * Sets the sharedMediaId (uid) for the initial recommendation.
     *
     * @type {string} uid
     */
    set sharedMediaId(uid) {
        this._sharedMediaId = uid;
    }

    /**
     * Gets the sharedMediaId (uid)
     */
    get sharedMediaId() {
        return this._sharedMediaId;
    }

    /**
     * Sets the channel for the initial recommendation. Once the initial recommendation has been consumed, this is no
     * longer guaranteed to be accurate.
     *
     * @type {string} channel
     */
    set channel(channel) {
        this._channel = channel;
    }

    /**
     * Gets the channel
     */
    get channel() {
        return this._channel;
    }

    /**
     * Recording the route change will close open menus.
     */
    recordRouteChange(): void {
        this._store.dispatch(MetadataActions.recordRouteChange());
    }

    /**
     * Kicks off the chain of actions that will result in an update of the UI with the first story.
     * By default, that first story is the very first result from the ApiService's call to the
     * getRecommendations endpoint. If the user arrived here via a sharedMediaId, assuming the ID was
     * valid, it will queue up that story as the first recommendation instead.
     */
    loadFirstRecommendation(): void {
        if (this._currentStory && this._currentStory.uid && this._sharedMediaId && this._currentStory.uid === this._sharedMediaId) {
            return; // we already have the story we want preloaded; nothing to do here
        }

        this._store.dispatch(MetadataActions.loadStory(this._sharedMediaId, this._channel));
    }

    /**
     * Assuming the passed-in sharedMediaId (uid) was a story that the user previously heard, it will
     * jump back to that point in their history (without making a call to the API). If the story was
     * not previously heard and/or the sharedMediaId is invalid, it does nothing.
     *
     * @param {string} sharedMediaId
     */
    goBackTo(sharedMediaId: string): void {
        this._playlist.previous(sharedMediaId);
    }

    /**
     * The callback to trigger when a user has clicked the mark interesting button
     */
    onMarkInteresting(): void {
        this._store.dispatch(MetadataActions.markInteresting());
    }

    /**
     * The callback to trigger when a user has clicked the share button
     */
    onShare(): void {
        this._store.dispatch(MetadataActions.share());
    }

    /**
     * The callback to trigger when a user has clicked the 'X' inside the share sub-menu
     */
    onCloseShareMenu(): void {
        this._store.dispatch(MetadataActions.closeShareMenu());
    }

    /**
     * The callback to trigger when a user has clicked through on a sponsorship item or feature card
     *
     * @param {boolean} isInternal
     */
    onClickThrough(isInternal: boolean): void {
        this._store.dispatch(MetadataActions.clickThrough(isInternal));
    }
}
export default MetadataService;
