import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Link } from '@npr/npr-one-sdk';

import { BrowserService } from '../../../core/browser.service';
import { Story } from '../../../util/recommendation-parser';

@Component({
    selector: 'sponsorship',
    template: `
    <div class="grid__column">
        <header class="card__header">
            <h1 class="card__title">{{story.title}}</h1>
        </header>
        <aside class="card__image center" role="complementary">
            <div *ngFor="let image of story.images" class="card__image__wrapper"
                 [attr.data-form-factor]="image['form-factor']">
                <img role="link" tabindex="0" class="image--clickable"
                     [src]="image.href" [width]="image.width" [height]="image.height" [alt]="story.title"
                     (click)="executeClickthrough($event, image['form-factor'])" />
            </div>
        </aside>
    </div>
    `,
    styleUrls: ['./sponsorship.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * This component is essentially just a holding class for the template that should only be applied if the current
 * recommendation is a sponsorship item.
 */
export class SponsorshipComponent {
    /** @type {Story} */
    @Input() story: Story;
    /** @type {EventEmitter} */
    @Output('onClickThrough') _onClickThrough: EventEmitter<boolean> = new EventEmitter();

    constructor(private _browser: BrowserService) {}

    /**
     * The event listener that gets called when the user clicks on the sponsorship image
     *
     * @param {Event} event
     * @param {string} formFactor
     */
    executeClickthrough(event: Event, formFactor: string): void {
        event.preventDefault();

        let isInternal = false;
        const clickthroughs = this._findClickthroughs(formFactor);
        clickthroughs.forEach((clickthrough) => {
            switch (clickthrough['content-type']) {
                case 'application/x-nprone':
                    /*
                     * sponsored channel items have links that look like e.g.:
                     * nprone://listen?channel=sponsoredChannelOne
                     */
                    isInternal = true;
                    break;
                case 'text/html':
                    const newWindow: Window = this._browser.getWindow().open(clickthrough.href, '_blank');
                    newWindow.opener = null;
                    break;
                default:
                    break;
            }
        });

        this._onClickThrough.emit(isInternal);
    }

    /**
     * Find one or more clickthrough URLs for the given form-factor
     *
     * @param {string} formFactor
     * @returns {Array}
     * @private
     */
    _findClickthroughs(formFactor: string): Array<Link> {
        const clickthroughs = [];
        this.story.sponsorshipClickthroughs.forEach((clickthrough) => {
            if (clickthrough['form-factor'] === formFactor) {
                clickthroughs.push(clickthrough);
            }
        });
        return clickthroughs;
    }
}
export default SponsorshipComponent;
