import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { User } from '../../api/user/user.state';
import BrowserService from '../../core/browser.service';

@Component({
    selector: 'junk-drawer',
    template: `
    <ul *ngIf="isExpanded" class="grid__column grid__column--no-grow site__nav__sub-menu" role="menu"
        (click)="doNotClose($event)" (keyup)="_processEscUp($event)" id="junkDrawer">
        <li *ngIf="!isNowPlayingActive" class="site__nav__item site__nav__item--mobile-only site__nav__item--with-emphasis" role="menuitem">
            <a tabindex="0" routerLink="/" id="nowPlayingSubmenuLink">Now Playing</a>
        </li>
        <li *ngIf="!isExploreActive" class="site__nav__item site__nav__item--mobile-only site__nav__item--with-emphasis" role="menuitem">
            <a tabindex="0" routerLink="/explore" id="exploreSubmenuLink">Explore</a>
        </li>
        <li class="site__nav__item site__nav__item--mobile-only site__nav__item--with-emphasis" role="menuitem">
            <a rel="me external noopener" tabindex="0" target="_blank" href="http://www.npr.org/about/products/npr-one/"
               (click)="clickAppsLink($event)">Get The App</a>
        </li>
        <li class="site__nav__item" role="menuitem">
            <a rel="external noopener" tabindex="0" target="_blank"
               [href]="'http://help.npr.org/customer/portal/emails/new?i=6&s=NPR%20Apps&s2=NPR%20One&appversion=2&device=web' + (userIsSignedIn ? ('&userid=' + user.id) : '')">Send Us Feedback</a>
        </li>
        <li class="site__nav__item" role="menuitem">
            <a rel="external noopener" tabindex="0" target="_blank" href="http://www.npr.org/about-npr/179878450/privacy-policy">Privacy Policy</a>
        </li>
        <li class="site__nav__item" role="menuitem">
            <a rel="external noopener" tabindex="0" target="_blank" href="http://www.npr.org/about-npr/179876898/terms-of-use">Terms of Use</a>
        </li>
        <li class="site__nav__item" role="menuitem">
            <a rel="external noopener" tabindex="0" target="_blank" href="https://www.npr.org/about-npr/179878450/privacy-policy#yourchoices">Privacy Choices</a>
        </li>
        <li class="site__nav__item" role="menuitem">
            <a rel="external noopener" tabindex="0" target="_blank" href="http://www.npr.org/about-npr/179881519/rights-and-permissions-information">Permissions</a>
        </li>
        <li class="site__nav__item site__nav__item--linkless" role="menuitem" aria-disabled="true">
            © {{year}} NPR
        </li>
    </ul>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 * The menu that gets shown when you click on the triple-dotted icon in the top-right corner
 */
export class JunkDrawerComponent implements OnInit, OnDestroy, OnChanges {
    /** @type {boolean} */
    @Input() isExpanded: boolean;
    /** @type {boolean} */
    @Input() userIsSignedIn: boolean;
    /** @type {User} */
    @Input() user: User;
    /** @type {boolean} */
    @Input() isNowPlayingActive: boolean;
    /** @type {boolean} */
    @Input() isExploreActive: boolean;
    /** @private */
    @Output('close') _close: EventEmitter<void> = new EventEmitter();
    /** @private */
    @Output('onClickAppsLink') _onClickAppsLink: EventEmitter<Event> = new EventEmitter();

    public year: string;
    public keyboardShortcuts: string;
    /**
     * Grabs the year from the current user's clock and saves it to reference in the template.
     */
    constructor(private _browser: BrowserService) {
        /** @type {string} */
        this.year = new Date().getFullYear().toString();
        /** @type {string} */
        this.keyboardShortcuts = 'Esc BlurMenu';
    }

    ngOnInit() {
        this._browser.getWindow().document.addEventListener('keyup', this._processEscUp.bind(this), false);
    }

    ngOnDestroy() {
        this._browser.getWindow().document.removeEventListener('keyup', this._processEscUp.bind(this), false);
    }

    ngOnChanges() {
        if (this.isExpanded === true) {
            this.keyboardShortcuts = 'Escape BlurMenu';
        } else {
            this.keyboardShortcuts = 'Escape DoNothing';
        }
    }
    /**
     * The callback that gets triggered when the user closes the junk drawer by clicking on the backdrop
     *
     * @listens {click}
     * @param {Event} event
     */
    close(event: Event): void {
        event.preventDefault();
        this._close.emit();
    }

    /* tslint:disable class-methods-use-this */
    /**
     * The callback that gets triggered when the user clicks inside the menu, which should NOT close the junk drawer.
     *
     * @listens {click}
     * @param event
     */
    doNotClose(event: Event): void {
        event.stopPropagation();
    }
    /* tslint:enable class-methods-use-this */

    /**
     * The callback that gets triggered when the user clicks on the "Get The App" link.
     *
     * @listens {click}
     * @param {Event} event
     */
    clickAppsLink(event: Event): void {
        event.preventDefault();

        this._onClickAppsLink.emit(event);
    }
    /**
     * The callback that gets triggered when the user closes the junk drawer by hitting the escape key
     *
     * @listens {keyup}
     * @param {Event} event
     */

   _processEscUp(event: KeyboardEvent): void {
       event.preventDefault();
        if (!this.isExpanded) {
            return;
        }

        if (event.key === 'Escape') {
            this._close.emit();
            return;
        }
    }
}

export default JunkDrawerComponent;
