import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import {combineLatest,  SubscriptionLike as ISubscription } from 'rxjs';

import { BrowserService } from '../../core/browser.service';
import { LoggerService } from '../../core/logger.service';

import { JPlayerAudioSources, JPlayerService } from './jplayer.service';

@Component({
    selector: 'jplayer',
    template: '<div id="jplayer"></div>',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * This is a very thin component layer whose sole job is to interface with jPlayer. This does not control the audio
 * player UI, but we use jPlayer as our communication go-between with the HTML5 audio API.
 *
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
export class JPlayerComponent implements OnInit, OnChanges, OnDestroy {
    /** @type boolean */
    @Input() isBrowserTabActive: boolean;
    /** @private */
    @Output('onAudioPlaying') _onAudioPlaying: EventEmitter<boolean> = new EventEmitter();
    /** @private */
    @Output('onAudioComplete') _onAudioComplete: EventEmitter<void> = new EventEmitter();
    /** @private */
    @Output('onAudioBuffering') _onAudioBuffering: EventEmitter<boolean> = new EventEmitter();
    /** @private */
    @Output('onAudioLoaded') _onAudioLoaded: EventEmitter<boolean> = new EventEmitter();
    /** @private */
    @Output('onTimeUpdate') _onTimeUpdate: EventEmitter<number> = new EventEmitter();
    /** @private */
    @Output('onAudioDurationChange') _onAudioDurationChange: EventEmitter<number> = new EventEmitter();

    public _onJplayerReady: EventEmitter<boolean>; // @TODO make private or remove underscore
    public _subscriptions: Array<ISubscription>; // @TODO make private or remove underscore

    constructor(private _elementRef: ElementRef, private _jPlayerService: JPlayerService, private _logger: LoggerService, private _browser: BrowserService) {
        /** @public */
        this._onJplayerReady = new EventEmitter();
        /** @public */
        this._subscriptions = [];
    }

    /**
     * The actions that should be taken when a component has been initialized. In our case, this is when we
     * can safely instantiate jPlayer.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnInit-interface.html
     */
    ngOnInit() {
        this._subscriptions.push(combineLatest([this._onJplayerReady, this._jPlayerService.instructions.play])
            .subscribe(([ready, position]) => {
                if (ready) {
                    this.play(<number> position);
                }
            }));
        this._subscriptions.push(combineLatest([this._onJplayerReady, this._jPlayerService.instructions.pause])
            .subscribe(([ready]) => {
                if (ready) {
                    this._pause();
                }
            }));
        this._subscriptions.push(combineLatest([this._onJplayerReady, this._jPlayerService.instructions.load])
            .subscribe(([ready, src]) => {
                if (ready && src) {
                    this._load(src);
                }
            }));
        this._subscriptions.push(combineLatest([this._onJplayerReady, this._jPlayerService.instructions.seek])
            .subscribe(([ready, position]) => {
                if (ready && typeof position !== 'undefined') {
                    this._seek(<number> position);
                }
            }));
        this._subscriptions.push(combineLatest([this._onJplayerReady, this._jPlayerService.instructions.setVolume])
            .subscribe(([ready, volume]) => {
                if (ready && typeof volume !== 'undefined') {
                    this._setVolume(<number> volume);
                }
            }));
        this._subscriptions.push(combineLatest([this._onJplayerReady, this._jPlayerService.instructions.mute])
            .subscribe(([ready]) => {
                if (ready) {
                    this._mute();
                }
            }));
        this._subscriptions.push(combineLatest([this._onJplayerReady, this._jPlayerService.instructions.unmute])
            .subscribe(([ready]) => {
                if (ready) {
                    this._unmute();
                }
            }));

        $(this._elementRef.nativeElement).jPlayer(this._getJplayerInitializationSettings());
    }

    /**
     * The actions that should be taken right after the data-bound properties have been checked and before view and
     * content children are checked if at least one of them has changed.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnChanges-interface.html
     *
     * @param {Object} changes
     */
    ngOnChanges(changes) {
        if (changes.isBrowserTabActive) {
            const isVisible = changes.isBrowserTabActive.currentValue;
            if (this._isAMobileDevice()) {
                if (isVisible) { // it was not visible before, but it is now -- so we want to add a slight delay
                    setTimeout(() => {
                        this._jPlayerService.isBrowserTabActiveOnMobileDevice = isVisible;
                    }, 3000); // 3 seconds
                } else {
                    this._jPlayerService.isBrowserTabActiveOnMobileDevice = isVisible;
                }
            }
        }
    }

    /**
     * The actions that should be taken right before a component is destroyed.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnDestroy-interface.html
     */
    ngOnDestroy() {
        $(this._elementRef.nativeElement).jPlayer('destroy');
        // don't forget to clean up the subscriptions
        this._subscriptions.forEach((s) => {
            s.unsubscribe();
        });
    }

    /**
     * Instructs jPlayer to play the loaded audio, optionally starting at a specific position
     *
     * @param {number} [position=0]
     */
    play(position = 0): void {
        $(this._elementRef.nativeElement).jPlayer('play', position);
    }

    /**
     * Instructs jPlayer to pause the loaded audio.
     *
     * @private
     */
    _pause(): void {
        $(this._elementRef.nativeElement).jPlayer('pause');
    }

    /**
     * Instructs jPlayer to load the specified media
     *
     * @param {JPlayerAudioSources} src
     * @private
     */
    _load(src: JPlayerAudioSources): void {
        $(this._elementRef.nativeElement).jPlayer('setMedia', src);
    }

    /**
     * Instructs jPlayer to change the track position to the specified percentage
     *
     * @param {number} seekPercent
     * @private
     */
    _seek(seekPercent: number): void {
        $(this._elementRef.nativeElement).jPlayer('playHead', seekPercent);
    }

    /**
     * Instructs jPlayer to change the audio volume to the specified ratio between 0 and 1
     *
     * @param {number} volumeRatio
     * @private
     */
    _setVolume(volumeRatio: number): void {
        $(this._elementRef.nativeElement).jPlayer('volume', volumeRatio);
    }

    /**
     * Instructs jPlayer to mute the audio
     *
     * @private
     */
    _mute(): void {
        $(this._elementRef.nativeElement).jPlayer('mute');
    }

    /**
     * Instructs jPlayer to unmute the audio
     *
     * @private
     */
    _unmute(): void {
        $(this._elementRef.nativeElement).jPlayer('unmute');
    }

    /**
     * Returns whether or not this device can safely be assumed to be a mobile device.
     * Factored out into its own function for easier testability.
     *
     * @returns {boolean}
     * @private
     */
    _isAMobileDevice(): boolean {
        const modernizr = this._browser.getModernizr();
        return !!(modernizr && modernizr.touchevents === true &&
            ($.jPlayer.platform.android || $.jPlayer.platform.ipad || $.jPlayer.platform.iphone || $.jPlayer.platform.ipod));
    }

    /**
     * Whether/what jPlayer should try to preload, based on what we know about the client device.
     * Factored out into its own function for easier testability.
     *
     * @returns {string}
     * @private
     */
    _shouldPreload(): string {
        return this._isAMobileDevice() ? 'metadata' : 'auto';
    }

    /**
     * Returns the object we need to initialize jPlayer. Abstracted out into a function for improved testability.
     *
     * @returns {JPlayerInitializationSettings}
     * @private
     */
    _getJplayerInitializationSettings(): Object {
        return {
            ready: () => this._onJplayerReady.next(true),
            supplied: 'm3u8a,m4a,mp3',
            preload: this._shouldPreload(),
            wmode: 'window',
            useStateClassSkin: true,
            autoBlur: false,
            ended: () => this._onAudioComplete.emit(),
            playing: () => this._onAudioPlaying.emit(true),
            pause: () => this._onAudioPlaying.emit(false),
            timeupdate: event => this._onTimeUpdate.emit(event.jPlayer.status.currentTime),
            durationchange: event => this._onAudioDurationChange.emit(event.jPlayer.status.duration),
            error: event => this._logger.error('JPlayerComponent', 'jPlayer Error', event.jPlayer.error.type, event.jPlayer.error.message, event.jPlayer.error.context, event.jPlayer.error.hint),
            stalled: event => this._logger.warn('JPlayerComponent', 'jPlayer audio data is unexpectedly no longer available', event.jPlayer.status),
            setmedia: event => this._onAudioBuffering.emit(true),
            waiting: event => this._onAudioBuffering.emit(true),
            loadeddata: event => this._onAudioLoaded.emit(true),
            canplay: event => this._onAudioLoaded.emit(true),
            canplaythrough: event => this._onAudioLoaded.emit(true),
            suspend: event => this._onAudioLoaded.emit(true),
            abort: event => this._onAudioLoaded.emit(true),
        };
    }

    /**
     * @see http://jplayer.org/latest/developer-guide/#jPlayer-constructor
     * @see http://jplayer.org/latest/developer-guide/#jPlayer-event-type
     *
     * @typedef {Object} JPlayerInitializationSettings
     * @property {function(event: Event): void} [ready] Defines an event handler function that is bound to the $.jPlayer.event.ready event.
     * @property {string} [swfPath="js"] Defines the path of jPlayer's Jplayer.swf file
     * @property {string} [solution="html,flash"] Defines the priority of the html and flash solutions. The default is to use HTML first, with a Flash fallback.
     * @property {string} [supplied="mp3"] Defines the formats supplied to jPlayer. The order defines the priority, with the left most format being highest. Formats to the right are lower priority.
     * @property {{width: string, height: string, cssClass: string}} [size]
     * @property {{width: string, height: string, cssClass: string}} [sizeFull]
     * @property {boolean} [smoothPlayBar=false]
     * @property {boolean} [fullScreen=false]
     * @property {boolean} [fullWindow=false]
     * @property {boolean} [audioFullScreen=false]
     * @property {{restored: boolean, full: boolean, fadeIn: number, fadeOut: number, hold: number}} [autohide]
     * @property {string} [preload="metadata"] Valid values are "none", "metadata" and "auto", which matches the HTML5 draft standard. Use "auto" to preload the file.
     * @property {number} [volume=0.8] Defines the initial volume as a value from 0 to 1.
     * @property {boolean} [muted=false]
     * @property {boolean} [globalVolume=false] Enabling causes the volume option to be shared with other jPlayer instances that have the globalVolume option enabled.
     * @property {boolean} [verticalVolume=false]
     * @property {boolean} [remainingDuration=false]
     * @property {boolean} [toggleDuration=false]
     * @property {boolean} [captureDuration=true]
     * @property {boolean} [autoBlur=true] When true, GUI interations are blur() after executing their action. When false, GUI interations gain focus() instead.
     * @property {number} [playbackRate=1]
     * @property {number} [defaultPlaybackRate=1]
     * @property {number} [minPlaybackRate=0.5]
     * @property {number} [maxPlaybackRate=4]
     * @property {boolean} [verticalPlaybackRate=false]
     * @property {string} [backgroundColor="#000000"]
     * @property {string} [cssSelectorAncestor="#jp_container_1"]
     * @property {Object} [cssSelector]
     * @property {Object} [stateClass]
     * @property {boolean} [useStateClassSkin=false] Controls how jPlayer uses its HTML GUI skin.
     * @property {string} [noConflict="jQuery"] Allows the global variable name of jQuery to be set.
     * @property {string} [wmode="opaque"] Allows the wmode of the Flash fallback to be set. Valid wmode values: window, transparent, opaque, direct, gpu
     * @property {boolean} [loop=false] Sets the initial loop state.
     * @property {boolean} [emulateHtml=false] Enables the HTML bridge.
     * @property {Object} [nativeVideoControls] Defines the user agent blocklist, which contains regular expressions, which cause the native controls to be used if a match is found.
     * @property {Object} [noFullWindow] Defines the user agent blocklist, which contains regular expressions, which cause the full-screen and restore-screen buttons to be hidden if a match is found.
     * @property {Object} [noVolume] Defines the user agent blocklist, which contains regular expressions, which cause the volume controls to be hidden if a match is found.
     * @property {Object} [timeFormat] Defines the display format of the currentTime and duration times.
     * @property {boolean} [keyEnabled=false] Enables the keyboard controls feature for this instance.
     * @property {Object} [keyBindings] Defines the event.which key codes and their actions.
     * @property {string} [idPrefix="jp"] Defines the Id prefix for jPlayer's internally generated HTML code.
     * @property {boolean} [consoleAlerts=true] Enabling forces the alerts generated by jPlayer({errorAlerts}) and jPlayer({warningAlerts}) to be written to the console instead. When the console is not supported and this option is enabled, no alert will occur.
     * @property {boolean} [errorAlerts=false] Enables error reporting through alerts.
     * @property {boolean} [warningAlerts=false] Enables warning reporting through alerts.
     * @property {Object} [eventType] Just like the jPlayer ready event, you can bind a handler to any of the jPlayer Events Types. The events include the HTML5 media events.
     * @property {function(event: Event): void} [setmedia] Occurs when jPlayer has been given the setMedia commands.
     * @property {function(event: Event): void} [flashreset] Occurs when the Flash solution generates another ready event.
     * @property {function(event: Event): void} [resize] Occurs when the screen state changes and when the size/sizeFull options are changed.
     * @property {function(event: Event): void} [repeat] Occurs when the loop state is changed and before the ready event. @see http://jplayer.org/latest/developer-guide/#jPlayer-option-repeat
     * @property {function(event: Event): void} [click] Occurs when the user clicks on the poster or video. NB: The GUI skin can interfere with this.
     * @property {function(event: Event): void} [error] Sent when an error occurs. @see http://jplayer.org/latest/developer-guide/#jPlayer-event-error-codes
     * @property {function(event: Event): void} [warning] @see http://jplayer.org/latest/developer-guide/#jPlayer-event-warning-codes
     * @property {function(event: Event): void} [loadstart] Sent when loading of the media begins. @see https://developer.mozilla.org/en-US/docs/Web/Events/loadstart
     * @property {function(event: Event): void} [progress] Occurs while the media is being downloaded. @see https://developer.mozilla.org/en-US/docs/Web/Events/progress
     * @property {function(event: Event): void} [suspend] Sent when loading of the media is suspended; this may happen either because the download has completed or because it has been paused for any other reason. @see https://developer.mozilla.org/en-US/docs/Web/Events/suspend
     * @property {function(event: Event): void} [abort] Sent when playback is aborted; for example, if the media is playing and is restarted from the beginning, this event is sent.
     * @property {function(event: Event): void} [emptied] The media has become empty; for example, this event is sent if the media has already been loaded (or partially loaded), and the load() method is called to reload it. @see https://developer.mozilla.org/en-US/docs/Web/Events/emptied
     * @property {function(event: Event): void} [stalled] Sent when the user agent is trying to fetch media data, but data is unexpectedly not forthcoming. @see https://developer.mozilla.org/en-US/docs/Web/Events/stalled
     * @property {function(event: Event): void} [play] Occurs when the media is played. @see https://developer.mozilla.org/en-US/docs/Web/Events/play
     * @property {function(event: Event): void} [pause] Occurs when the media is paused. @see https://developer.mozilla.org/en-US/docs/Web/Events/pause
     * @property {function(event: Event): void} [loadedmetadata] The media's metadata has finished loading; all attributes now contain as much useful information as they're going to. @see https://developer.mozilla.org/en-US/docs/Web/Events/loadedmetadata
     * @property {function(event: Event): void} [loadeddata] The first frame of the media has finished loading. @see https://developer.mozilla.org/en-US/docs/Web/Events/loadeddata
     * @property {function(event: Event): void} [waiting] Sent when the requested operation (such as playback) is delayed pending the completion of another operation (such as a seek). @see https://developer.mozilla.org/en-US/docs/Web/Events/waiting
     * @property {function(event: Event): void} [playing] Sent when the media begins to play (either for the first time, after having been paused, or after ending and then restarting). @see https://developer.mozilla.org/en-US/docs/Web/Events/playing
     * @property {function(event: Event): void} [canplay] Sent when enough data is available that the media can be played, at least for a couple of frames.  This corresponds to the HAVE_ENOUGH_DATA readyState. @see https://developer.mozilla.org/en-US/docs/Web/Events/canplay
     * @property {function(event: Event): void} [canplaythrough] Sent when the ready state changes to CAN_PLAY_THROUGH, indicating that the entire media can be played without interruption, assuming the download rate remains at least at the current level. Note: Manually setting the currentTime will eventually fire a canplaythrough event in firefox. Other browsers might not fire this event. @see https://developer.mozilla.org/en-US/docs/Web/Events/canplaythrough
     * @property {function(event: Event): void} [seeking] Sent when a seek operation begins. @see https://developer.mozilla.org/en-US/docs/Web/Events/seeking
     * @property {function(event: Event): void} [seeked] Sent when a seek operation completes. @see https://developer.mozilla.org/en-US/docs/Web/Events/seeked
     * @property {function(event: Event): void} [timeupdate] Occurs when the currentTime is changed. (~250ms between events during playback.) @see https://developer.mozilla.org/en-US/docs/Web/Events/timeupdate
     * @property {function(event: Event): void} [ended] Occurs when the media ends.
     * @property {function(event: Event): void} [ratechange] Sent when the playback speed changes. @see https://developer.mozilla.org/en-US/docs/Web/Events/ratechange
     * @property {function(event: Event): void} [durationchange] The metadata has loaded or changed, indicating a change in duration of the media.  This is sent, for example, when the media has loaded enough that the duration is known. @see https://developer.mozilla.org/en-US/docs/Web/Events/durationchange
     * @property {function(event: Event): void} [volumechange] Sent when the audio volume changes (both when the volume is set and when the muted attribute is changed). @see https://developer.mozilla.org/en-US/docs/Web/Events/volumechange
     */
}
export default JPlayerComponent;
