import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { Story } from '../../../util/recommendation-parser';

@Component({
    selector: 'station-card',
    template: `
    <div class="grid__column grid__column--vertically-centered">
        <header class="card__header center">
            <img *ngIf="story.primaryImage" [src]="story.primaryImage.href" [width]="story.primaryImage.width"
                 [height]="story.primaryImage.height" [attr.data-rel]="story.primaryImage.rel"
                 [alt]="story.title + ' logo'" />
            <h1 *ngIf="!story.primaryImage">{{story.title}}</h1>
        </header>
        <p class="card__meta" data-hidden="true">
            <span *ngIf="story.provider">{{story.provider}}</span>
        </p>
    </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * This component is essentially just a holding class for the template that should only be applied if the current
 * recommendation is a station card (stationId).
 */
export class StationCardComponent {
    /** @type {Story|null} */
    @Input() story: Story;
}
export default StationCardComponent;
