/**
 * @typedef {Object} LogLevels
 * @property {number} DEBUG
 * @property {number} INFO
 * @property {number} WARN
 * @property {number} ERROR
 * @property {number} OFF
 */
/**
 * @type {LogLevels}
 */
export enum LogLevels {
    DEBUG = 1,
    INFO = 2,
    WARN = 4,
    ERROR = 8,
    OFF = 99,
}
export default LogLevels;
