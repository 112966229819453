import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { CampaignService } from '../../../analytics/campaign.service';
import { BrowserService } from '../../../core/browser.service';
import { Story } from '../../../util/recommendation-parser';

@Component({
    selector: 'upsell',
    template: `
    <div class="grid__column grid__column--flex grid__column--vertically-centered center">
        <aside class="grid__row grid__row--no-grow grid__row--flex grid__row--centered card__image" role="complementary">
            <figure *ngFor="let image of story.images" class="card__image__wrapper" data-form-factor="phone">
                <img role="link" tabindex="0" class="image--clickable"
                     alt="Playing next: that thing you didn't know you'd love. Download the app now."
                     [src]="image.href" [width]="image.width" [height]="image.height" [attr.data-rel]="image['rel']"
                     (click)="executeCallToAction($event)" />
            </figure>
            <figure class="card__image__wrapper" data-form-factor="desktop">
                <img role="link" tabindex="0" class="image--clickable"
                     alt="NPR One: Hand-picked stories based on what you like. Download the app now."
                     [src]="upsellImageSrc" width="5333" height="3000" data-rel="wide"
                     (click)="executeCallToAction($event)" />
            </figure>
        </aside>
    </div>
    <div class="grid__column grid__column--flex grid__column--fixed-width grid__column--vertically-centered grid__column--with-gutters center upsell-buttons">
        <header class="grid__row grid__row--fixed-height card__header" data-hidden="true">
            <h3 class="title card__title">{{story.title}}</h3>
        </header>
        <div class="grid__row grid__row--flex grid__row--centered upsell-buttons__store upsell-buttons__store--apple">
            <a rel="external noopener" tabindex="0" target="_blank" [href]="iOSUrl"
               (click)="openStore($event, 'iOS')">
                <i class="upsell-buttons__store__logo" role="img" aria-label="Available on the App Store"></i>
            </a>
        </div>
        <div class="grid__row grid__row--flex grid__row--centered upsell-buttons__store upsell-buttons__store--android">
            <a rel="external noopener" tabindex="0" target="_blank" [href]="googleUrl"
               (click)="openStore($event, 'Google')">
                <i class="upsell-buttons__store__logo" role="img" aria-label="Get it on Google Play"></i>
            </a>
        </div>
        <div class="grid__row grid__row--flex grid__row--centered upsell-buttons__store upsell-buttons__store--windows">
            <a rel="external noopener" tabindex="0" target="_blank" [href]="windowsUrl"
               (click)="openStore($event, 'Microsoft')">
                <i class="upsell-buttons__store__logo" role="img" aria-label="Get it from Microsoft"></i>
            </a>
        </div>
        <div class="grid__row grid__row--no-grow grid__row--flex grid__row--centered upsell-buttons__launch-icon">
            <img [src]="launchIconSrc" width="100" height="100" alt="NPR One Launch Icon" />
        </div>
    </div>
    `,
    styleUrls: ['./upsell.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * This component is essentially just a holding class for the template that should only be applied if the current
 * recommendation is a feature card.
 *
 * @implements {OnInit}
 */
export class UpsellComponent implements OnInit {
    /** @type {Story} */
    @Input() story: Story;
    /** @type {EventEmitter} */
    @Output('onClickThrough') _onClickThrough: EventEmitter<void> = new EventEmitter();

    public upsellImageSrc: string;
    public launchIconSrc: string;
    public iOSUrl: string;
    public googleUrl: string;
    public windowsUrl: string;

    constructor(private _analytics: AnalyticsService, private _campaign: CampaignService, private _browser: BrowserService) {
        /** @private */
        this.upsellImageSrc = `${environment.cdnImagePath.replace(/\/$/, '')}/img/upsell/upsell-ph1-85.jpg`;
        /** @private */
        this.launchIconSrc = `${environment.cdnImagePath.replace(/\/$/, '')}/img/logos/launch-icon.png`;

        /** @type {string} */
        this.iOSUrl = this._campaign.appendQueryStringToUrl('https://itunes.apple.com/us/app/npr-news/id874498884');
        /** @type {string} */
        this.googleUrl = this._campaign.appendQueryStringToUrl('https://play.google.com/store/apps/details?id=org.npr.one&hl=en');
        /** @type {string} */
        this.windowsUrl = this._campaign.appendQueryStringToUrl('https://www.windowsphone.com/en-us/store/app/npr-one/3d60b46e-0632-47f4-b454-a17dabca4d00');
    }

    /**
     * The actions that should be taken when a component has been initialized.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnInit-interface.html
     */
    ngOnInit() {
        this._analytics.sendEvent('audio user interactions', 'app upsell impression');
    }

    /**
     * The event listener that gets called when the user clicks on a call-to-action
     *
     * @param {Event} event
     */
    executeCallToAction(event: Event): void {
        event.preventDefault();

        this._onClickThrough.emit();

        this.story.callsToAction.forEach((action) => {
            switch (action['content-type']) {
                case 'text/html':
                    const href = this._campaign.appendQueryStringToUrl(action.href);
                    const newWindow: Window = this._browser.getWindow().open(href, '_blank');
                    newWindow.opener = null;
                    break;
                default:
                    break;
            }
        });

        this._analytics.sendEvent('settings', 'upsell card click');
    }

    /**
     * The event listener that gets called when the user clicks on a specific store link (desktop only).
     *
     * @param {Event} event
     * @param {?string} storeType
     */
    openStore(event: Event, storeType?: string): void {
        event.preventDefault();
        this._onClickThrough.emit();

        let href;
        const target: Element = <Element> event.target;
        if (target instanceof HTMLAnchorElement) {
            href = target.href;
        } else if (target.parentElement instanceof HTMLAnchorElement) {
            href = target.parentElement.href;
        }
        if (href) {
            this._browser.getWindow().open(href, '_blank');
        }

        if (storeType) {
            this._analytics.sendEvent('settings', 'upsell card click', storeType);
        } else {
            this._analytics.sendEvent('settings', 'upsell card click');
        }
    }
}
export default UpsellComponent;
