/**
 * @typedef {object} FeatureCardTypes
 * @property {string} ANY
 * @property {string} EXTLINK
 * @property {string} PROMO
 * @property {string} INFO
 */
export enum FeatureCardTypes {
    EXTLINK = 'featureCardExternalLink',
    PROMO = 'featureCardPromotion',
    INFO = 'featureCardInformational',
}

/**
 * @typedef {Object} CardTypes
 * @property {string} DEFAULT
 * @property {string} STATION
 * @property {string} SPONSORSHIP
 * @property {FeatureCardTypes} FEATURE
 * @property {string} UPSELL
 */
/**
 * @type {CardTypes}
 */
export enum CardTypes {
    DEFAULT = 'audio',
    STATION = 'stationId',
    SPONSORSHIP = 'sponsorship',
    FEATURE = 'featureCard',
    UPSELL = 'upsell',
}
export default CardTypes;
