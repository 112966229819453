import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { AnalyticsService } from '../../../analytics/analytics.service';

@Component({
    selector: 'interesting-button',
    template: `
    <button type="button" [class.card__button--active]="hasMarkedInteresting"
            (click)="markInteresting($event)">
        <div class="grid__row grid__row--flex grid__row--centered card__button__contents">
            <i class="grid__column grid__column--fixed-width" role="img" aria-label="Mark interesting icon"></i>
            <b class="grid__column grid__column--no-grow">Interesting</b>
        </div>
    </button>
    `,
    styleUrls: ['./interesting.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * A very thin component that represents the "Mark as Interesting" button
 */
export class MarkInterestingButtonComponent {
    /** @type {boolean} */
    @Input() hasMarkedInteresting: boolean;
    /** @private */
    @Output('onMarkInteresting') _onMarkInteresting: EventEmitter<void> = new EventEmitter();

    constructor(private _analytics: AnalyticsService) {}

    /**
     * The callback that gets triggered when the user has decided to mark the story as interesting
     *
     * @listens {click}
     * @param {Event} event
     */
    markInteresting(event: Event): void {
        event.preventDefault();

        this._onMarkInteresting.emit();

        this._analytics.sendEvent('audio user interactions', 'mark interesting');
    }
}
export default MarkInterestingButtonComponent;
