import AllActions from '../all.actions';
import ActionTypes from '../constants/action-types.enum';

import initialState, { NavState } from './nav.state';


type Action = AllActions;

/**
 * The primary reducer for the navigation.
 *
 * @param {NavState} state
 * @param {Action} action
 * @return {NavState}
 */
const navReducer = (state = initialState, action: Action): NavState => {
    switch (action.type) {
        case ActionTypes.TOGGLE_JUNK_DRAWER:
            const isJunkDrawerExpanded = !state.isJunkDrawerExpanded;
            return {
                ...state,
                isJunkDrawerExpanded,
                isUserMenuExpanded: isJunkDrawerExpanded ? false : state.isUserMenuExpanded,
                isOrganizationMenuExpanded: isJunkDrawerExpanded ? false : state.isOrganizationMenuExpanded,
            };

        case ActionTypes.TOGGLE_USER_MENU:
            const isUserMenuExpanded = !state.isUserMenuExpanded;
            return {
                ...state,
                isUserMenuExpanded,
                isJunkDrawerExpanded: isUserMenuExpanded ? false : state.isJunkDrawerExpanded,
                isOrganizationMenuExpanded: isUserMenuExpanded ? false : state.isOrganizationMenuExpanded,
            };

        case ActionTypes.TOGGLE_ORGANIZATION_MENU:
            const isOrganizationMenuExpanded = !state.isOrganizationMenuExpanded;
            return {
                ...state,
                isOrganizationMenuExpanded,
                isJunkDrawerExpanded: isOrganizationMenuExpanded ? false : state.isJunkDrawerExpanded,
                isUserMenuExpanded: isOrganizationMenuExpanded ? false : state.isUserMenuExpanded,
            };

        case ActionTypes.STATION_DETAILS_UPDATED:
            return {
                ...state,
                stationDetails: action.payload,
            };

        case ActionTypes.SIGN_OUT:
            return {
                ...state,
                isSignedIn: false,
                isUserMenuExpanded: false,
            };

        case ActionTypes.USER_LOADED:
            /** @type {User} */
            const user = action.payload;
            return {
                ...state,
                isSignedIn: !user.isTemporary(),
            };

        case ActionTypes.ROUTE_CHANGED:
            return {
                ...initialState,
                isSignedIn: state.isSignedIn,
                stationDetails: state.stationDetails,
            };

        default:
            return state;
    }
};
export default navReducer;
