import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { Story } from '../../../util/recommendation-parser';

@Component({
    selector: 'story-card',
    template: `
    <aside *ngIf="story.primaryImage" class="grid__column grid__column--fixed-width card__image" role="complementary">
        <figure class="card__image__wrapper" id="primaryImage">
            <img [src]="story.primaryImage.href" [width]="story.primaryImage.width" [height]="story.primaryImage.height"
                 [attr.data-rel]="story.primaryImage.rel" [alt]="story.primaryImage.caption" />
            <figcaption class="card__image__caption">
                <span *ngIf="story.primaryImage.producer">{{story.primaryImage.producer}}</span><span *ngIf="story.primaryImage.producer && story.primaryImage.provider" role="separator">&nbsp;/&nbsp;</span><span *ngIf="story.primaryImage.provider">{{story.primaryImage.provider}}</span>
            </figcaption>
        </figure>
    </aside>
    <div class="grid__column grid__column--flex grid__column--fixed-width card__content">
        <div class="grid__row grid__row--no-grow">
            <header class="card__header">
                <div *ngIf="story.logo && story.slug" class="grid__row grid__row--flex grid__row--fixed-height grid__row--with-gutters card__slug__wrapper">
                    <div class="grid__column grid__column--fixed-width card__slug__logo">
                        <img id="slugLogo"
                             [src]="story.logo.href" [width]="story.logo.width" [height]="story.logo.height"
                             [attr.data-rel]="story.logo.rel" [alt]="story.slug + ' logo'" />
                    </div>
                    <h2 class="grid__column grid__column--flex grid__column--vertically-centered card__slug">{{story.slug}}</h2>
                </div>
                <h2 *ngIf="story.slug" [attr.data-hidden]="!!story.logo" class="card__slug" id="slug">{{story.slug}}</h2>
                <h1 class="card__title">{{story.title}}</h1>
            </header>
            <p class="card__meta">
                <span *ngIf="story.provider">{{story.provider}}</span><span *ngIf="story.provider && story.date" role="separator">&nbsp;&nbsp;•&nbsp;&nbsp;</span><time *ngIf="story.date" [attr.datetime]="story.date.toISOString()">{{story.date | date:'mediumDate'}}</time>
            </p>
            <p class="card__meta card__meta--light" id="rationale">{{story.rationale}}</p>
            <p data-hidden="true" class="card__teaser">{{story.description}}</p>
        </div>
        <div class="grid__row grid__row--flex grid__row--no-grow card__buttons">
            <interesting-button *ngIf="story.isShareable" class="grid__column grid__column--flex grid__column--no-grow grid__column--centered"
                                [hasMarkedInteresting]="hasMarkedInteresting"
                                (onMarkInteresting)="onMarkInteresting()">
            </interesting-button>
            <share-button *ngIf="story.isShareable" class="grid__column grid__column--flex grid__column--no-grow grid__column--centered"
                          [isShareMenuExpanded]="isShareMenuExpanded"
                          [storyTitle]="story.title"
                          [shareableLink]="story.shareableLink"
                          (onShare)="onShare()"
                          (onCloseShareMenu)="onCloseShareMenu()">
            </share-button>
            <read-button *ngIf="story.webLink" class="grid__column grid__column--flex grid__column--no-grow grid__column--centered"
                         [link]="story.webLink">
            </read-button>
        </div>
    </div>
    `,
    styleUrls: ['./story.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * This component represents the default markup to be applied for any recommendation which is not a special case
 * such as sponsorship and feature cards.
 */
export class StoryCardComponent {
    /** @type {Story} */
    @Input() story: Story;
    /** @type {boolean} */
    @Input() hasMarkedInteresting: boolean;
    /** @type {boolean} */
    @Input() isShareMenuExpanded: boolean;
    /** @type {EventEmitter} */
    @Output('onMarkInteresting') _onMarkInteresting: EventEmitter<void> = new EventEmitter();
    /** @type {EventEmitter} */
    @Output('onShare') _onShare: EventEmitter<void> = new EventEmitter();
    /** @type {EventEmitter} */
    @Output('onCloseShareMenu') _onCloseShareMenu: EventEmitter<void> = new EventEmitter();

    /**
     * The event listener that gets called when the user has marked the story as interesting
     */
    onMarkInteresting(): void {
        this._onMarkInteresting.emit();
    }

    /**
     * The event listener that gets called when the user has shared the story
     */
    onShare(): void {
        this._onShare.emit();
    }

    /**
     * The event listener that gets called when a user has clicked the 'X' inside the share sub-menu
     */
    onCloseShareMenu(): void {
        this._onCloseShareMenu.emit();
    }
}
export default StoryCardComponent;
