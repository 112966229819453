import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AudioLink } from '@npr/npr-one-sdk';
import { combineLatest, ConnectableObservable, interval, Observable, SubscriptionLike } from 'rxjs';
import { distinctUntilChanged, filter, map, publish, share, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';

import FeatureStores from '../../constants/feature-stores.enum';
import { LoggerService } from '../../core/logger.service';
import PlayerActions from '../player.actions';
import initialState, { PlayerPlusRootState, PlayerState } from '../player.state';

export interface JPlayerAudioSources {
    mp3?: string;
    m4a?: string;
    webma?: string;
    oga?: string;
    fla?: string;
    wav?: string;
    m3u8a?: string;
}

export interface JPlayerInstructions {
    play: EventEmitter<number>;
    pause: EventEmitter<void>;
    load: EventEmitter<JPlayerAudioSources>;
    seek: EventEmitter<number>;
    setVolume: EventEmitter<number>;
    mute: EventEmitter<void>;
    unmute: EventEmitter<void>;
}

@Injectable()
/**
 * The service class to be paired with the JPlayerComponent. We're allowing it to interact with the {@link Store}
 * because it needs to perform business logic based on the player state, but we are not allowing it to dispatch
 * actions the way {@link PlayerService} is allowed to.
 *
 * To elaborate on this a bit further: JPlayerComponent is a bit of a special case because it's a component that
 * doesn't really render anything into the UI based on state the way components usually do. However, depending on
 * what happens to the player state, we do need to trigger certain actions on our jPlayer instance. All of the below
 * could technically be done inside of JPlayerComponent (as long as it had some way of accessing the raw, un-async'ed
 * state) and that is, in fact, what it used to do; however, as a result it grew into a bit of a monster class (its
 * Jasmine spec was about 1000 lines long) which suggested that it was doing just a bit too much for an ordinary
 * component class. Thus, JPlayerService was born as a way of handling the true business logic of this component
 * (e.g. figuring out when the 'play' event should *actually* be triggered on jPlayer based on the state), while the
 * actual direct interactions with jPlayer are kept inside of JPlayerComponent. The chosen method of communication
 * between the two is a set of {@link EventEmitter}s; we could have used {@link Observable}s too, but they make a bit
 * less sense than events in this case, and they overall have a higher degree of complexity than we need here.
 */
export class JPlayerService {
    public state$: Observable<PlayerState>;
    public instructions: JPlayerInstructions;

    private _isBrowserTabActiveOnMobileDevice: boolean;
    private _shouldPlay: boolean;
    private _audioPosition: number;
    private _audioCheckInterval: number;
    private _isPlaying$: Observable<boolean>;
    private _isAudioPlaying$: Observable<boolean>;
    private _audioUrls$: Observable<Array<AudioLink>>;
    private _position$: Observable<number>;
    private _subscription: SubscriptionLike;


    constructor(private _store: Store<PlayerPlusRootState>, private _logger: LoggerService) {
        /** @private */
        this._isBrowserTabActiveOnMobileDevice = true;
        /** @private */
        this._shouldPlay = false;
        /** @private */
        this._audioPosition = 0;

        const state$ = this._store.select(FeatureStores.PLAYER);

        /**
         * The time between checks to see if audio has started playing yet. Saved as a variable so we can override it in our unit
         * tests and not have to wait 2.5 seconds to be able to test `_waitToSeeIfAudioStartsPlayingAndSendATimeoutIfNot()`.
         *
         * @returns {number}
         * @private
         */
        this._audioCheckInterval = 2500; // 2.5 seconds in milliseconds

        /** @type {JPlayerInstructions} */
        this.instructions = {
            play: new EventEmitter(),
            pause: new EventEmitter(),
            load: new EventEmitter(),
            seek: new EventEmitter(),
            setVolume: new EventEmitter(),
            mute: new EventEmitter(),
            unmute: new EventEmitter(),
        };

        const isPlaying$ = state$.pipe(map(s => s.isPlaying), startWith(initialState.isPlaying), distinctUntilChanged());
        const isAudioPlaying$ = state$.pipe(map(s => s.isAudioPlaying), startWith(initialState.isAudioPlaying), distinctUntilChanged());
        const isAudioComplete$ = state$.pipe(map(s => s.isAudioComplete), startWith(initialState.isAudioComplete), distinctUntilChanged());
        const duration$ = state$.pipe(map(s => s.duration), startWith(initialState.duration), distinctUntilChanged());
        const position$ = state$.pipe(map(s => s.position), startWith(initialState.position), distinctUntilChanged());
        const seekPosition$ = state$.pipe(map(s => s.seekPosition), startWith(initialState.seekPosition), distinctUntilChanged());
        const volume$ = state$.pipe(map(s => s.volume), startWith(initialState.volume), distinctUntilChanged());
        const isMuted$ = state$.pipe(map(s => s.isMuted), startWith(initialState.isMuted), distinctUntilChanged());
        const audioUrls$ = state$.pipe(map(s => s.audioUrls), startWith(initialState.audioUrls), distinctUntilChanged());

        position$.subscribe((position) => {
            this._audioPosition = position;
        });

        isPlaying$
            .pipe(withLatestFrom(position$), withLatestFrom(isAudioPlaying$), withLatestFrom(isAudioComplete$))
            .subscribe(([[[shouldPlay, position], isAudioPlaying], isAudioComplete]) => {
                this._shouldPlay = shouldPlay;
                if (shouldPlay) {
                    if (!isAudioPlaying) {
                        this.instructions.play.emit(position);
                    }
                } else if (!isAudioComplete) {
                    this.instructions.pause.emit();
                }
            });

        audioUrls$
            .pipe(withLatestFrom(isPlaying$))
            .subscribe(([audioList, shouldPlay]) => {
                if (audioList && audioList.length > 0) {
                    const src = JPlayerService._parseAudioList(audioList);
                    if (Object.keys(src).length === 0) {
                        this.onTimeout();
                    } else {
                        this.instructions.load.emit(src);
                        if (shouldPlay) {
                            this.instructions.play.emit();
                        }
                    }
                }
            });

        volume$.subscribe((volume) => {
            this.instructions.setVolume.emit(volume);
        });

        isMuted$.subscribe((shouldMute) => {
            if (shouldMute) {
                this.instructions.mute.emit();
            } else {
                this.instructions.unmute.emit();
            }
        });

        seekPosition$
            .pipe(withLatestFrom(position$), withLatestFrom(duration$))
            .subscribe(([[seekPosition, position], duration]) => {
                if (seekPosition !== position) {
                    this.instructions.seek.emit((seekPosition / duration) * 100);
                }
            });

        /** @private */
        this._isPlaying$ = isPlaying$;
        /** @private */
        this._isAudioPlaying$ = isAudioPlaying$;
        /** @private */
        this._audioUrls$ = audioUrls$;
        /** @private */
        this._position$ = position$;
        /** @private */
        this._subscription = combineLatest([
                isPlaying$,
                isAudioPlaying$,
                audioUrls$.pipe(filter(audioUrls => !!audioUrls && audioUrls.length > 0)),
            ])
            .subscribe({ next: ([isPlaying, isAudioPlaying, audioUrls]) => {
                if (isPlaying && !isAudioPlaying) {
                    this._waitToSeeIfAudioStartsPlayingAndSendATimeoutIfNot();
                }
            }});
    }

    /**
     * Maps a list of one or more audio sources from the API to the format desired by jPlayer.
     * Note that yes, this method could be static, but then it wouldn't be private anymore.
     * In this case, desire for privacy trumps use of the static keyword.
     *
     * @param {Array<AudioLink>} audioList
     * @returns {JPlayerAudioSources}
     * @private
     */
    public static _parseAudioList(audioList: Array<AudioLink>): JPlayerAudioSources {
        const src: JPlayerAudioSources = {};
        audioList.forEach((audio) => {
            if (audio.rel !== 'download') {
                switch (audio['content-type']) {
                    case 'audio/aac':
                    case 'audio/mp4':
                    case 'audio/m4a':
                        src.m4a = audio.href;
                        break;
                    case 'audio/mp3':
                        src.mp3 = audio.href;
                        break;
                    case 'audio/ogg':
                    case 'audio/oga':
                        src.oga = audio.href;
                        break;
                    case 'audio/webm':
                        src.webma = audio.href;
                        break;
                    case 'audio/wav':
                    case 'audio/wave':
                    case 'audio/x-wave':
                    case 'audio/vnd.wave':
                        src.wav = audio.href;
                        break;
                    case 'application/vnd.apple.mpegurl':
                        src.m3u8a = audio.href;
                        break;
                    default:
                        break;
                }
            }
        });
        return src;
    }

    /**
     * Used to let us know if the player (and the screen in general) is no longer visible so that we do not send
     * a TIMEOUT. Used only for mobile browsers to avoid weird behavior when the screen is locked or the browser
     * is backgrounded.
     *
     * @type {boolean} isBrowserTabActiveOnMobileDevice
     */
    set isBrowserTabActiveOnMobileDevice(isBrowserTabActiveOnMobileDevice) {
        this._isBrowserTabActiveOnMobileDevice = isBrowserTabActiveOnMobileDevice;

        if (!isBrowserTabActiveOnMobileDevice && this._shouldPlay) {
            this.instructions.play.emit(this._audioPosition);
        }
    }

    // @TODO change tests so these setters aren't needed.
    /**
     * Set audio check interval - used for tests
     */
    set audioCheckInterval(checkInterval: number) {
        this._audioCheckInterval = checkInterval;
    }

     /**
     * Set shouldPlay flag - used for tests
     */
    set shouldPlay(shouldPlay: boolean) {
        this._shouldPlay = shouldPlay;
    }

    /**
     * The callback to trigger when the player has failed to play a piece of audio in a reasonable amount of time
     * or is unable to play a piece of audio because there are no formats supported by jPlayer
     */
    onTimeout(): void {
        this._store.dispatch(PlayerActions.timeout());
    }

    /**
     * The callback to trigger when the user has pushed the play or pause buttons. In our case, we're only using
     * this to pause the playback because we haven't been able to start the audio in a reasonable amount of time.
     */
    onTogglePlay(): void {
        this._store.dispatch(PlayerActions.togglePlay());
    }

    /**
     * If the player has been told to play (i.e. because the user pressed the 'Play' button), this checks to
     * make sure that audio actually starts playing in a reasonable amount of time. If a given amount of time
     * passes without audio starting to play, this will result in a TIMEOUT being sent to the SDK.
     *
     * @private
     */
    _waitToSeeIfAudioStartsPlayingAndSendATimeoutIfNot(): void {
        const playerIsPaused$ = this._isPlaying$.pipe(
            filter(isPlaying => isPlaying === false),
            publish(),
        ) as ConnectableObservable<boolean>;
        const audioIsPlaying$ = this._isAudioPlaying$.pipe(
            filter(isAudioPlaying => isAudioPlaying === true),
            publish(),
        ) as ConnectableObservable<boolean>;
        const audioUrlsHaveChanged$ = this._audioUrls$.pipe(
            filter(audioUrls => !!audioUrls && audioUrls.length > 0),
            publish(),
        ) as ConnectableObservable<AudioLink>;
        const timer$ = interval(this._audioCheckInterval).pipe(
            map(i => (i + 1) * 2.5), // calculate seconds elapsed
            withLatestFrom(this._position$),
            share(),
            takeUntil(audioIsPlaying$),
            takeUntil(playerIsPaused$),
            takeUntil(audioUrlsHaveChanged$),
        );
        const audioIsPlayingConnection = audioIsPlaying$.connect();
        const playerIsPausedConnection = playerIsPaused$.connect();
        const audioUrlsHaveChangedConnection = audioUrlsHaveChanged$.connect();

        timer$.subscribe({
            next: ([secondsElapsed, position]) => {
                if (secondsElapsed <= 15) {
                    if (secondsElapsed % 5 === 0) {
                        this._logger.warn('JPlayerService', 'Player has been told to play but no audio has been playing for',
                            secondsElapsed, 'seconds');
                    }
                    if (!this._isBrowserTabActiveOnMobileDevice) {
                        this.instructions.play.emit(position);
                    } else if (secondsElapsed === 10) {
                        this.onTimeout();
                    }
                } else {
                    if (secondsElapsed % 5 === 0) {
                        this._logger.error('JPlayerService', 'Player has been told to play but no audio has been playing for',
                            secondsElapsed, 'seconds');
                    }
                    if (!this._isBrowserTabActiveOnMobileDevice) {
                        this.instructions.play.emit(position);
                    } else if (secondsElapsed >= 30) {
                        this.onTogglePlay(); // pause the player
                    }
                }
            },
            error: () => {
                this._logger.error.bind(this, 'JPlayerService');
            },
            complete: () => {
                // @TODO Figure out if the below calls are necessary (I'm not sure)
                audioIsPlayingConnection.unsubscribe();
                playerIsPausedConnection.unsubscribe();
                audioUrlsHaveChangedConnection.unsubscribe();
            },
        });
    }
}
export default JPlayerService;
