import { Action } from '@ngrx/store';

import ActionTypes from '../constants/action-types.enum';
import { RecordRouteChangeAction } from '../util/shared.actions';


export class LoadStoryAction implements Action {
    readonly type = ActionTypes.METADATA_LOADING;

    constructor(public payload: { sharedMediaId: string, channel: string }) {}
}

export class MarkInterestingAction implements Action {
    readonly type = ActionTypes.MARK_INTERESTING;
}

export class ShareAction implements Action {
    readonly type = ActionTypes.SHARE;
}

export class CloseShareMenuAction implements Action {
    readonly type = ActionTypes.CLOSE_SHARE;
}

export class ClickThroughAction implements Action {
    readonly type = ActionTypes.CLICK_THROUGH;

    constructor(public payload: boolean) {}
}

export type AllMetadataActions
    = LoadStoryAction
    | MarkInterestingAction
    | ShareAction
    | CloseShareMenuAction
    | ClickThroughAction;

/**
 * @typedef {Object} MetadataActions
 * @property {function(): Action} recordRouteChange
 * @property {function(sharedMediaId: string, channel: string): Action} loadStory
 * @property {function(): Action} markInteresting
 * @property {function(): Action} share
 * @property {function(): Action} closeShareMenu
 * @property {function(isInternal: boolean): Action} clickThrough
 */
/**
 * @type {MetadataActions}
 */
const MetadataActions = {
    recordRouteChange(): RecordRouteChangeAction {
        return new RecordRouteChangeAction();
    },

    loadStory(sharedMediaId?, channel?): LoadStoryAction {
        return new LoadStoryAction({ sharedMediaId, channel });
    },

    markInteresting(): MarkInterestingAction {
        return new MarkInterestingAction();
    },

    share(): ShareAction {
        return new ShareAction();
    },

    closeShareMenu(): CloseShareMenuAction {
        return new CloseShareMenuAction();
    },

    clickThrough(isInternal?): ClickThroughAction {
        return new ClickThroughAction(isInternal);
    },
};
export default MetadataActions;
