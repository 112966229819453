import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { NprOneSDK } from '@npr/npr-one-sdk';

import FeatureStores from '../constants/feature-stores.enum';

import { ApiService } from './api.service';
import playlistReducer from './playlist/playlist.reducer';
import { PlaylistService } from './playlist/playlist.service';
import { getInitialPlaylistState } from './playlist/playlist.state';
import stationFinderReducer from './station/station-finder.reducer';
import { StationFinderService } from './station/station-finder.service';
import { getInitialStationFinderState } from './station/station-finder.state';
import userReducer from './user/user.reducer';
import { UserService } from './user/user.service';
import { getInitialUserState } from './user/user.state';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(FeatureStores.PLAYLIST, playlistReducer, { initialState: getInitialPlaylistState }),
        StoreModule.forFeature(FeatureStores.STATION_FINDER, stationFinderReducer, { initialState: getInitialStationFinderState }),
        StoreModule.forFeature(FeatureStores.USER, userReducer, { initialState: getInitialUserState }),
    ],
    declarations: [],
    providers: [
        ApiService,
        UserService,
        PlaylistService,
        StationFinderService,
        { provide: NprOneSDK, useClass: NprOneSDK },
    ],
})
export class ApiModule {
    constructor(@Optional() @SkipSelf() parentModule: ApiModule) {
        if (parentModule) {
            throw new Error(
                'ApiModule is already loaded. Import it in the AppModule only');
        }
    }
}
