import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { AnalyticsService } from '../../analytics/analytics.service';
import { BrowserService } from '../../core/browser.service';
import { MobilePlayService } from '../mobile-play.service';

@Component({
    selector: 'toggle-play',
    template: `
    <button type="button" class="player__play-control" [attr.aria-kbdshortcuts]="keyboardShortcuts"
            [disabled]="!hasAudio" [attr.aria-disabled]="!hasAudio" [attr.aria-busy]="isLoading"
            [class.player__play-control--inactive]="!hasAudio"
            (click)="togglePlay($event)" (touchend)="togglePlay($event)" tabindex="0">
        <i class="player__play-control__icon" role="img" aria-label="Play/Pause icon"
           [class.player__play-control__icon--play]="!isLoading && !isPlaying"
           [class.player__play-control__icon--pause]="!isLoading && isPlaying && isAudioPlaying"
           [class.player__play-control__icon--loading]="isLoading || (isPlaying && !isAudioPlaying)"></i>
    </button>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * A sub-component of the player representing the play/pause buttons
 *
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {OnChanges}
 */
export class TogglePlayComponent implements OnInit, OnDestroy, OnChanges {
    /** @type {boolean} */
    @Input() hasAudio: boolean;
    /** @type {boolean} */
    @Input() isLoading: boolean;
    /** @type {boolean} */
    @Input() isPlaying: boolean;
    /** @type {boolean} */
    @Input() isAudioPlaying: boolean;
    /** @type {boolean} */
    @Input() isPlayerHidden: boolean;
    /** @private */
    @Output('onTogglePlay') _onTogglePlay: EventEmitter<void> = new EventEmitter();

    public keyboardShortcuts: string;

    constructor(private _mobilePlayService: MobilePlayService, private _analytics: AnalyticsService, private _browser: BrowserService) {
        /** @type {string} */
        this.keyboardShortcuts = 'Space MediaPlayPause';
    }

    /**
     * The actions that should be taken when a component has been initialized.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnInit-interface.html
     */
    ngOnInit() {
        this._browser.getWindow().document.addEventListener('keyup', this._processKeyUp.bind(this), false);
        this.setActionHandlers();
    }

    /**
     * The actions that should be taken right before a component is destroyed.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnDestroy-interface.html
     */
    ngOnDestroy() {
        this._browser.getWindow().document.removeEventListener('keyup', this._processKeyUp.bind(this), false);
    }

    /**
     * The actions that should be taken right after the data-bound properties have been checked and before view and
     * content children are checked if at least one of them has changed.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnChanges-interface.html
     */
    ngOnChanges() {
        if (this.isPlaying === true) {
            this.keyboardShortcuts = 'Space MediaPlayPause MediaPause MediaStop Pause';
        } else {
            this.keyboardShortcuts = 'Space MediaPlayPause MediaPlay Play';
        }
    }

    /**
     * The callback that gets triggered when a user presses play/pause using media keys. Only used for Chrome.
     */
    playActionHandler() {
        if (!this.isPlaying && this.hasAudio) {
            this._onTogglePlay.emit();
            this._analytics.sendEvent('audio user interactions', 'play audio');
        }
    }

    /**
     * The callback that gets triggered when a user presses pause/play using media keys. Only used for Chrome.
     */
    pauseActionHandler() {
        if (this.isPlaying && this.hasAudio) {
            this._onTogglePlay.emit();
            this._analytics.sendEvent('audio user interactions', 'pause audio');
        }
    }

    setActionHandlers() {
        if ((navigator as any).mediaSession) {
            (navigator as any).mediaSession.setActionHandler('play', this.playActionHandler.bind(this));
            (navigator as any).mediaSession.setActionHandler('pause', this.pauseActionHandler.bind(this));
        }
    }

    /**
     * The callback that gets triggered when the user clicks on either the play or pause buttons.
     *
     * @listens {click}
     * @listens {touchend}
     * @param {Event} event
     */
    togglePlay(event: Event): void {
        event.preventDefault();

        if (!this.hasAudio) {
            return;
        }

        const isPlaying = this.isPlaying;

        /*
         * Mobile Safari wants the audio player play event triggered very soon after the
         * user makes a touch gesture - the _onTogglePlay EventEmitter seems to be too late.
         */
        if (event.type === 'touchend' && !isPlaying) {
            this._mobilePlayService.play();
        }

        this._onTogglePlay.emit();

        if (isPlaying === true) {
            this._analytics.sendEvent('audio user interactions', 'pause audio');
        } else {
            this._analytics.sendEvent('audio user interactions', 'play audio');
        }
    }

    /**
     * Processes keyboard/remote controller input.
     *
     * @see https://developer.amazon.com/public/solutions/platforms/webapps/docs/supporting-controllers-in-web-apps
     * @see https://msdn.microsoft.com/en-us/library/windows/desktop/dd375731(v=vs.85).aspx
     * @see https://fmtvp.github.io/tal/jsdoc/symbols/src/antie_static_script_events_keyevent.js.html
     *
     * @listens {keyup}
     * @param {Event} event
     * @private
     */
    _processKeyUp(event: KeyboardEvent): void {
        if (this.isPlayerHidden) {
            return;
        }

        // This is a space, not an empty string
        if (event.key === ' ') {
            this.togglePlay(event);
            return;
        }

        /** @todo: remove deprecated keycode use for firetv and replace with event.key  */
        const key = event.keyCode !== 0 ? event.keyCode : parseInt(event.code, 10); // tslint:disable-line:deprecation

        switch (key) {
            case 32: // Spacebar
            case 179: // Amazon FireTV controller Play/Pause button; Microsoft keyboard "VK_MEDIA_PLAY_PAUSE" key code
                this.togglePlay(event);
                return;

            default:
                break;
        }


        if (this.isPlaying) {
            switch (key) {
                case 19: // Pause/Break; "VK_PAUSE" key code
                // falls through
                case 178: // Microsoft keyboard "VK_MEDIA_STOP" key code
                // falls through
                case 413: // "VK_STOP" key code
                    this.togglePlay(event);
                    break;

                default:
                    break;
            }
        } else {
            switch (key) {
                case 250: // Microsoft keyboard "VK_PLAY" key code
                // falls through
                case 415: // Alternative "VK_PLAY" key code
                // falls through
                case 445: // Another key mapping found for "VK_PLAY"
                    this.togglePlay(event);
                    break;

                default:
                    break;
            }
        }
    }
}
export default TogglePlayComponent;
