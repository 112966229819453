import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Story } from '../../util/recommendation-parser';
import { CardBackground } from '../metadata.state';

@Component({
    selector: 'card',
    template: `
    <div *ngIf="background" class="background-texture" role="presentation"
         [class.background-texture--from-image]="background.textureImage">
        <div *ngIf="background.textureImage" class="background-texture__image">
            <img [src]="background.textureImage.href" alt="Blurred story image as background texture" />
        </div>
        <div *ngIf="background.shouldUseGradientTexture" class="background-texture__gradient"
             [class.background-texture__gradient--blue]="!background.shouldUseGrayGradientTexture"
             [class.background-texture__gradient--gray]="background.shouldUseGrayGradientTexture"
             [class.background-texture__gradient--repeating]="background.shouldUseRepeatingGradientTexture">
        </div>
        <div *ngIf="background.shouldUseRepeatingGradientTexture" class="background-texture__gradient background-texture__gradient--repeating"
             [class.background-texture__gradient--repeating--variation-one]="background.repeatingGradientVariation === 1"
             [class.background-texture__gradient--repeating--variation-two]="background.repeatingGradientVariation === 2"
             [class.background-texture__gradient--repeating--variation-three]="background.repeatingGradientVariation === 3"
             [class.background-texture__gradient--repeating--variation-four]="background.repeatingGradientVariation === 4"
             [class.background-texture__gradient--repeating--variation-five]="background.repeatingGradientVariation === 5"
             [class.background-texture__gradient--repeating--variation-six]="background.repeatingGradientVariation === 6">
        </div>
        <div *ngIf="background.shouldUseFlatGrayBackground" class="background-texture__flat background-texture__flat--gray">
        </div>
    </div>
    <article *ngIf="story" class="grid__column grid__column--flex card" role="article" aria-live="assertive" aria-atomic="true"
             [ngSwitch]="story.templateType" [attr.data-uid]="story.uid" [attr.data-card-type]="story.templateType">
        <station-card *ngSwitchCase="'stationId'" class="grid__row grid__row--flex" [attr.data-station-call-letters]="story.title"
                      [story]="story">
        </station-card>
        <upsell *ngSwitchCase="'upsell'" class="grid__row grid__row--flex grid__row--with-gutters grid__row--horizontally-centered" [attr.data-feature-card-type]="story.type"
                [story]="story"
                (onClickThrough)="onClickThrough()">
        </upsell>
        <feature-card *ngSwitchCase="'featureCard'" class="grid__row grid__row--flex grid__row--horizontally-centered center" [attr.data-feature-card-type]="story.type"
                      [story]="story"
                      (onClickThrough)="onClickThrough(isInternal=$event)">
        </feature-card>
        <sponsorship *ngSwitchCase="'sponsorship'" class="grid__row grid__row--flex grid__row--centered"
                     [story]="story"
                     (onClickThrough)="onClickThrough(isInternal=$event)">
        </sponsorship>
        <story-card id="story-card" *ngSwitchDefault class="grid__row grid__row--flex" [attr.data-story-type]="story.type"
                    [story]="story"
                    [hasMarkedInteresting]="hasMarkedInteresting"
                    [isShareMenuExpanded]="isShareMenuExpanded"
                    (onMarkInteresting)="onMarkInteresting()"
                    (onShare)="onShare()"
                    (onCloseShareMenu)="onCloseShareMenu()">
        </story-card>
    </article>
    `,
    styleUrls: ['./card.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * This is a thin wrapper component which is essentially just one giant switch statement that determines which
 * sub-component to select based on the type of recommendation (story) we are working with.
 */
export class CardComponent {
    /** @type {Story} */
    @Input() story: Story;
    /** @type {CardBackground} */
    @Input() background: CardBackground;
    /** @type {boolean} */
    @Input() hasMarkedInteresting: boolean;
    /** @type {boolean} */
    @Input() isShareMenuExpanded: boolean;
    /** @type {EventEmitter} */
    @Output('onMarkInteresting') _onMarkInteresting: EventEmitter<void> = new EventEmitter();
    /** @type {EventEmitter} */
    @Output('onShare') _onShare: EventEmitter<void> = new EventEmitter();
    /** @type {EventEmitter} */
    @Output('onCloseShareMenu') _onCloseShareMenu: EventEmitter<void> = new EventEmitter();
    /** @type {EventEmitter} */
    @Output('onClickThrough') _onClickThrough: EventEmitter<boolean> = new EventEmitter();

    constructor(private _sanitizer: DomSanitizer) {}

    get shareableLink() {
        return this._sanitizer.bypassSecurityTrustResourceUrl(this.story.shareableLink);
    }

    /**
     * The event listener that gets called when the user has marked the story as interesting
     */
    onMarkInteresting(): void {
        this._onMarkInteresting.emit();
    }

    /**
     * The event listener that gets called when the user has shared the story
     */
    onShare(): void {
        this._onShare.emit();
    }

    /**
     * The event listener that gets called when a user has clicked the 'X' inside the share sub-menu
     */
    onCloseShareMenu(): void {
        this._onCloseShareMenu.emit();
    }

    /**
     * The event listener that gets called when the user has clicked through on a sponsorship item or feature card
     *
     * @param {boolean} isInternal
     */
    onClickThrough(isInternal = false): void {
        this._onClickThrough.emit(isInternal);
    }
}
export default CardComponent;
