export enum FeatureCardTypeImages {
    EXTLINK = 'logo_square',
    PROMO = 'logo_square',
    INFO = 'icon',
}

export enum CardTypeImages {
    DEFAULT = 'standard',
    STATION = 'logo',
    SPONSORSHIP = '',
    FEATURE = 'logo_square',
    UPSELL = 'logo_square',
}
export default CardTypeImages;
