import { Action } from '@ngrx/store';

import ActionTypes from '../constants/action-types.enum';


export class RecordRouteChangeAction implements Action {
    readonly type = ActionTypes.ROUTE_CHANGED;
}

export class SignInAction implements Action {
    readonly type = ActionTypes.SIGN_IN;
}

export class InitAction implements Action {
    readonly type = ActionTypes.INIT;
}

export type AllSharedActions
    = RecordRouteChangeAction
    | SignInAction
    | InitAction;

export default AllSharedActions;
