import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BrowserService } from '../core/browser.service';
import { LoggerService } from '../core/logger.service';

@Injectable()
/**
 * A very thin singleton class whose job it is to detect whether there are any UTM campaign parameters
 * in the URL when the app is first loaded and retain those for the lifetime of the app so that they
 * can be appended to relevant outgoing links. Requested as a feature by Nick DePrey.
 *
 * By default, we use the querystring `utm_medium=npr_one_webapp&utm_source=one.npr.org&utm_campaign=`.
 * `utm_medium` is hard-coded and will always be used, even if another `utm_medium` is passed in via the
 * location bar. `utm_source`, however, is overrideable. `utm_campaign` will always be set to the Seamus ID
 * of a story if a `sharedMediaId` is passed into the URL, but if not, it is overrideable.
 */
export class CampaignService {
    private _campaignParams: HttpParams;

    constructor(private _browser: BrowserService, private _logger: LoggerService) {
        this._campaignParams = new HttpParams({
            fromObject: {
                utm_medium: 'npr_one_webapp',
                utm_source: 'one.npr.org',
                utm_campaign: '',
            },
        });
    }

    /**
     * Parses the current window's query parameters and extracts all campaign parameters
     */
    parseQueryParameters(): void {
        const queryParams = new HttpParams({ fromString: this.currentQueryString.replace(/^\?/, '')} );
        let seamusId = null;
        const keys = queryParams.keys();
        keys.forEach(key => {
            if (/^utm_/.test(key) && key !== 'utm_medium') { // we hard-code utm_medium to npr_one_webapp regardless of what is passed in
                if (key === 'utm_source' || key === 'utm_campaign') {
                    const values = queryParams.getAll(key);
                    values.forEach(value => {
                        this._campaignParams = this._campaignParams.set(key, value); // override previously-set value
                    });
                } else {
                    const values = queryParams.getAll(key);
                    values.forEach(value => {
                        this._campaignParams = this._campaignParams.append(key, value); // append (don't override)
                    });
                }
            } else if (key === 'sharedMediaId') {
                const values = queryParams.getAll(key);
                values.forEach(value => {
                    if (value) { seamusId = value.split(':', 2)[0]; }
                });
            }
        });
        if (seamusId !== null) {
            this._campaignParams = this._campaignParams.set('utm_campaign', seamusId); // override previously-set value
        }
        this._logger.debug('CampaignService', 'Will use the following campaign parameters:', this.queryString);
    }

    /**
     * @returns {string}
     */
    get queryString() {
        return this._campaignParams.toString();
    }

    /**
     * For the given URL, appends the campaign parameter querystring.
     *
     * @param {string} url
     * @returns {string}
     */
    appendQueryStringToUrl(url: string): string {
        const queryString = this.queryString;
        if (queryString) {
            if (url.indexOf('?') >= 0) {
                return `${url}&${queryString}`;
            }
            return `${url}?${queryString}`;
        }
        return url;
    }

    private get currentQueryString(): string {
        const window = this._browser.getWindow();
        return window.location.search;
    }
}
export default CampaignService;
