import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { AnalyticsService } from '../../analytics/analytics.service';
import { BrowserService } from '../../core/browser.service';
import { MobilePlayService } from '../mobile-play.service';

@Component({
    selector: 'skip',
    template: `
    <button type="button" class="player__play-control" aria-kbdshortcuts="ArrowRight MediaTrackNext MediaFastForward MediaSkipForward"
            [disabled]="hasDisabledControls" [attr.aria-disabled]="hasDisabledControls"
            [class.player__play-control--inactive]="!hasAudio || hasDisabledControls"
            (click)="skip($event)" (touchend)="skip($event)" tabindex="0">
        <i class="player__play-control__icon player__play-control__icon--skip" role="img" aria-label="Skip icon"></i>
    </button>
    `,
    styleUrls: ['./skip.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * A sub-component of the player representing the skip button
 *
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class SkipComponent implements OnInit, OnDestroy {
    /** @type {boolean} */
    @Input() hasAudio: boolean;
    /** @type {boolean} */
    @Input() hasDisabledControls: boolean;
    /** @type {boolean} */
    @Input() isPlaying: boolean;
    /** @type {boolean} */
    @Input() isPlayerHidden: boolean;
    /** @private */
    @Output('onSkip') _onSkip: EventEmitter<void> = new EventEmitter();

    constructor(private _mobilePlayService: MobilePlayService, private _analytics: AnalyticsService, private _browser: BrowserService) {}

    /**
     * The actions that should be taken when a component has been initialized.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnInit-interface.html
     */
    ngOnInit() {
        this._browser.getWindow().document.addEventListener('keyup', this._processKeyUp.bind(this), false);
    }

    /**
     * The actions that should be taken right before a component is destroyed.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnDestroy-interface.html
     */
    ngOnDestroy() {
        this._browser.getWindow().document.removeEventListener('keyup', this._processKeyUp.bind(this), false);
    }

    /**
     * The callback that gets triggered when the user clicks the skip button.
     *
     * @listens {click}
     * @listens {touchend}
     * @param {Event} event
     */
    skip(event: Event): void {
        event.preventDefault();

        if (this.hasDisabledControls || !this.hasAudio) {
            return;
        }

        if (event.type === 'touchend' && !this.isPlaying) {
            this._mobilePlayService.play();
        }

        this._onSkip.emit();

        this._analytics.sendEvent('audio user interactions', 'skip audio');
    }

    /**
     * Processes keyboard/remote controller input.
     *
     * @see https://developer.amazon.com/public/solutions/platforms/webapps/docs/supporting-controllers-in-web-apps
     * @see https://msdn.microsoft.com/en-us/library/windows/desktop/dd375731(v=vs.85).aspx
     * @see https://fmtvp.github.io/tal/jsdoc/symbols/src/antie_static_script_events_keyevent.js.html
     *
     * @listens {keyup}
     * @param {Event} event
     * @private
     */
    _processKeyUp(event: KeyboardEvent): void {
        if (this.isPlayerHidden) {
            return;
        }

        if (event.key === 'ArrowRight') {
            this.skip(event);
            return;
        }

        /** @todo: remove deprecated keycode use for firetv and replace with event.key  */
        const key = event.keyCode !== 0 ? event.keyCode : parseInt(event.code, 10); // tslint:disable-line:deprecation

        switch (key) {
            case 39: // Right arrow
            // falls through
            case 176: // Microsoft keyboard "VK_MEDIA_NEXT_TRACK" key code
            // falls through
            case 228: // Amazon FireTV controller Fast-Forward/Skip button
            // falls through
            case 417: // "VK_FAST_FWD" key code
            // falls through
            case 425: // "VK_NEXT" key code
                this.skip(event);
                break;

            default:
                break;
        }
    }
}
export default SkipComponent;
