import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';

import BrowserService from '../../core/browser.service';
@Component({
  selector: 'bypass-menu',
  template: `
  <div class="site__nav__item">
      <button *ngIf="!isExploreActive || !isChangeStationActive" id="skip-to-player" class="bypass-link" (click)="skipToPlayer()">
        skip to player controls
      </button>
  </div>
  <div class="site__nav__item">
      <button *ngIf="!isExploreActive || !isChangeStationActive" id="skip-to-main" class="bypass-link" (click)="skipToMain()">
        skip to main content
      </button>
  </div>
  `,
  styleUrls: ['./bypass.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})


export class BypassComponent {
  @Input() isPlaying: boolean;
  @Input() isExploreActive: boolean;
  @Input() isChangeStationActive: boolean;

  constructor(private element: ElementRef, private _browser: BrowserService) { }

  skipToPlayer(): void {
    this._browser.getWindow().document.getElementById('goToPlayer').focus();
    this.element.nativeElement.querySelector('#skip-to-player').blur();
  }

  skipToMain(): void {
    this._browser.getWindow().document.getElementById('main-card').focus();
    this.element.nativeElement.querySelector('#skip-to-main').blur();
  }
}
export default BypassComponent;
