import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DurationPipe } from './duration.pipe';
import { TruncateCharactersPipe } from './truncate-characters.pipe';
import { TruncateWordsPipe } from './truncate-words.pipe';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        DurationPipe,
        TruncateCharactersPipe,
        TruncateWordsPipe,
    ],
    exports: [
        DurationPipe,
        TruncateCharactersPipe,
        TruncateWordsPipe,
    ],
})
export class UtilModule {
}
