import { Injectable } from '@angular/core';

import { BrowserService } from './browser.service';
import { LoggerService } from './logger.service';

@Injectable()
/**
 * Responsible for storing content ... locally.
 */
export class StorageService {
    private _storage: Storage;

    /**
     * Checks to see if localStorage is available
     */
    constructor(private _browser: BrowserService, private _logger: LoggerService) {
        this._storage = null;

        const Modernizr = this._browser.getModernizr();
        if (Modernizr && Modernizr.localstorage) {
            this._storage = this._browser.getLocalStorage();
        }

        if (this._storage === null) {
            this._logger.warn('StorageService', 'HTML5 localStorage is unavailable!');
        }
    }

    get isAvailable(): boolean {
        return this._storage !== null;
    }

    /**
     * Retrieves value from local storage if one exists for key
     *
     * @param {string} key
     * @returns {string|null}
     */
    getItem(key: string): string | null {
        if (this._storage !== null) {
            return this._storage.getItem(key);
        } else {
            this._logger.warn('StorageService', `Unable to get item for key ${key}`,
                '- Reason: HTML5 localStorage is unavailable!');
        }

        return null;
    }

    /**
     * Stores a key value pair in local storage
     *
     * @param {string} key
     * @param {string|null} value
     */
    setItem(key: string, value?: string): void {
        if (this._storage !== null) {
            this._storage.setItem(key, value);
        } else {
            this._logger.warn('StorageService', `Unable to set item for key ${key}`,
                '- Reason: HTML5 localStorage is unavailable!');
        }
    }

    /**
     * Removes a key and value from local storage
     *
     * @param {string} key
     */
    removeItem(key: string): void {
        if (this._storage !== null) {
            this._storage.removeItem(key);
        } else {
            this._logger.warn('StorageService', `Unable to remove item for key ${key}`,
                '- Reason: HTML5 localStorage is unavailable!');
        }
    }
}
export default StorageService;
