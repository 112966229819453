import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'read-button',
    template: `
    <a class="button" rel="external noopener" target="_blank" [href]="link">
        <div class="grid__row grid__row--flex grid__row--centered card__button__contents">
            <i class="grid__column grid__column--fixed-width" role="img" aria-label="Read story icon"></i>
            <b class="grid__column">Read Story</b>
        </div>
    </a>
    `,
    styleUrls: ['./read.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * A very thin component that represents the "Read Story" button
 */
export class ReadStoryButtonComponent {
    /** @type {string} */
    @Input() link: string;
}
export default ReadStoryButtonComponent;
