import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate',
})
/**
 * A custom pipe that truncates a string to a given number of characters. Defaults to 100 characters.
 * Usage: `value | truncate:<numCharacters>`
 *
 * @example
 * {{ station.displayName | truncate:25 }}
 *
 * @implements {PipeTransform}
 */
export class TruncateCharactersPipe implements PipeTransform {
    /**
     * Truncates a string to a given number of characters. Defaults to 100 characters if no limit is specified.
     *
     * @param {string} value
     * @param {Array} args
     * @returns {string}
     */
    transform(value: any, args?: any): any {
        if (typeof value === 'string') {
            let limit = 100;
            if (args) {
                if (args.length) {
                    limit = parseInt(args[0], 10);
                } else {
                    limit = parseInt(args, 10);
                }
            }
            if (isNaN(limit) || !isFinite(limit)) {
                limit = 100;
            }

            const characters = value.trim().split('');
            if (characters.length > limit) {
                const limitedCharacters = characters.slice(0, limit);
                let truncatedString = limitedCharacters.join('');
                if (!/[(\.")\.\?!]$/.test(truncatedString)) { // tslint:disable-line no-useless-escape
                    if (truncatedString.endsWith(',')) {
                        truncatedString = truncatedString.slice(0, -1);
                    }
                    truncatedString += '…';
                }
                return truncatedString;
            }
        }
        return value;
    }
}
export default TruncateCharactersPipe;
