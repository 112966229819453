import { ImageLink } from '@npr/npr-one-sdk';

import RootState from '../app/root.state';
import FallbackImages from '../constants/fallback-images.enum';
import FeatureStores from '../constants/feature-stores.enum';
import { Story } from '../util/recommendation-parser';

/**
 * @typedef {Object} CardBackground
 * @property {ImageLink|null} textureImage The image to serve as a background texture for the story content, if applicable
 * @property {boolean} shouldUseGradientTexture Whether or not to use a radial gradient as the background texture for the story content
 * @property {boolean} shouldUseGrayGradientTexture Whether or not to use a gray radial gradient instead of the default blue gradient
 * @property {boolean} shouldUseRepeatingGradientTexture Whether or not to use the repeating radial gradient (used for stories with fallback images)
 * @property {number} repeatingGradientVariation An integer corresponding to the variation (color) of repeating radial gradient to use
 * @property {boolean} shouldUseFlatGrayBackground Whether or not to use a flat, light gray background for the story
 */
export interface CardBackground {
    textureImage: ImageLink | null;
    shouldUseGradientTexture: boolean;
    shouldUseGrayGradientTexture: boolean;
    shouldUseRepeatingGradientTexture: boolean;
    repeatingGradientVariation: number;
    shouldUseFlatGrayBackground: boolean;
}

/**
 * @typedef {Object} MetadataState
 * @property {boolean} isLoading Whether or not the application is in the process of loading (new) metadata
 * @property {Story} story The story (if loaded) or an uninitialized state of a story (if not loaded)
 * @property {CardBackground} background The type of background to use for the story card
 * @property {boolean} hasMarkedInteresting Whether or not the user has marked the story as interesting
 * @property {boolean} isShareMenuExpanded Whether or not the user has clicked the 'Share' button and expanded the share menu
 */
export interface MetadataState {
    isLoading: boolean;
    story: Story;
    background: CardBackground;
    hasMarkedInteresting: boolean;
    isShareMenuExpanded: boolean;
}

export interface MetadataPlusRootState extends RootState {
    [FeatureStores.METADATA]: MetadataState;
}

/**
 * Initial state (and general structure) of the metadata store.
 *
 * @type {MetadataState}
 */
const initialState: MetadataState = {
    isLoading: true,
    story: {
        templateType: '',
        type: '',
        uid: '',
        title: '',
        slug: '',
        provider: '',
        date: null,
        rationale: '',
        duration: 0,
        description: '',
        images: [],
        primaryImage: null,
        caption: '',
        logo: null,
        webLink: '',
        shareableLink: '',
        isShareable: false,
        callToActionText: '',
        callsToAction: [],
        sponsorshipClickthroughs: [],
    },
    background: {
        textureImage: null,
        shouldUseGradientTexture: false,
        shouldUseGrayGradientTexture: false,
        shouldUseRepeatingGradientTexture: false,
        repeatingGradientVariation: FallbackImages.MIN_FALLBACK_INDEX,
        shouldUseFlatGrayBackground: false,
    },
    hasMarkedInteresting: false,
    isShareMenuExpanded: false,
};

export function getInitialMetadataState() {
    return initialState;
}

export default initialState;
