import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncateWords',
})
/**
 * A custom pipe that truncates a string to a given number of words. Defaults to 50 words.
 * Usage: `value | truncateWords:<numWords>`
 *
 * @example
 * {{ story.description | truncateWords:300 }}
 *
 * @implements {PipeTransform}
 */
export class TruncateWordsPipe implements PipeTransform {
    /**
     * Truncates a string to a given number of words. Defaults to 50 words if no limit is specified.
     *
     * @param {string} value
     * @param {Array} args
     * @returns {string}
     */
    transform(value: any, args?: any): any {
        if (typeof value === 'string') {
            let limit = 50;
            if (args) {
                if (args.length) {
                    limit = parseInt(args[0], 10);
                } else {
                    limit = parseInt(args, 10);
                }
            }
            if (isNaN(limit) || !isFinite(limit)) {
                limit = 50;
            }

            const words = value.trim().split(' ');
            if (words.length > limit) {
                const limitedWords = words.slice(0, limit);
                let truncatedString = limitedWords.join(' ');
                if (!/[(\.")\.\?!]$/.test(truncatedString)) { // tslint:disable-line no-useless-escape
                    if (truncatedString.endsWith(',')) {
                        truncatedString = truncatedString.slice(0, -1);
                    }
                    truncatedString += '…';
                }
                return truncatedString;
            }
        }
        return value;
    }
}
export default TruncateWordsPipe;
