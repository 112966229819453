import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Station } from '@npr/npr-one-sdk';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { AnalyticsService } from '../analytics/analytics.service';
import { CampaignService } from '../analytics/campaign.service';
import { User } from '../api/user/user.state';
import { BrowserService } from '../core/browser.service';

import { NavService } from './nav.service';
import initialState from './nav.state';

@Component({
    selector: 'header.site__header',
    template: `
    <div class="grid__column grid__column--fixed-width grid__column--vertically-centered grid__column--retain-gutter site__logo">
        <i class="site__logo__icon site__logo__icon--horizontal"></i>
    </div>
    <bypass-menu [isExploreActive]="isExploreActive"
                 [isPlaying]="isPlaying"
                 [isChangeStationActive]="isChangeStationActive">
    </bypass-menu>
    <organization-menu id="orgMenu" class="grid__column grid__column--fixed-width grid__column--vertically-centered site__nav"
                       [user]="user$ | async"
                       [station]="stationDetails$ | async"
                       [isChangeStationActive]="isChangeStationActive"
                       [isOrganizationMenuExpanded]="isOrganizationMenuExpanded$ | async"
                       (onToggleOrganizationMenu)="onToggleOrganizationMenu()">
    </organization-menu>
    <nav class="grid__column grid__column--vertically-centered grid__column--retain-gutter site__nav" role="navigation">
        <div class="grid__row grid__row--flex">
            <div class="grid__column grid__column--flex grid__column--align-left site__nav__secondary__wrapper">
                <ul class="grid__row grid__row--flex site__nav__secondary" role="menu">
                    <li class="site__nav__item" role="menuitem" [attr.aria-disabled]="isNowPlayingActive"
                        [class.site__nav__item--linkless]="isNowPlayingActive">
                        <a *ngIf="!isNowPlayingActive" routerLink="/" id="nowPlayingLink" aria-label="View the Now Playing Screen">Now Playing</a>
                        <span *ngIf="isNowPlayingActive" id="nowPlayingLink">Now Playing</span>
                    </li>
                    <li class="site__nav__item" role="menuitem" [attr.aria-disabled]="isExploreActive"
                        [class.site__nav__item--linkless]="isExploreActive" >
                        <a *ngIf="!isExploreActive" routerLink="/explore" id="exploreLink" aria-label="View shows, podcasts, and other listening options" >Explore</a>
                        <span *ngIf="isExploreActive" id="exploreLink">Explore</span>
                    </li>
                </ul>
            </div>
            <div class="grid__column grid__column--no-grow site__nav__secondary__wrapper">
                <ul class="grid__row grid__row--flex site__nav__secondary" role="menu">
                    <li class="site__nav__item site__nav__item--with-emphasis" role="menuitem">
                        <a rel="me external noopener" target="_blank" href="http://www.npr.org/about/products/npr-one/"
                            id="getTheApp" title="Get The App"
                           (click)="clickAppsLink($event)" aria-label="Get the NPR One app">Get The App</a>
                    </li>
                    <li class="site__nav__item" role="menuitem" aria-haspopup="true">
                        <user-menu [user]="user$ | async"
                                   [isSignedIn]="isSignedIn$ | async"
                                   [isUserMenuExpanded]="isUserMenuExpanded$ | async"
                                   (onSignIn)="onSignIn()"
                                   (onSignOut)="onSignOut()"
                                   (onToggleUserMenu)="onToggleUserMenu()">
                        </user-menu>
                    </li>
                </ul>
            </div>
            <ul class="grid__column grid__column--no-grow site__nav__primary" role="menu">
                <li class="site__nav__item" role="menuitem" aria-haspopup="true">
                    <button id="toggleJunkDrawer" class="site__nav__button"
                            (click)="onToggleJunkDrawer($event)">
                        <i class="site__nav__icon site__nav__icon--junk-drawer" role="img" aria-label="Click for more options and information"></i>
                    </button>
                    <junk-drawer [isExpanded]="isJunkDrawerExpanded$ | async"
                                 [userIsSignedIn]="isSignedIn$ | async"
                                 [user]="user$ | async"
                                 [isNowPlayingActive]="isNowPlayingActive"
                                 [isExploreActive]="isExploreActive"
                                 (close)="onToggleJunkDrawer()"
                                 (onClickAppsLink)="clickAppsLink($event)">
                    </junk-drawer>
                </li>
            </ul>
        </div>
        <div *ngIf="isJunkDrawerExpanded$ | async" class="site__nav__overlay" role="presentation"
             (click)="onToggleJunkDrawer()">
        </div>
        <div *ngIf="isUserMenuExpanded$ | async" class="site__nav__overlay" role="presentation"
             (click)="onToggleUserMenu()">
        </div>
        <div *ngIf="isOrganizationMenuExpanded$ | async" class="site__nav__overlay" role="presentation"
             (click)="onToggleOrganizationMenu()">
        </div>
    </nav>
    `,
    styleUrls: ['./nav.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * This component manages the header and navigation at the top of the page.
 *
 * @implements {OnInit}
 */
export class NavComponent implements OnInit {
    /** @type {boolean} */
    @Input() isNowPlayingActive: boolean;
    /** @type {boolean} */
    @Input() isExploreActive: boolean;
    /** @type {boolean} */
    @Input() isChangeStationActive: boolean;
    /** @type {boolean} */
    @Input() isPlaying: boolean;

    public isJunkDrawerExpanded$: Observable<boolean>;
    public isSignedIn$: Observable<boolean>;
    public isUserMenuExpanded$: Observable<boolean>;
    public isOrganizationMenuExpanded$: Observable<boolean>;
    public stationDetails$: Observable<Station>;
    public user$: Observable<User>;

    constructor(private _navService: NavService, private _analytics: AnalyticsService, private _campaign: CampaignService, private _browser: BrowserService) {
        /** @type {Observable<boolean>} */
        this.isJunkDrawerExpanded$ = this._navService.state$.pipe(
            map(s => s.isJunkDrawerExpanded),
            startWith(initialState.isJunkDrawerExpanded),
            distinctUntilChanged(),
        );
        /** @type {Observable<boolean>} */
        this.isSignedIn$ = this._navService.state$.pipe(map(s => s.isSignedIn), startWith(initialState.isSignedIn), distinctUntilChanged());
        /** @type {Observable<boolean>} */
        this.isUserMenuExpanded$ = this._navService.state$.pipe(
            map(s => s.isUserMenuExpanded),
            startWith(initialState.isUserMenuExpanded),
            distinctUntilChanged(),
        );
        /** @type {Observable<boolean>} */
        this.isOrganizationMenuExpanded$ = this._navService.state$.pipe(
            map(s => s.isOrganizationMenuExpanded),
            startWith(initialState.isOrganizationMenuExpanded),
            distinctUntilChanged(),
        );
        /** @type {Observable<Station>} */
        this.stationDetails$ = this._navService.state$.pipe(
            map(s => s.stationDetails),
            startWith(initialState.stationDetails),
            distinctUntilChanged(),
        );
        /** @type {Observable<User>} */
        this.user$ = this._navService.user$;
    }

    /**
     * This method starts a long chain of actions which:
     * - loads an access token from either a temporary or full (logged-in) user
     * - causes playlist to ask the NPR One SDK for a recommendation
     */
    ngOnInit() {
        this._navService.loadUser();
    }

    /**
     * The event listener that gets called when the user has toggled the "junk drawer"
     *
     * @param {Event} [event]
     */
    onToggleJunkDrawer(event?: Event): void {
        if (event) {
            event.preventDefault();
        }

        this._navService.onToggleJunkDrawer();
    }

    /**
     * The event listener that gets called when the user has toggled the user menu
     */
    onToggleUserMenu(): void {
        this._navService.onToggleUserMenu();
    }

    /**
     * The event listener that gets called when the user has toggled the organization menu
     */
    onToggleOrganizationMenu(): void {
        this._navService.onToggleOrganizationMenu();
    }

    /**
     * The event listener that gets called when the user has clicked the "Sign In" link
     */
    onSignIn(): void {
        this._navService.onSignIn();
    }

    /**
     * The event listener that gets called when the user has clicked the "Sign Out" link
     */
    onSignOut(): void {
        this._navService.onSignOut();
    }

    /**
     * The event listener that gets called when the user has clicked on the "NPR One Apps" link
     *
     * @param {Event} event
     */
    clickAppsLink(event: Event): void {
        event.preventDefault();

        if (event.target instanceof HTMLAnchorElement) {
            const href = this._campaign.appendQueryStringToUrl((<HTMLAnchorElement> event.target).href);
            const newWindow: Window = this._browser.getWindow().open(href, '_blank');
            newWindow.opener = null;
        }

        this._analytics.sendEvent('settings', 'about npr one', 'external link');
    }
}
export default NavComponent;
