import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import NprOneSDK, { User } from '@npr/npr-one-sdk';
import {interval as observableInterval,  Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import FeatureStores from '../../constants/feature-stores.enum';
import { BrowserService } from '../../core/browser.service';
import { LoggerService } from '../../core/logger.service';
import { StorageService } from '../../core/storage.service';
import ApiActions from '../api.actions';
import { ApiService } from '../api.service';

import initialState, { User as StateUser, UserPlusRootState, UserState } from './user.state';

@Injectable()
/**
 * Responsible for user-centric data and work flows.
 */
export class UserService {
    public user$: Observable<StateUser>;
    public _userId: string = null; // @TODO make private after refactoring tests
    public _userIsTemporary = true; // @TODO make private after refactoring tests

    private _state$: Observable<UserState>;

    private readonly _accessTokenKey = 'nprone_access_token';

    constructor(private _store: Store<UserPlusRootState>, private _api: ApiService, private _browser: BrowserService, private _storage: StorageService, private _logger: LoggerService) {
        this._state$ = this._store.select(FeatureStores.USER);

        /** @type {Observable<User>} */
        this.user$ = this._state$.pipe(map(s => s.user), startWith(initialState.user), distinctUntilChanged());

        this._api.onAccessTokenChanged = this._checkAndUpdateTheAccessToken.bind(this);

        this._convertV1AuthTokenCookieToLocalStorage();
        this._convertV2AccessTokenCookieToLocalStorage();

        const timer$ = observableInterval(900000); // 15 minutes
        timer$.subscribe(this.getUser.bind(this));
    }

    /**
     * Starts the OAuth2 login flow
     *
     * Temporary user ID should always be set at this point
     */
    startOAuthSignIn(): void {
        // Attempt to give other services a chance to respond to the SIGN_IN event
        setTimeout(() => {
            const query = this._userIsTemporary ? `?temp_user=${this._userId}` : '';
            this._browser.getWindow().location.href = `${this._api.authHostname}/oauth2/npr${query}`;
        }, 50);
    }

    /**
     * Performs required cleanup when a user signs out. Initiates the process to create a new temporary user.
     */
    signOutUser(): void {
        this._storage.removeItem(this._accessTokenKey);
        this._api.logout();
        this._browser.getWindow().location.href = NprOneSDK.config.subdomain ? `https://${NprOneSDK.config.subdomain}secure.npr.org/account/logout` : 'https://secure.npr.org/account/logout';
        this.loadUser();
    }

    /**
     * Loads either an existing user if an access token is available, or asks for a temporary user if not.
     *
     * This function should typically only be called on the initial page load, and when the user signs out.
     * In all other cases, if you are just trying to refresh the user model, `getUser()` is probably the
     * function you are looking for.
     */
    loadUser(): void {
        const accessToken = this._storage.getItem(this._accessTokenKey);
        if (accessToken) {
            this._api.accessToken = accessToken;
            this.getUser()
                .catch((e) => {
                    this._logger.debug('UserService', `Unable to getUser for ${accessToken}, falling back to creation of a temp user. Error:`, e);
                    this._storage.removeItem(this._accessTokenKey);
                    this.loadUser(); // caution - this will recurse
                });
        } else {
            this._api.createTemporaryUser()
                .then(() => {
                    this._storage.setItem(this._accessTokenKey, this._api.accessToken);
                    return this.getUser();
                })
                .catch((e) => {
                    this._logger.debug('UserService', 'Temporary user creation has failed with:', e);
                    // TODO: dispatch an action to render error state.
                });
        }
    }

    /**
     * A thin wrapper around getUser() in the ApiService to ensure we are marking the API call as having been made.
     *
     * @returns {Promise<User>}
     */
    getUser(): Promise<User> {
        return this._api.getUser()
            .then((user) => {
                this._setUserLoaded(user);
                return Promise.resolve(user);
            })
            .catch((err) => {
                this._logger.debug('UserService', 'Error during getUser() in the SDK:', err);
                return Promise.reject(err);
            });
    }

    /**
     * Sets a user station (organization) preference
     *
     * @param {string|number} stationId
     * @returns {Promise<User>}
     */
    setUserStation(stationId: string | number): Promise<User> {
        return this._api.setUserStation(stationId)
            .then((user) => {
                this._setUserLoaded(user);
                this._store.dispatch(ApiActions.setUserChangedStation());
                return Promise.resolve(user);
            });
    }

    /**
     * Dispatches an event when the current user has finished loading
     *
     * @param {User} user
     * @private
     */
    _setUserLoaded(user: User): void {
        this._userId = user.attributes.id;
        this._userIsTemporary = user.isTemporary();
        this._store.dispatch(ApiActions.setUserLoaded(user));
    }

    /**
     * This handler function gets called every time the `needsAccessTokenCheck` state property gets updated (which is
     * set to `true` every time an API call is made). If the parameter is true, we get the current value of the access
     * token from the SDK (with the ApiService as the middleman) and check to see if it's what we've got saved in
     * localStorage; if it's not, then we update what we have in localStorage with what just came from the SDK. The SDK
     * is always considered the single source of truth.
     * Once we are done, we emit an event indicating that we have checked the access token in the SDK, and that it does
     * not need to be checked again until the next API call.
     *
     * @private
     */
    _checkAndUpdateTheAccessToken(): void {
        this._logger.debug('UserService', 'Checking access token in the SDK to see whether it has changed.');

        const actualToken = this._api.accessToken;
        if (!!actualToken && (actualToken !== this._storage.getItem(this._accessTokenKey))) {
            this._logger.debug('UserService', 'The access token was changed! Saving the new token to localStorage.');
            this._storage.setItem(this._accessTokenKey, actualToken);
        }
    }

    /**
     * Converts a web app "version 1" auth_token cookie (which contains an access token) to local storage
     *
     * @private
     */
    _convertV1AuthTokenCookieToLocalStorage(): void {
        this._convertTokenCookieToLocalStorage('auth_token');
    }

    /**
     * After sign in successfully completes an access token cookie is set. This method converts
     * that access token cookie to local storage.
     *
     * @private
     */
    _convertV2AccessTokenCookieToLocalStorage(): void {
        this._convertTokenCookieToLocalStorage('access_token');
    }

    /**
     * Converts token cookies to local storage
     *
     * @param {string} cookieName
     * @private
     */
    _convertTokenCookieToLocalStorage(cookieName: string): void {
        const document = this._browser.getDocument();
        if (!document.cookie) {
            return;
        }

        const cookie = document.cookie.split('; ').filter(c => c.indexOf(`${cookieName}=`) === 0);
        const accessToken = cookie[0] ? cookie[0].replace(`${cookieName}=`, '') : '';

        if (accessToken) {
            this._logger.debug('UserService', `An ${cookieName} cookie was found and converted to use local storage.`);
            this._storage.setItem(this._accessTokenKey, accessToken);
            document.cookie = `${cookieName}=;path=/;domain=.npr.org; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        }
    }
}
export default UserService;
