import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { AnalyticsModule } from '../analytics/analytics.module';
import { ApiModule } from '../api/api.module';
import { CoreModule } from '../core/core.module';
import { NavModule } from '../nav/nav.module';
import { PlayerModule } from '../player/player.module';
import { CustomRouterStateSerializer } from '../util/router-state-serializer';
import { UtilModule } from '../util/util.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import rootReducer from './root.reducer';

@NgModule({
    imports: [
        BrowserModule,
        AnalyticsModule,
        ApiModule,
        CoreModule,
        NavModule,
        PlayerModule,
        UtilModule,
        AppRoutingModule,
        StoreModule.forRoot(rootReducer),
        StoreRouterConnectingModule.forRoot(),
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        /**
         * The `RouterStateSnapshot` provided by the `Router` is a large complex structure.
         * A custom RouterStateSerializer is used to parse the `RouterStateSnapshot` provided
         * by `@ngrx/router-store` to include only the desired pieces of the snapshot.
         */
        { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    ],
    bootstrap: [
        AppComponent,
    ],
})
export class AppModule {
}
