import { UserOrganization } from '@npr/npr-one-sdk';

import RootState from '../../app/root.state';
import FeatureStores from '../../constants/feature-stores.enum';

export interface User {
    isTemporary: boolean;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    organization: UserOrganization;
}

/**
 * @typedef {Object} UserState
 * @property {boolean} needsAccessTokenCheck
 * @property {User} user
 */
export interface UserState {
    needsAccessTokenCheck: boolean;
    user: User;
}

export interface UserPlusRootState extends RootState {
    [FeatureStores.USER]: UserState;
}

/**
 * Initial state (and general structure) of the user store.
 *
 * @type {UserState}
 */
const initialState: UserState = {
    needsAccessTokenCheck: false,
    user: {
        isTemporary: false,
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        organization: {
            id: '',
            displayName: '',
            call: '',
            city: '',
            logo: '',
        },
    },
};

export function getInitialUserState() {
    return initialState;
}

export default initialState;
