import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import FeatureStores from '../constants/feature-stores.enum';
import { UtilModule } from '../util/util.module';

import { JPlayerComponent } from './jplayer/jplayer.component';
import { JPlayerService } from './jplayer/jplayer.service';
import { MobilePlayService } from './mobile-play.service';
import { ProgressBarComponent } from './play-controls/progress-bar.component';
import { RewindComponent } from './play-controls/rewind.component';
import { SkipComponent } from './play-controls/skip.component';
import { TogglePlayComponent } from './play-controls/toggle-play.component';
import { VolumeControlsComponent } from './play-controls/volume-controls.component';
import { PlayerComponent } from './player.component';
import playerReducer from './player.reducer';
import { PlayerService } from './player.service';
import { getInitialPlayerState } from './player.state';

@NgModule({
    imports: [
        CommonModule,
        UtilModule,
        StoreModule.forFeature(FeatureStores.PLAYER, playerReducer, { initialState: getInitialPlayerState }),
    ],
    declarations: [
        PlayerComponent,
        JPlayerComponent,
        SkipComponent,
        ProgressBarComponent,
        RewindComponent,
        TogglePlayComponent,
        VolumeControlsComponent,
    ],
    exports: [
        PlayerComponent,
    ],
    providers: [
        PlayerService,
        JPlayerService,
        MobilePlayService,
    ],
})
export class PlayerModule {
}
