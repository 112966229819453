import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'duration',
})
/**
 * A custom pipe for displaying durations in mm:ss (if under one hour) or HH:mm:ss (if over one hour).
 *
 * @implements {PipeTransform}
 */
export class DurationPipe implements PipeTransform {
    /**
     * Transforms a duration in seconds (as either a number or a stringified number) into a duration string
     * in mm:ss (if under one hour) or HH:mm:ss (if over one hour).
     *
     * @param {number|string} value
     * @param {Array} args
     * @returns {string|null}
     */
    transform(value: any, args?: any): any {
        if (typeof value !== 'object') {
            const seconds = typeof value !== 'number' ? parseFloat(value) : value;
            if (!isNaN(seconds) && isFinite(seconds)) {
                return DurationPipe.secondsToHHMMSS(seconds);
            }
        }
        return null;
    }

    /**
     * Turns a total number of seconds into an HH:MM:SS duration.
     *
     * @param {number} value
     * @returns {string}
     */
    private static secondsToHHMMSS(value) {
        const totalSeconds = Math.round(value);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
        const seconds = totalSeconds - (hours * 3600) - (minutes * 60);

        let timeString = '';
        if (hours > 0) {
            timeString += `${hours}:`;
            if (minutes < 10) {
                timeString += '0';
            }
        }
        timeString += `${minutes}:`;
        if (seconds < 10) {
            timeString += '0';
        }
        timeString += seconds;
        return timeString;
    }
}
export default DurationPipe;
