import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BehaviorSubject, Observable, SubscriptionLike as ISubscription } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';





import { AnalyticsService } from '../analytics/analytics.service';
import { CardTypes } from '../constants/card-types.enum';
import { Story } from '../util/recommendation-parser';

import { MetadataService } from './metadata.service';
import initialState, { CardBackground } from './metadata.state';

@Component({
    selector: 'metadata.grid__column.grid__column--flex',
    template: `
    <div *ngIf="isLoading$ | async" class="grid__row grid__row--flex grid__row--centered grid__row--horizontally-centered">
        <img src="https://media.npr.org/chrome/svg/loading.svg" alt="Loading" />
    </div>
    <card id="main-card" [attr.data-hidden]="isLoading$ | async" class="grid__row grid__row--flex"
          [story]="story$ | async"
          [background]="background$ | async"
          [hasMarkedInteresting]="hasMarkedInteresting$ | async"
          [isShareMenuExpanded]="isShareMenuExpanded$ | async"
          (onMarkInteresting)="onMarkInteresting()"
          (onShare)="onShare()"
          (onCloseShareMenu)="onCloseShareMenu()"
          (onClickThrough)="onClickThrough($event)">
    </card>
    `,
    styleUrls: ['./metadata.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetadataComponent implements OnInit, OnDestroy {
    public isLoading$: Observable<boolean>;
    public story$: Observable<Story>;
    public background$: Observable<CardBackground>;
    public hasMarkedInteresting$: Observable<boolean>;
    public isShareMenuExpanded$: Observable<boolean>;

    private _ready$: BehaviorSubject<boolean>;
    private _sharedMediaId$: Observable<string | null>;
    private _sharedMediaId: string | null;
    private _channel$: Observable<string | null>;
    private _channel: string | null;
    private _hasStory: boolean;
    private _subscriptions: Array<ISubscription> = [];

    constructor(private _metadataService: MetadataService, private _router: Router, private route: ActivatedRoute, private _title: Title, private _analytics: AnalyticsService) {
        this._sharedMediaId$ = route.queryParamMap.pipe(
            map((queryParams: ParamMap) => queryParams.get('sharedMediaId')),
            distinctUntilChanged(),
        );
        this._channel$ = route.queryParamMap.pipe(map((queryParams: ParamMap) => queryParams.get('channel')), distinctUntilChanged());
        /** @private */
        this._hasStory = false;
        this._ready$ = new BehaviorSubject(false);

        /** @type {Observable<boolean>} */
        this.isLoading$ = this._metadataService.state$.pipe(
            map(s => s.isLoading),
            startWith(initialState.isLoading),
            distinctUntilChanged(),
        );
        /** @type {Observable<Story>} */
        this.story$ = this._metadataService.state$.pipe(map(s => s.story), startWith(initialState.story), distinctUntilChanged());
        /** @type {Observable<CardBackground>} */
        this.background$ = this._metadataService.state$.pipe(
            map(s => s.background),
            startWith(initialState.background),
            distinctUntilChanged(),
        );
        /** @type {Observable<boolean>} */
        this.hasMarkedInteresting$ = this._metadataService.state$.pipe(
            map(s => s.hasMarkedInteresting),
            startWith(initialState.hasMarkedInteresting),
            distinctUntilChanged(),
        );
        /** @type {Observable<boolean>} */
        this.isShareMenuExpanded$ = this._metadataService.state$.pipe(
            map(s => s.isShareMenuExpanded),
            startWith(initialState.isShareMenuExpanded),
            distinctUntilChanged(),
        );
    }

    /**
     * The actions that should be taken when a component has been initialized.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnInit-interface.html
     */
    ngOnInit() {
        this._subscriptions.push(this.story$.subscribe(this._checkIfNewStoryNeedsToUpdateRoute.bind(this)));
        this._subscriptions.push(this._sharedMediaId$.subscribe(this._processSharedMediaIdChange.bind(this)));
        this._subscriptions.push(this._channel$.subscribe((channel: string | null) => {
            this._channel = channel;
            this._metadataService.channel = this._channel;
        }));

        this._metadataService.recordRouteChange();

        this._subscriptions.push(this._ready$.subscribe((ready) => {
            if (ready && !this._hasStory) {
                /*
                 * The below calls kick off the call(s) to the API which will ultimately result in metadata being displayed
                 * in the UI and the first audio being loaded into the player.
                 */
                this._metadataService.loadFirstRecommendation();
            }
        }));
    }

    /**
     * The actions that should be taken right before a component is destroyed.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnDestroy-interface.html
     */
    ngOnDestroy() {
        // don't forget to clean up the subscriptions
        this._subscriptions.forEach((s) => {
            s.unsubscribe();
        });
    }

    /**
     * getter for _subscriptions - used for tests only
     * @return {Array<ISubscription>}
     */
    get subscriptions(): Array<ISubscription> {
        return this._subscriptions;
    }

    /**
     * setter for _channel - used for tests only
     */
    set channel(channel: string | null) {
        this._channel = channel;
    }

    /**
     * The event listener that gets called when the user has marked the story as interesting
     */
    onMarkInteresting(): void {
        this._metadataService.onMarkInteresting();
    }

    /**
     * The event listener that gets called when the user has shared the story
     */
    onShare(): void {
        this._metadataService.onShare();
    }

    /**
     * The event listener that gets called when a user has clicked the 'X' inside the share sub-menu
     */
    onCloseShareMenu(): void {
        this._metadataService.onCloseShareMenu();
    }

    /**
     * The event listener that gets called when the user has clicked through on a sponsorship item or feature card
     *
     * @param {boolean} isInternal
     */
    onClickThrough(isInternal: boolean): void {
        this._metadataService.onClickThrough(isInternal);
    }

    _processSharedMediaIdChange(sharedMediaId: string | null): void {
        const prevSharedMediaId = this._sharedMediaId;
        this._sharedMediaId = sharedMediaId;
        this._metadataService.sharedMediaId = this._sharedMediaId;

        // If the user got here by clicking the back button, go back to that piece in their history(?)
        if (this._sharedMediaId !== null && this._sharedMediaId !== prevSharedMediaId) {
            this._metadataService.goBackTo(this._sharedMediaId);
        }

        this._ready$.next(true);
    }

    /**
     * If the story is "shareable", we want to update the URL in the address bar so it's easier for users
     * to copy/paste a link to a specific story (into e.g. Facebook, Twitter) just from the address bar
     *
     * @param {Story} story
     * @private
     */
    _checkIfNewStoryNeedsToUpdateRoute(story: Story): void {
        if (story && story.uid) {
            this._hasStory = true;

            let path = '/';
            if (story.isShareable) {
                this._title.setTitle(`${story.title} : NPR One`);
                if (this._sharedMediaId !== story.uid) {
                    this._router.navigate([''], { queryParams: { sharedMediaId: story.uid } });
                }
                path = this._getURL(story.uid);
            } else {
                this._title.setTitle('NPR One');
                path = this._getURL();
                if (this._sharedMediaId !== null) {
                    this._router.navigate([''], { queryParams: {} }); // clear the sharedMediaId from the previous piece
                } else if (this._channel !== null) {
                    path = this._getURL(null, this._channel);
                    this._router.navigate([''], { queryParams: {} }); // clear the channel from the URL
                    this._channel = null; // reset
                }
            }
            this._sendAnalyticsPageView(story, path);
        } else {
            this._hasStory = false;
        }
    }

    /**
     * Sends a new pageview to GA via the AnalyticsService
     *
     * @param {Story} story
     * @param {string} [path='/']
     * @private
     */
    _sendAnalyticsPageView(story: Story, path = '/'): void {
        if (story) {
            switch (story.templateType) {
                case CardTypes.STATION:
                    this._analytics.sendPageview(path, `Station ID | ${story.title}`);
                    break;
                case CardTypes.FEATURE:
                case CardTypes.SPONSORSHIP:
                case CardTypes.UPSELL:
                    this._analytics.sendPageview(path);
                    break;
                default:
                    const seamusId = story.uid.split(':', 2)[0];
                    this._analytics.sendPageview(path, `${story.title} | ${seamusId}`);
                    break;
            }
        }
    }

    _getURL(sharedMediaId?: string, channel?: string): string {
        const path = this._router.url;
        const tree = this._router.createUrlTree([path], { queryParams: { sharedMediaId, channel } });
        return this._router.serializeUrl(tree);
    }
}
export default MetadataComponent;
