import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { BrowserService } from './browser.service';
import { LogLevelProvider } from './log-level.provider';
import { LoggerService } from './logger.service';
import { StorageService } from './storage.service';

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        LogLevelProvider,
        LoggerService,
        BrowserService,
        StorageService,
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}
