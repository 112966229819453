import { Action } from '@ngrx/store';

import ActionTypes from '../constants/action-types.enum';

export class TogglePlayAction implements Action {
    readonly type = ActionTypes.TOGGLE_PLAY;
}

export class SetAudioPlayingAction implements Action {
    readonly type = ActionTypes.AUDIO_PLAYING;

    constructor(public payload: { isAudioPlaying: boolean }) {}
}

export class AudioCompleteAction implements Action {
    readonly type = ActionTypes.AUDIO_COMPLETE;
}

export class AudioLoadingAction implements Action {
    readonly type = ActionTypes.AUDIO_LOADING;
}

export class AudioLoadedAction implements Action {
    readonly type = ActionTypes.AUDIO_LOADED;
}

export class UpdatePositionAction implements Action {
    readonly type = ActionTypes.AUDIO_POSITION_CHANGE;

    constructor(public payload: { position: number }) {}
}

export class ChangeDurationAction implements Action {
    readonly type = ActionTypes.AUDIO_DURATION_CHANGE;

    constructor(public payload: { duration: number }) {}
}

export class RewindAction implements Action {
    readonly type = ActionTypes.REWIND;
}

export class SeekAction implements Action {
    readonly type = ActionTypes.SEEK;

    constructor(public payload: { position: number }) {}
}

export class ScrubBeginAction implements Action {
    readonly type = ActionTypes.SCRUB_BEGIN;
}

export class ScrubEndAction implements Action {
    readonly type = ActionTypes.SCRUB_END;
}

export class ToggleVolumeControlsAction implements Action {
    readonly type = ActionTypes.TOGGLE_VOLUME_CONTROLS;
}

export class ToggleMuteAction implements Action {
    readonly type = ActionTypes.TOGGLE_MUTE;
}

export class ChangeVolumeAction implements Action {
    readonly type = ActionTypes.AUDIO_VOLUME_CHANGE;

    constructor(public payload: { volume: number }) {}
}

export class VolumeSliderBeginAction implements Action {
    readonly type = ActionTypes.VOLUME_SLIDER_BEGIN;
}

export class VolumeSliderEndAction implements Action {
    readonly type = ActionTypes.VOLUME_SLIDER_END;
}

export class SkipAction implements Action {
    readonly type = ActionTypes.SKIP;
}

export class TimeoutAction implements Action {
    readonly type = ActionTypes.TIMEOUT;
}

export type AllPlayerActions
    = TogglePlayAction
    | SetAudioPlayingAction
    | AudioCompleteAction
    | AudioLoadingAction
    | AudioLoadedAction
    | UpdatePositionAction
    | ChangeDurationAction
    | RewindAction
    | SeekAction
    | ScrubBeginAction
    | ScrubEndAction
    | ToggleVolumeControlsAction
    | ToggleMuteAction
    | ChangeVolumeAction
    | VolumeSliderBeginAction
    | VolumeSliderEndAction
    | SkipAction
    | TimeoutAction;

/**
 * @typedef {Object} PlayerActions
 * @property {function(): Action} togglePlay
 * @property {function(isAudioPlaying: boolean): Action} setAudioPlaying
 * @property {function(): Action} audioComplete
 * @property {function(position: number): Action} updatePosition
 * @property {function(duration: number): Action} changeDuration
 * @property {function(): Action} rewind
 * @property {function(position: number): Action} seek
 * @property {function(): Action} scrubBegin
 * @property {function(): Action} scrubEnd
 * @property {function(): Action} toggleVolumeControls
 * @property {function(): Action} toggleMute
 * @property {function(volume: number): Action} changeVolume
 * @property {function(): Action} volumeSliderBegin
 * @property {function(): Action} volumeSliderEnd
 * @property {function(): Action} skip
 * @property {function(): Action} timeout
 */
/**
 * @type {PlayerActions} PlayerActions
 */
const PlayerActions = {
    togglePlay(): TogglePlayAction {
        return new TogglePlayAction();
    },

    setAudioPlaying(isAudioPlaying?: boolean): SetAudioPlayingAction {
        return new SetAudioPlayingAction({ isAudioPlaying });
    },

    audioComplete(): AudioCompleteAction {
        return new AudioCompleteAction();
    },

    audioBuffering(): AudioLoadingAction {
        return new AudioLoadingAction();
    },

    audioLoaded(): AudioLoadedAction {
        return new AudioLoadedAction();
    },

    updatePosition(position?: number): UpdatePositionAction {
        return new UpdatePositionAction({ position });
    },

    changeDuration(duration?: number): ChangeDurationAction {
        return new ChangeDurationAction({ duration });
    },

    rewind(): RewindAction {
        return new RewindAction();
    },

    seek(position?: number): SeekAction {
        return new SeekAction({ position });
    },

    scrubBegin(): ScrubBeginAction {
        return new ScrubBeginAction();
    },

    scrubEnd(): ScrubEndAction {
        return new ScrubEndAction();
    },

    toggleVolumeControls(): ToggleVolumeControlsAction {
        return new ToggleVolumeControlsAction();
    },

    toggleMute(): ToggleMuteAction {
        return new ToggleMuteAction();
    },

    changeVolume(volume: number): ChangeVolumeAction {
        return new ChangeVolumeAction({ volume: Math.max(Math.min(volume, 1), 0) });
    },

    volumeSliderBegin(): VolumeSliderBeginAction {
        return new VolumeSliderBeginAction();
    },

    volumeSliderEnd(): VolumeSliderEndAction {
        return new VolumeSliderEndAction();
    },

    skip(): SkipAction {
        return new SkipAction();
    },

    timeout(): TimeoutAction {
        return new TimeoutAction();
    },
};
export default PlayerActions;
