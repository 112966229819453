import { AllActions } from '../../all.actions';
import ActionTypes from '../../constants/action-types.enum';

import initialState, { StationFinderState } from './station-finder.state';


type Action = AllActions;

/**
 * The primary reducer for the stationFinder.
 *
 * @param {StationFinderState} state
 * @param {Action} action
 * @return {StationFinderState}
 */
const stationFinderReducer = (state = initialState, action: Action): StationFinderState => {
    switch (action.type) {
        case ActionTypes.USER_LOADED:
            const user = action.payload;
            const station = user.getPrimaryOrganization();
            return {
                ...state,
                isUserLoaded: true,
                stationId: station ? station.id : '',
            };

        case ActionTypes.SIGN_OUT:
            return {
                ...state,
                isUserLoaded: false,
                stationId: '',
            };

        case ActionTypes.STATION_SEARCH_RESULTS_UPDATING:
            return {
                ...state,
                needsStationSearch: true,
                searchQuery: action.payload ? action.payload.query : initialState.searchQuery,
            };

        case ActionTypes.STATION_SEARCH_RESULTS_UPDATED:
            return {
                ...state,
                needsStationSearch: false,
                searchQuery: initialState.searchQuery,
            };

        default:
            return state;
    }
};
export default stationFinderReducer;
