import { AllActions } from '../../all.actions';
import ActionTypes from '../../constants/action-types.enum';

import initialState, { UserState } from './user.state';


type Action = AllActions;

/**
 * The primary reducer for the user data.
 *
 * @param {UserState} state
 * @param {Action} action
 * @return {UserState}
 */
const userReducer = (state = initialState, action: Action): UserState => {
    switch (action.type) {
        case ActionTypes.USER_LOADED:
            const userObj = action.payload;
            const org = userObj.getPrimaryOrganization();
            return {
                ...state,
                user: {
                    isTemporary: userObj.isTemporary(),
                    id: `${userObj.attributes.id}`, // cast to string because sometimes it's an int
                    firstName: userObj.attributes.firstName,
                    lastName: userObj.attributes.lastName,
                    email: userObj.attributes.email,
                    organization: {
                        id: org && org.id ? org.id : '',
                        displayName: org && org.displayName ? org.displayName : '',
                        call: org && org.call ? org.call : '',
                        city: org && org.city ? org.city : '',
                        logo: org && org.logo ? org.logo : '',
                    },
                },
            };

        default:
            return state;
    }
};
export default userReducer;
