import { Action } from '@ngrx/store';
import { Recommendation, Station, User } from '@npr/npr-one-sdk';

import ActionTypes from '../constants/action-types.enum';


export class SetUserLoadedAction implements Action {
    readonly type = ActionTypes.USER_LOADED;

    constructor(public payload: User) {}
}

export class SetStoryLoadedAction implements Action {
    readonly type = ActionTypes.METADATA_LOADED;

    constructor(public payload: { story: Recommendation }) {}
}

export class SetRecommendationsUpdatedAction implements Action {
    readonly type = ActionTypes.RECOMMENDATIONS_UPDATED;

    constructor(public payload: Array<Recommendation>) {}
}

export class SetStationSearchResultsAction implements Action {
    readonly type = ActionTypes.STATION_SEARCH_RESULTS_UPDATED;

    constructor(public payload: Array<Station>) {}
}

export class SetStationDetailsAction implements Action {
    readonly type = ActionTypes.STATION_DETAILS_UPDATED;

    constructor(public payload?: Station) {}
}

export class SetUserChangedStationAction implements Action {
    readonly type = ActionTypes.USER_CHANGED_STATION;

    constructor(public payload: boolean) {}
}

export type AllApiActions
    = SetUserLoadedAction
    | SetStoryLoadedAction
    | SetRecommendationsUpdatedAction
    | SetStationSearchResultsAction
    | SetStationDetailsAction
    | SetUserChangedStationAction;

const ApiActions = {
    setUserLoaded(user: User): SetUserLoadedAction {
        return new SetUserLoadedAction(user);
    },

    setStoryLoaded(recommendation: Recommendation): SetStoryLoadedAction {
        return new SetStoryLoadedAction({ story: recommendation });
    },

    setRecommendationsUpdated(recommendations: Array<Recommendation>): SetRecommendationsUpdatedAction {
        return new SetRecommendationsUpdatedAction(recommendations);
    },

    setStationSearchResults(searchResults: Array<Station>): SetStationSearchResultsAction {
        return new SetStationSearchResultsAction(searchResults);
    },

    setStationDetails(searchResult?: Station): SetStationDetailsAction {
        return new SetStationDetailsAction(searchResult);
    },

    setUserChangedStation(needsRecommendationCheckAfterStationChange = true): SetUserChangedStationAction {
        return new SetUserChangedStationAction(needsRecommendationCheckAfterStationChange);
    },
};
export default ApiActions;
