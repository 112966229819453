import { InjectionToken } from '@angular/core';

import { environment } from '../../environments/environment';

const branch = require('branch-sdk');

export const Branch = new InjectionToken<any>('branch');
export const BranchProvider = { provide: Branch, useValue: branch };

export const BranchApiKey = new InjectionToken<string>('environment.branchApiKey');
export const BranchApiKeyProvider = { provide: BranchApiKey, useValue: environment.branchApiKey };
