import { AllActions } from '../../all.actions';
import ActionTypes from '../../constants/action-types.enum';

import initialState, { PlaylistState } from './playlist.state';


type Action = AllActions;

/**
 * The primary reducer for the playlist.
 *
 * @param {PlaylistState} state
 * @param {Action} action
 * @return {PlaylistState}
 */
const playlistReducer = (state = initialState, action: Action): PlaylistState => {
    switch (action.type) {
        case ActionTypes.USER_LOADED:
            return {
                ...state,
                isUserLoaded: true,
            };

        case ActionTypes.SIGN_OUT:
            return {
                ...state,
                isUserLoaded: false,
            };

        case ActionTypes.METADATA_LOADING:
            return {
                ...state,
                isReadyForStory: true,
                sharedMediaId: action.payload.sharedMediaId || initialState.sharedMediaId,
                channel: action.payload.channel || initialState.channel,
            };

        case ActionTypes.METADATA_LOADED:
            return {
                ...initialState,
                isUserLoaded: state.isUserLoaded,
            };

        case ActionTypes.RECOMMENDATIONS_UPDATING:
            return {
                ...state,
                needsRecommendationListUpdate: true,
                channel: action.payload.channel || initialState.channel,
            };

        case ActionTypes.RECOMMENDATIONS_UPDATED:
            return {
                ...state,
                needsRecommendationListUpdate: false,
                channel: initialState.channel,
            };

        case ActionTypes.AUDIO_PLAYING:
            return {
                ...state,
                isAudioPlaying: action.payload.isAudioPlaying ?
                    !!action.payload.isAudioPlaying :
                    state.isAudioPlaying,
            };

        case ActionTypes.AUDIO_COMPLETE:
            return {
                ...state,
                isAudioComplete: true,
            };

        case ActionTypes.AUDIO_POSITION_CHANGE:
            return {
                ...state,
                position: action.payload.position || state.position,
            };

        case ActionTypes.SKIP:
            return {
                ...state,
                hasClickedSkip: true,
            };

        case ActionTypes.SHARE:
            return {
                ...state,
                hasClickedShare: true,
            };

        case ActionTypes.MARK_INTERESTING:
            return {
                ...state,
                hasClickedMarkInteresting: true,
            };

        case ActionTypes.CLICK_THROUGH:
            return {
                ...state,
                hasClickedThrough: true,
                isClickThroughInternal: !!action.payload,
            };

        case ActionTypes.TIMEOUT:
            return {
                ...state,
                hasTimedOut: true,
            };

        case ActionTypes.USER_CHANGED_STATION:
            return {
                ...state,
                needsRecommendationCheckAfterStationChange: action.payload,
            };

        default:
            return state;
    }
};
export default playlistReducer;
