import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';

import FeatureStores from '../constants/feature-stores.enum';
import { UtilModule } from '../util/util.module';

import { NavComponent } from './nav.component';
import navReducer from './nav.reducer';
import { NavService } from './nav.service';
import { getInitialNavState } from './nav.state';
import { BypassComponent } from './sub-menus/bypass.component';
import { JunkDrawerComponent } from './sub-menus/junk-drawer.component';
import { OrganizationMenuComponent } from './sub-menus/organization.component';
import { UserMenuComponent } from './sub-menus/user-menu.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UtilModule,
        StoreModule.forFeature(FeatureStores.NAV, navReducer, { initialState: getInitialNavState }),
    ],
    declarations: [
        NavComponent,
        JunkDrawerComponent,
        OrganizationMenuComponent,
        UserMenuComponent,
        BypassComponent,
    ],
    exports: [
        NavComponent,
    ],
    providers: [
        NavService,
    ],
})
export class NavModule {
}
