import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';

import FeatureStores from '../constants/feature-stores.enum';
import { UtilModule } from '../util/util.module';

import { MarkInterestingButtonComponent } from './card/buttons/interesting.component';
import { ReadStoryButtonComponent } from './card/buttons/read.component';
import { ShareButtonComponent } from './card/buttons/share.component';
import { FeatureCardComponent } from './card/card-types/feature-card.component';
import { SponsorshipComponent } from './card/card-types/sponsorship.component';
import { StationCardComponent } from './card/card-types/station.component';
import { StoryCardComponent } from './card/card-types/story.component';
import { UpsellComponent } from './card/card-types/upsell.component';
import { CardComponent } from './card/card.component';
import { MetadataComponent } from './metadata.component';
import metadataReducer from './metadata.reducer';
import { MetadataService } from './metadata.service';
import { getInitialMetadataState } from './metadata.state';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UtilModule,
        StoreModule.forFeature(FeatureStores.METADATA, metadataReducer, { initialState: getInitialMetadataState }),
    ],
    declarations: [
        MetadataComponent,
        CardComponent,
        MarkInterestingButtonComponent,
        ReadStoryButtonComponent,
        ShareButtonComponent,
        StoryCardComponent,
        FeatureCardComponent,
        SponsorshipComponent,
        StationCardComponent,
        UpsellComponent,
    ],
    providers: [
        MetadataService,
    ],
})
export class MetadataModule {
}
