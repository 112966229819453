import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Station } from '@npr/npr-one-sdk';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, withLatestFrom } from 'rxjs/operators';

import FeatureStores from '../../constants/feature-stores.enum';
import { LoggerService } from '../../core/logger.service';
import ApiActions from '../api.actions';
import { ApiService } from '../api.service';

import initialState, {StationFinderPlusRootState, StationFinderState} from './station-finder.state';

@Injectable()
/**
 * A global service class which can theoretically be injected anywhere. However, our contract is that the only place
 * where these custom services should be allowed to be interacted with is inside other services.
 */
export class StationFinderService {
    public state$: Observable<StationFinderState>;

    constructor(private _store: Store<StationFinderPlusRootState>, private _api: ApiService, private _logger: LoggerService) {
        this.state$ = this._store.select(FeatureStores.STATION_FINDER);

        const isUserLoaded$ = this.state$.pipe(map(s => s.isUserLoaded), startWith(initialState.isUserLoaded), distinctUntilChanged());
        const needsStationSearch$ = this.state$.pipe(
            map(s => s.needsStationSearch),
            startWith(initialState.needsStationSearch),
            distinctUntilChanged(),
        );
        const searchQuery$ = this.state$.pipe(map(s => s.searchQuery), startWith(initialState.searchQuery), distinctUntilChanged());
        const stationId$ = this.state$.pipe(map(s => s.stationId), startWith(initialState.stationId), distinctUntilChanged());

        combineLatest([isUserLoaded$, needsStationSearch$])
            .pipe(withLatestFrom(searchQuery$))
            .subscribe(([[isUserLoaded, needsStationSearch], searchQuery]) => {
                if (isUserLoaded && needsStationSearch) {
                    this._searchStations(searchQuery);
                }
            });

        combineLatest([isUserLoaded$, stationId$])
            .subscribe(([isUserLoaded, stationId]) => {
                if (isUserLoaded && stationId) {
                    this._lookUpStation(stationId);
                }
            });
    }

    /**
     * Get a list of stations, rather than just a single one.
     *
     * @param {string} [searchQuery='']
     * @returns {Promise<Array<Station>>}
     * @private
     */
    _searchStations(searchQuery = ''): Promise<Array<Station>> {
        return this._api.searchStations(searchQuery === '' ? null : searchQuery)
            .then((searchResults) => {
                this._store.dispatch(ApiActions.setStationSearchResults(searchResults));
                return searchResults; // not for consumption, but useful for unit tests/debugging
            }).catch((error) => {
                this._logger.error('StationFinderService', error); // we do want to log that it happened
                throw error; // ...but we don't have enough information as to how best to handle the error here; rethrow
            });
    }

    /**
     * Get more information for a single station based on its ID.
     *
     * @param {string} stationId
     * @returns {Promise<Station>}
     * @private
     */
    _lookUpStation(stationId: string | number): Promise<Station | void> {
        return this._api.getStationDetails(stationId)
            .then((result) => {
                this._store.dispatch(ApiActions.setStationDetails(result));
                return result; // not for consumption, but useful for unit tests/debugging
            }).catch((error) => {
                this._logger.error('StationFinderService', error); // we do want to log that it happened
                // This particular call isn't worth retrying, so we dispatch an action setting the station details to null
                this._store.dispatch(ApiActions.setStationDetails(null));
            });
    }
}
export default StationFinderService;
