import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { AnalyticsService } from '../../analytics/analytics.service';
import { BrowserService } from '../../core/browser.service';

@Component({
    selector: 'rewind',
    template: `
    <button type="button" class="player__play-control" aria-kbdshortcuts="ArrowLeft MediaRewind"
            [disabled]="hasDisabledControls || !isRewindable" [attr.aria-disabled]="hasDisabledControls || !isRewindable"
            [class.player__play-control--inactive]="!hasAudio || hasDisabledControls || !isRewindable"
            (click)="rewind($event)" tabindex="0">
        <i class="player__play-control__icon player__play-control__icon--rewind" role="img" aria-label="Rewind 15 seconds"></i>
    </button>
    `,
    styleUrls: ['./rewind.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * A sub-component of the player representing the rewind button
 *
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class RewindComponent implements OnInit, OnDestroy {
    /** @type {boolean} */
    @Input() hasAudio: boolean;
    /** @type {boolean} */
    @Input() hasDisabledControls: boolean;
    /** @type {boolean} */
    @Input() isRewindable: boolean;
    /** @type {boolean} */
    @Input() isPlayerHidden: boolean;
    /** @private */
    @Output('onRewind') _onRewind: EventEmitter<void> = new EventEmitter();

    constructor(private _analytics: AnalyticsService, private _browser: BrowserService) {}

    /**
     * The actions that should be taken when a component has been initialized.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnInit-interface.html
     */
    ngOnInit() {
        this._browser.getWindow().document.addEventListener('keyup', this._processKeyUp.bind(this), false);
    }

    /**
     * The actions that should be taken right before a component is destroyed.
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnDestroy-interface.html
     */
    ngOnDestroy() {
        this._browser.getWindow().document.removeEventListener('keyup', this._processKeyUp.bind(this), false);
    }

    /**
     * The callback that gets triggered when the user clicks the rewind button.
     *
     * @listens {click}
     * @param {Event} event
     */
    rewind(event: Event): void {
        event.preventDefault();

        if (this.hasDisabledControls || !this.hasAudio || !this.isRewindable) {
            return;
        }

        this._onRewind.emit();

        this._analytics.sendEvent('audio user interactions', 'rewind audio');
    }

    /**
     * Processes keyboard/remote controller input.
     *
     * @see https://developer.amazon.com/public/solutions/platforms/webapps/docs/supporting-controllers-in-web-apps
     * @see https://msdn.microsoft.com/en-us/library/windows/desktop/dd375731(v=vs.85).aspx
     * @see https://fmtvp.github.io/tal/jsdoc/symbols/src/antie_static_script_events_keyevent.js.html
     *
     * @listens {keyup}
     * @param {Event} event
     * @private
     */
    _processKeyUp(event: KeyboardEvent): void {
        if (this.isPlayerHidden) {
            return;
        }

        if (event.key === 'ArrowLeft') {
            this.rewind(event);
            return;
        }

        /** @todo: remove deprecated keycode use for firetv and replace with event.key  */
        const key = event.keyCode !== 0 ? event.keyCode : parseInt(event.code, 10); // tslint:disable-line:deprecation
        switch (key) {
            case 37: // Left arrow
            // falls through
            case 227: // Amazon FireTV controller Rewind button
            // falls through
            case 412: // "VK_REWIND" key code
                // falls through
                this.rewind(event);
                break;

            default:
                break;
        }
    }
}
export default RewindComponent;
