import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Link } from '@npr/npr-one-sdk';

import { BrowserService } from '../../../core/browser.service';
import { Story } from '../../../util/recommendation-parser';

@Component({
    selector: 'feature-card',
    template: `
    <div class="grid__column grid__column--flex grid__column--no-grow grid__column--vertically-centered grid__column--with-gutters">
        <aside *ngIf="story.primaryImage" class="grid__row grid__row--no-grow card__image" role="complementary">
            <img [src]="story.primaryImage.href" [width]="story.primaryImage.width" [height]="story.primaryImage.height"
                 [attr.data-rel]="story.primaryImage.rel" [alt]="story.title" />
        </aside>
        <header class="grid__row grid__row--fixed-height card__header">
            <h3 class="card__title">{{story.title}}</h3>
        </header>
        <div *ngIf="story.callsToAction" class="grid__row grid__row--fixed-height card__call-to-action">
            <button role="link" type="button" tabindex="0" *ngFor="let callToAction of story.callsToAction"
                    [attr.data-action-type]="callToAction['content-type']"
                    (click)="executeCallToAction($event, callToAction)">
                {{story.callToActionText}}
            </button>
        </div>
    </div>
    `,
    styleUrls: ['./feature-card.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * This component is essentially just a holding class for the template that should only be applied if the current
 * recommendation is a feature card.
 */
export class FeatureCardComponent {
    /** @type {Story} */
    @Input() story: Story;
    /** @type {EventEmitter} */
    @Output('onClickThrough') _onClickThrough: EventEmitter<boolean> = new EventEmitter();

    constructor(private _browser: BrowserService) {}

    /**
     * The event listener that gets called when the user clicks on a call-to-action button on a feature card
     *
     * @param {Event} event
     * @param {Object} action
     */
    executeCallToAction(event: Event, action: Link) {
        event.preventDefault();

        let isInternal = false;
        switch (action['content-type']) {
            case 'text/html':
                /*
                 * featureCardPromotion items have action links that look like e.g.:
                 * nprone://listen?sharedMediaId=462691504:462692254&flow=4&channel=npr&prevStories=1
                 */
                if (/^nprone:\/\/listen/.test(action.href)) {
                    isInternal = true;
                } else {
                    const newWindow: Window = this._browser.getWindow().open(action.href, '_blank');
                    newWindow.opener = null;
                }
                break;
            default:
                break;
        }

        this._onClickThrough.emit(isInternal);
    }
}
export default FeatureCardComponent;
