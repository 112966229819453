import RootState from '../../app/root.state';
import FeatureStores from '../../constants/feature-stores.enum';

/**
 * @typedef {Object} PlaylistState
 * @property {boolean} isUserLoaded
 * @property {boolean} isReadyForStory signals the rest of the app is ready to receive a new recommendation
 * @property {boolean} needsRecommendationListUpdate
 * @property {string} sharedMediaId
 * @property {string} channel
 * @property {number} position the current position of the audio player in seconds
 * @property {boolean} isAudioPlaying signals audio has started playing, most likely for the first START action
 * @property {boolean} isAudioComplete signals audio has been completed, allowing for a COMPLETED action
 * @property {boolean} hasClickedSkip signals that the user has chosen to skip a piece of audio, allowing for a `SKIP` action
 * @property {boolean} hasClickedShare signals that the user has chosen to share a piece of audio, allowing for a `SHARE` action
 * @property {boolean} hasClickedMarkInteresting signals that the user has chosen to mark a piece of audio as interesting, allowing for a `THUMBUP` action
 * @property {boolean} hasClickedThrough signals that the user has 'clicked' a recommendation, allowing for a `TAPTHRU` action
 * @property {boolean} isClickThroughInternal accompanies `hasClickedThrough` and determines whether a new recommendation should be requested
 * @property {boolean} hasTimedOut signals that the audio player was unable to play a piece of audio
 * @property {boolean} needsRecommendationCheckAfterStationChange
 */
export interface PlaylistState {
    isUserLoaded: boolean;
    isReadyForStory: boolean;
    needsRecommendationListUpdate: boolean;
    sharedMediaId: string;
    channel: string;
    position: number;
    isAudioPlaying: boolean;
    isAudioComplete: boolean;
    hasClickedSkip: boolean;
    hasClickedShare: boolean;
    hasClickedMarkInteresting: boolean;
    hasClickedThrough: boolean;
    isClickThroughInternal: boolean;
    hasTimedOut: boolean;
    needsRecommendationCheckAfterStationChange: boolean;
}

export interface PlaylistPlusRootState extends RootState {
    [FeatureStores.PLAYLIST]: PlaylistState;
}

/**
 * Initial state (and general structure) of the playlist store.
 *
 * @type {PlaylistState}
 */
const initialState: PlaylistState = {
    isUserLoaded: false,
    isReadyForStory: false,
    needsRecommendationListUpdate: false,
    sharedMediaId: '',
    channel: '',
    position: 0,
    isAudioPlaying: false,
    isAudioComplete: false,
    hasClickedSkip: false,
    hasClickedShare: false,
    hasClickedMarkInteresting: false,
    hasClickedThrough: false,
    isClickThroughInternal: false,
    hasTimedOut: false,
    needsRecommendationCheckAfterStationChange: false,
};

export function getInitialPlaylistState() {
    return initialState;
}

export default initialState;
