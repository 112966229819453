import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Station } from '@npr/npr-one-sdk';

import { AnalyticsService } from '../../analytics/analytics.service';
import { User } from '../../api/user/user.state';
import BrowserService from '../../core/browser.service';

@Component({
    selector: 'organization-menu',
    template: `
    <button *ngIf="user.organization" class="grid__column grid__column--flex grid__column--fixed-width grid__column--vertically-centered org__logo"
         (click)="toggleOrganizationMenu($event)" tabindex='0'>
        <img *ngIf="user.organization.logo" [src]="user.organization.logo" [alt]="'Your station is ' + user.organization.displayName + '. Click for more station options.'"/>
        <h5 *ngIf="!user.organization.logo && !!user.organization.call">{{user.organization.call}}</h5>
        <h5 *ngIf="!user.organization.logo && !user.organization.call">NPR</h5>
        <b class="org__logo__caret"></b>
    </button>
    <ul class="site__nav__sub-menu" *ngIf="isOrganizationMenuExpanded" (keyup)="_processEscUp($event)" role="menu">
        <li class="site__nav__item site__nav__item--linkless" role="menuitem" aria-disabled="true" *ngIf="station?.displayName" [title]="station.displayName">
            {{station.displayName | truncate:25 }}
        </li>
        <li class="site__nav__item" role="menuitem" *ngIf="station?.donationUrl">
            <a rel="external noopener" tabindex="0" target="_blank" [href]="station.donationUrl">Donate</a>
        </li>
        <li class="site__nav__item" role="menuitem" *ngIf="station && !station.donationUrl && station.homepageUrl">
            <a rel="external noopener" tabindex="0" target="_blank" [href]="station.homepageUrl">Homepage</a>
        </li>
        <li class="site__nav__item site__nav__item--with-emphasis" role="menuitem">
            <a *ngIf="!isChangeStationActive" tabindex="0" routerLink="/stations">Change Station</a>
            <a *ngIf="isChangeStationActive" tabindex="0" href="#" (click)="toggleOrganizationMenu($event)">Change Station</a>
        </li>
    </ul>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * For now, just the organization logo if the user is signed in. In the future, this will contain
 * an actual sub-menu with options to e.g. donate to the station, and change your station.
 */
export class OrganizationMenuComponent implements OnInit, OnDestroy, OnChanges {
    /** @type {User|null} */
    @Input() user: User;
    /** @type {boolean} */
    @Input() isOrganizationMenuExpanded: boolean;
    /** @type {Station|null} */
    @Input() station: Station;
    /** @type {boolean} */
    @Input() isChangeStationActive: boolean;
    /** @private */
    @Output('onToggleOrganizationMenu') _onToggleOrganizationMenu: EventEmitter<void> = new EventEmitter();
    public keyboardShortcuts: string;

    constructor(private _sanitizer: DomSanitizer, private _analytics: AnalyticsService, private _browser: BrowserService) {
        /** @type {string} */
        this.keyboardShortcuts = 'Esc BlurMenu';
    }

    ngOnInit() {
        this._browser.getWindow().document.addEventListener('keyup', this._processEscUp.bind(this), false);
    }

    ngOnDestroy() {
        this._browser.getWindow().document.removeEventListener('keyup', this._processEscUp.bind(this), false);
    }

    ngOnChanges() {
        if (this.isOrganizationMenuExpanded === true) {
            this.keyboardShortcuts = 'Escape BlurMenu';
        } else {
            this.keyboardShortcuts = 'Escape DoNothing';
        }
    }
    get stationUrl() {
        return this._sanitizer.bypassSecurityTrustResourceUrl(this.station.homepageUrl);
    }

    /**
     * @listens {click}
     * @param {Event} event
     */
    toggleOrganizationMenu(event: Event): void {
        event.preventDefault();

        if (!this.isOrganizationMenuExpanded) {
            this._analytics.sendEvent('settings', 'station view');
        }

        this._onToggleOrganizationMenu.emit();
    }

    /**
     * The callback that gets triggered when the user closes the junk drawer by hitting the escape key
     *
     * @listens {keyup}
     * @param {Event} event
     */
    _processEscUp(event: KeyboardEvent): void {
    event.preventDefault();
     if (!this.isOrganizationMenuExpanded) {
         return;
     }

     if (event.key === 'Escape') {
         this._onToggleOrganizationMenu.emit();
         return;
     }
 }
}
export default OrganizationMenuComponent;
